import React from 'react';
// import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { createNewPatient } from '../../pages/api/api';
import {
    normalSuccessToast,
    showErrorToast,
    generatePatientId,
    formatNameWithSpaceToUnderscore,
} from '../commonfunction';
// import { SpinnerBlur } from './Spinner';
// import TextField from '@material-ui/core/TextField';
// import * as moment from 'moment-timezone';
// import DatePicker from 'react-date-picker';
import CreateNewPatientForm from './CreateNewPatientForm';
import { inboxPage } from '../../pages/_redux/actions/inboxActions';

// https://preview.keenthemes.com/metronic/react/demo1/google-material/inputs/pickers
const initialState = {
    // dob: new Date(),
    dob: '',
    firstName: '',
    lastName: '',
    gender: '',
};

export class CreateNewPatient extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            createPatientData: {
                first_name: '',
                last_name: '',
                date_of_birth: '',
                gender: '',
                service_line: '',
                branch: '',
                order_number: '',
                order_date: '',
            },
            validateCreate: false,
            // todaysDate: new Date(),
            disabledClass: '',
            open_create_patient: this.props.open_create_patient,
            patient_form_apperance: this.props.patient_form_apperance,
            disableBtn: this.props.disableBtn ? this.props.disableBtn : false,
        };
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState({ patientCreated: false }, () => {
            this.props.updateState({ patientCreated: false });
        });

        // var todaysDate = formatDate(this.state.todaysDate, 'YYYY-MM-DD');
        // this.setState({ todaysDate: todaysDate });
    }

    componentDidUpdate(prevProps) {
        if (this.props.submitCreatePatient === true) {
            this.submitCreateNewPatient();
        }
        if (this.props.validateForUpload === true) {
            this.validateForm();
        }

        if (
            prevProps.submitNormalCreatePatient !==
            this.props.submitNormalCreatePatient
        ) {
            if (this.props.submitNormalCreatePatient === true) {
                this.submitCreateNewPatient();
            }
        }
    }

    removeValidate = () => {
        if (this.state.validateCreate === true) {
            this.setState({ validateCreate: false });
        }
    };

    async componentWillUnmount() {
        await this.setState({ ...initialState });
        this._isMounted = false;
    }

    validateForm = () => {
        let error = false;
        for (const property in this.state.createPatientData) {
            if (
                Object.prototype.hasOwnProperty.call(
                    this.state.createPatientData,
                    property
                )
            ) {
                if (
                    this.state.createPatientData[property] === '' &&
                    (property === 'first_name' ||
                        property === 'last_name' ||
                        property === 'date_of_birth' ||
                        property === 'gender' ||
                        property === 'service_line' ||
                        property === 'branch' ||
                        (this.props.formFor === 'order' &&
                            property === 'order_number') ||
                        (this.props.formFor === 'order' &&
                            property === 'order_date'))
                ) {
                    if (error === false) {
                        error = true;
                    }
                    break;
                }

                if (this.props.formFor === 'order') {
                    if (
                        this.state.createPatientData[property] === '' &&
                        (property === 'order_number' ||
                            property === 'order_date')
                    ) {
                        if (error === false) {
                            error = true;
                        }
                        break;
                    }
                }
            }
        }
        if (error === true) {
            if (this.state.validateCreate === false) {
                this.setState({ validateCreate: true });
            }
        }
    };

    updateState = (state) => {
        this.setState({ ...state });
        this.props.updateState(state);
    };

    submitCreateNewPatient = async () => {
        this.validateForm();

        this.setState({
            disabledClass: 'disabled',
        });
        this.updateState({
            disableBtn: true,
        });
        let error = false;
        for (const property in this.state.createPatientData) {
            if (
                this.state.createPatientData[property] === '' &&
                (property === 'first_name' ||
                    property === 'last_name' ||
                    property === 'date_of_birth' ||
                    property === 'gender' ||
                    property === 'service_line' ||
                    property === 'branch')
            ) {
                if (error === false) {
                    error = true;
                }
                break;
            }
        }
        if (error === true) {
            this.setState({ validateCreate: true });
            if (this.state.validateCreate === false) {
                this.setState({ validateCreate: true });
            }
            this.setState({
                disabledClass: '',
            });
            this.updateState({
                disableBtn: false,
            });
            this.props.resetSubmit();
            return true;
        }

        this.props.resetSubmit();
        const patientID = generatePatientId();
        const addPatientID = {
            ...this.state.createPatientData,
            patientid: patientID,
            patient_id: patientID,
        };

        await this.setState({ loader: true, createPatientData: addPatientID });
        // let dob;
        let patientname = '';
        if (
            this.state.createPatientData.hasOwnProperty('first_name') &&
            this.state.createPatientData.hasOwnProperty('last_name')
        ) {
            patientname = `${this.state.createPatientData.last_name}, ${this.state.createPatientData.first_name}`;
        }

        if (
            this.state.createPatientData.hasOwnProperty('first_name') ===
                false &&
            this.state.createPatientData.hasOwnProperty('last_name')
        ) {
            patientname = this.state.createPatientData.last_name;
        }

        if (
            this.state.createPatientData.hasOwnProperty('first_name') &&
            this.state.createPatientData.hasOwnProperty('last_name') === false
        ) {
            patientname = this.state.createPatientData.first_name;
        }

        if (patientname !== '') {
            const createPatientData = {
                ...this.state.createPatientData,
                patient_name: patientname,
                patient_first_name: this.state.createPatientData.first_name,
                patient_last_name: this.state.createPatientData.last_name,
            };
            await this.setState({ createPatientData: createPatientData });
        }

        let patientFieldIdArray = [];
        patientFieldIdArray = this.props.patientFieldIdArray;

        const params = [];
        const formData = this.state.createPatientData;

        if (patientFieldIdArray.length !== 0) {
            // eslint-disable-next-line array-callback-return
            patientFieldIdArray.map((data) => {
                const value =
                    formData[formatNameWithSpaceToUnderscore(data.name)];
                if (
                    (formData[formatNameWithSpaceToUnderscore(data.name)] !==
                        undefined &&
                        formData[formatNameWithSpaceToUnderscore(data.name)] !==
                            '') ||
                    formatNameWithSpaceToUnderscore(data.name) === 'agencyid'
                ) {
                    params.push({
                        fieldId: data.fieldId,
                        value: value,
                    });
                }
            });
        }

        if (this.props.modifyPatientFieldIdArray !== undefined) {
            // eslint-disable-next-line array-callback-return
            this.props.modifyPatientFieldIdArray.map((data) => {
                if (
                    formatNameWithSpaceToUnderscore(data.name) ===
                    'patient_last_name'
                ) {
                    params.push({
                        fieldId: data.fieldId,
                        value: this.state.createPatientData.last_name,
                    });
                }
                if (
                    formatNameWithSpaceToUnderscore(data.name) ===
                    'patient_first_name'
                ) {
                    params.push({
                        fieldId: data.fieldId,
                        value: this.state.createPatientData.first_name,
                    });
                }
            });
        }

        let status;
        await this.props.inboxPage({
            createNewPatientCount: true,
        });
        createNewPatient(params, this.props.patientModifiableRecordsetId)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then(async (data) => {
                if (status === 200) {
                    formData.id = data.uniqueId;
                    await this.setState({
                        loader: false,
                        disabledClass: '',
                    });
                    await this.updateState({
                        disableBtn: false,
                        byPatientRender: false,
                    });
                    await this.setState({ patientCreated: true }, () => {
                        this.props.updateState({ patientCreated: true });
                    });
                    await this.props.inboxPage({
                        currentBranch: formData.branch,
                    });
                    formData.closeFunction = true;
                    await this.props.updateState({
                        patient_filter: this.state.createPatientData,
                        tempPatientFilter: this.state.createPatientData,
                        selectedPatient: this.state.createPatientData,
                        patient_filter_display: `${this.state.createPatientData.last_name}, ${this.state.createPatientData.first_name}`,
                    });
                    await this.props.closeCreatePatient(formData);
                    normalSuccessToast('Patient Created Successfully');

                    /* this.props.updateState({
                        patient_filter: this.state.createPatientData,
                        tempPatientFilter: this.state.createPatientData,
                        selectedPatient: this.state.createPatientData,
                        patient_filter_display: `${this.state.createPatientData.last_name}, ${this.state.createPatientData.first_name}`,
                    }); */

                    if (
                        this.props.calingFrom === 'inbox' &&
                        this.state.createPatientData.order_date !== undefined &&
                        this.state.createPatientData.order_number !== undefined
                    ) {
                        this.props.updateState({
                            formData: {
                                ...this.props.formData,
                                order_date:
                                    this.state.createPatientData.order_date,
                                order_number:
                                    this.state.createPatientData.order_number,
                                byOrderEdit: true,
                            },
                        });
                    }
                    if (this.props.by_order === true) {
                        this.props.callBack(true);
                    }

                    if (
                        this.props.submittingViaBigView === true ||
                        this.props.submittingViaUpload === true
                    ) {
                        this.props.submitDetailForm();
                    }
                    await this.updateState({
                        byPatientRender: true,
                    });
                }
                if (status === 400) {
                    this.setState({ loader: false });
                    showErrorToast(data.message);
                    this.setState({
                        loader: false,
                        disabledClass: '',
                    });
                    this.updateState({
                        disableBtn: false,
                    });
                }
            });
    };

    /* handleSelectOnChange = (state, event) => {
        this.setState({ [state]: event.target.value

        });

        var createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        this.setState({ createPatientData: createPatientData });
    };

    handleInputOnChange = (state, event) => {
        this.setState({ [state]: event.target.value });
        var createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        this.setState({ createPatientData: createPatientData });
    };

    setDob = (date) => {
        var sel_date = date;
        this.setState({
            dob: sel_date,
        });
        var dob = formatDate(sel_date, 'MM/DD/YYYY');
        var createPatientData = {
            ...this.state.createPatientData,
            date_of_birth: dob,
        };
        this.setState({ createPatientData: createPatientData });
    };

    handleBranchOnChange = (state, event) => {
        this.setState({ [state]: event.target.value });
        var createPatientData = {
            ...this.state.createPatientData,
            [state.toLowerCase()]: event.target.value,
        };
        this.setState({ createPatientData: createPatientData });
        var episodeId =
            this.props.allRecordSetID.branch_data.fields_obj.branch.fieldId;
        var searchText = 'fieldSearch=' + episodeId + '=' + event.target.value;
        var status;
        recordSetSearch(
            this.props.allRecordSetID.branch_data.recordsetId,
            searchText
        )
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((data) => {
                if (status === 200) {
                    var searchResult = EpisodeSearch(data);

                    var agencyid = {
                        ...this.state.createPatientData,
                        agencyid: searchResult[0].agencyid,
                    };
                    this.setState({ createPatientData: agencyid });
                }
            });
    }; */

    closeCreatePatient = async () => {
        this.setState({
            open_create_patient: false,
            // create_patient: false,
        });

        await this.props.updateState({
            open_create_patient: false,
            // create_patient: false
        });
    };

    render() {
        return (
            <>
                {this.state.patient_form_apperance === 'normal' ? (
                    <CreateNewPatientForm
                        state={{ ...this.state }}
                        updateState={(state) => {
                            this.setState({ ...state }, () => {
                                this.updateState(state);
                            });
                        }}
                        isDetailBigView={this.props.isDetailBigView}
                        allRecordSetID={this.props.allRecordSetID}
                        allowedFields={this.props.allowedFields}
                        isUploadPage={this.props.isUploadPage}
                        calingFrom={this.props.calingFrom}
                        functionPatientData={(e) => {
                            this.props.functionPatientData(e);
                        }}
                        patientValidation={this.props.patientValidation}
                        editPatientDataDetail={this.props.editPatientDataDetail}
                    />
                ) : (
                    <Modal
                        className='add-new-patient'
                        show={this.state.open_create_patient}
                        onHide={this.closeCreatePatient}
                        animation={false}
                        size='lg'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                    >
                        <Modal.Header
                            closeButton
                            className='d-block justify-content-start'
                        >
                            <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                                <div className='add-new-patient-icon'>
                                    <i className='fa-regular fa-plus'> </i>
                                    Create New Patient
                                </div>
                                <i
                                    title='Close'
                                    className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                    aria-hidden='true'
                                    onClick={this.closeCreatePatient}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='deletes'>
                            <CreateNewPatientForm
                                state={{ ...this.state }}
                                updateState={(state) => {
                                    this.setState({ ...state }, () => {
                                        this.updateState(state);
                                    });
                                }}
                                isDetailBigView={this.props.isDetailBigView}
                                allRecordSetID={this.props.allRecordSetID}
                                allowedFields={this.props.allowedFields}
                                isUploadPage={this.props.isUploadPage}
                                calingFrom={this.props.calingFrom}
                                formFor={this.props.formFor}
                                // formData={this.props.formData}
                            />
                        </Modal.Body>
                        <Modal.Footer className='d-block delete_btn'>
                            <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                                <div className='d-flex'>
                                    <Button
                                        title='Cancel'
                                        variant='secondary'
                                        onClick={this.closeCreatePatient}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className='d-flex'>
                                    <div
                                        className='bulk'
                                        style={{ display: 'flex' }}
                                    >
                                        <div
                                            className={
                                                this.state.disabledClass === ''
                                                    ? 'btn btn-success'
                                                    : 'btn btn-success disabled'
                                            }
                                            title='Save'
                                            onClick={() => {
                                                if (
                                                    this.state.disabledClass ===
                                                    ''
                                                ) {
                                                    this.submitCreateNewPatient();
                                                }
                                            }}
                                            // disabled={this.state.coverBtn}*/
                                        >
                                            Submit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );
    }
}

const mapDispatchToProps = { inboxPage };
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.inbox.selectedRowDetails.rulesJson,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateNewPatient));
