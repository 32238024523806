// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { put, takeLatest } from 'redux-saga/effects';
// import { InboxData } from "../api/api";
import {
    GET_INBOX_LIST,
    INBOX_LIST_SUCCESS,
    INBOX_LIST_FAILURE,
    // SET_SELECTED_ROW,
    DEFAULT_INBOX_STATE,
    // SET_ROW_DATA,
    GET_OWNERS,
    GET_OWNERS_SUCCESS,
    GET_OWNERS_FAILURE,
} from './actionTypes/inboxTypes';
import {
    GET_OUTBOX_LIST,
    OUTBOX_LIST_SUCCESS,
    OUTBOX_LIST_FAILURE,
    // SET_SELECTED_ROW,
    // SET_ROW_DATA,
} from './actionTypes/outboxTypes';

/* import axios from "axios";

axios.interceptors.request.use(function (config) {
	// Do something before request is sent
	return config;
  }, function (error) {
	// Do something with request error
	return Promise.reject(error);
  }); */

export const API_URL = process.env.REACT_APP_EXTERNAL_API_URL;

/* let config = {
	headers: {
		'Accept': 'application/json', 'Access-Control-Allow-Origin': '*',
		'Authorization': 'Bearer ' + localStorage.getItem('WV-Token')
	},
	withCredentials: true,
	responseType: 'json',
	timeout: 1000
}; */

export function* getInboxList({ processId, statusId, callingPage, queryData }) {
    try {
        if (
            callingPage !== undefined &&
            callingPage !== '' &&
            callingPage === 'search' &&
            queryData !== ''
        ) {
            let listData = yield fetch(`${API_URL}/customSearch/` + queryData, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
                },
            }).then((response) => {
                if (response.status === 401) window.location.replace('/logout');
                else return response.json();
            });
            if (listData !== undefined) {
                yield put({ type: INBOX_LIST_SUCCESS, data: listData });
            } else {
                throw new Error('Not Found');
            }
        } else {
            let listData = yield fetch(
                `${API_URL}/documents/${processId}/${statusId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${localStorage.getItem(
                            'WV-Token'
                        )}`,
                    },
                }
            ).then((response) => {
                if (response.status === 401) window.location.replace('/logout');
                else return response.json();
            });
            if (listData !== undefined) {
                yield put({ type: INBOX_LIST_SUCCESS, data: listData });
            } else {
                throw new Error('Not Found');
            }
        }

        // }
    } catch (error) {
        yield put({ type: INBOX_LIST_FAILURE, error });
    }
}

export function* getOutboxList({
    processId,
    statusId,
    callingPage,
    queryData,
}) {
    try {
        if (
            callingPage !== undefined &&
            callingPage !== '' &&
            callingPage === 'search' &&
            queryData !== ''
        ) {
            let listData = yield fetch(`${API_URL}/customSearch/` + queryData, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
                },
            }).then((response) => response.json());

            if (listData !== undefined) {
                yield put({ type: OUTBOX_LIST_SUCCESS, data: listData });
            } else {
                throw new Error('Not Found');
            }
        } else {
            let listData = yield fetch(
                `${API_URL}/documents/${processId}/${statusId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${localStorage.getItem(
                            'WV-Token'
                        )}`,
                    },
                }
            ).then((response) => {
                if (response.status === 401) window.location.replace('/logout');
                else return response.json();
            });

            if (listData !== undefined) {
                yield put({ type: OUTBOX_LIST_SUCCESS, data: listData });
            } else {
                throw new Error('Not Found');
            }
        }
    } catch (error) {
        yield put({ type: OUTBOX_LIST_FAILURE, error });
    }
}

export function* getOwners({ processId, statusId }) {
    try {
        // const result = [ "kalpana Raja","Bargavi Prasanna","Sathish Kumar","Abubakkar","Santhosh" ];
        const color_code = [
            '#ECB445',
            '#8B36BF',
            '#00B1BC',
            '#ffbe0b',
            '#fb5607',
            '#ff006e',
            '#8338ec',
            '#3a86ff',
            '#003049',
            '#d62828',
            '#f77f00',
            '#283618',
            '#bc6c25',
            '#1b4332',
            '#1a759f',
        ];

        //const history = useHistory();
        const ownerData = yield fetch(
            `${API_URL}/documentOwners/${processId}/${statusId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
                },
            }
        )
            //.then((response) => response.json());
            .then((response) => {
                var statuscode = response.status;

                if (
                    statuscode === '400' ||
                    statuscode === 400 ||
                    statuscode === 401
                ) {
                    fetch(`${API_URL}/logout`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${localStorage.getItem(
                                'WV-Token'
                            )}`,
                        },
                    });

                    var remember_me = localStorage.getItem('remember_me');
                    var username = localStorage.getItem('username');
                    var password = localStorage.getItem('password');

                    const values = {};
                    const keys = Object.keys(localStorage);
                    let i = keys.length;
                    while (i--) {
                        values[keys[i]] = localStorage.getItem(keys[i]);
                    }

                    const localStorageData = values;
                    const headerStorage = {};
                    for (const name in localStorageData) {
                        if (
                            String(name).includes('header-reorder') ||
                            String(name).includes('header-width') ||
                            String(name).includes('filter-options') ||
                            String(name).includes('gmail_') ||
                            String(name).includes('sub_domain_name') ||
                            String(name).includes('tagsCurrentWidth')
                        ) {
                            headerStorage[name] = localStorageData[name];
                        }
                    }

                    localStorage.clear();
                    localStorage.setItem('remember_me', remember_me);
                    localStorage.setItem('username', username);
                    localStorage.setItem('password', password);
                    for (const name in headerStorage) {
                        localStorage.setItem(name, headerStorage[name]);
                    }
                    localStorage.setItem('forcelogout', 1);
                } else {
                    //localStorage.setItem('forcelogout', 1);
                    return response.json();
                }
            });
        const finalOwner = [];
        ownerData.forEach((owner) => {
            const color = {};
            const bg_color = color_code[Math.floor(Math.random() * 15)];
            color.ownerId = owner.ownerId;
            color.ownerName = owner.ownerName;
            color.bg_color = bg_color;
            finalOwner.push(color);
        });
        yield put({ type: GET_OWNERS_SUCCESS, data: finalOwner });
    } catch (error) {
        yield put({ type: GET_OWNERS_FAILURE, error });
    }
}

export function* setDefaultValuesForInbox() {
    yield put({ type: DEFAULT_INBOX_STATE });
}

export function* defaultInbox() {
    yield takeLatest(DEFAULT_INBOX_STATE, setDefaultValuesForInbox);
}

export function* listWatcher(processId, statusId) {
    yield takeLatest(GET_OUTBOX_LIST, getOutboxList);
    yield takeLatest(GET_INBOX_LIST, getInboxList);
    yield takeLatest(GET_OWNERS, getOwners);
}
