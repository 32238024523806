import axios from 'axios';

export const APP_URL = `${window.location.protocol}//${window.location.host}`;
export const API_URL = process.env.REACT_APP_EXTERNAL_API_URL;
//export const SUB_DOMAIN = process.env.REACT_APP_API_SUB_DOMAIN;
export const SUB_DOMAIN = localStorage.getItem('sub_domain_name');
// export const AUTHORIZATION =process.env.REACT_APP_AUTHORIZATION_TOKEN;
export const LOGIN_URL = 'api/auth/login';
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const ME_URL = 'api/me';
export function login(email, password, subdomainname = 'medattach3template') {
    const data = {
        username: email,
        password: password,
        subdomain: subdomainname,
    };

    // return fetch("https://cors-anywhere.herokuapp.com/"+API_URL+"/login",
    // return fetch("https://reqres.in/api/users",
    // return fetch("https://wv-esignapi.myworldviewltd.com/api/user?userid=5241e660-dafc-457f-8729-2b967ed21e11",
    return fetch(`${API_URL}/login`, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            // 'Authorization':'Basic MjI2YTFiZWMtYzZlYS00YzE5LWI5NWEtMDc5ZTgyZGMzY2QxOmEyNjFkMGRiLTkxZjQtNDU0NC04YTdhLWFkNWY0MGNiMzNjYQ=='
        },
    });
}

export function loginWithSession(
    subdomainname = 'medattach3template',
    sessionID
) {
    const data = {
        username: 'N/A',
        password: 'N/A ',
        subdomain: subdomainname,
        sessionID: sessionID,
    };

    // return fetch("https://cors-anywhere.herokuapp.com/"+API_URL+"/login",
    // return fetch("https://reqres.in/api/users",
    // return fetch("https://wv-esignapi.myworldviewltd.com/api/user?userid=5241e660-dafc-457f-8729-2b967ed21e11",
    return fetch(`${API_URL}/login`, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            // 'Authorization':'Basic MjI2YTFiZWMtYzZlYS00YzE5LWI5NWEtMDc5ZTgyZGMzY2QxOmEyNjFkMGRiLTkxZjQtNDU0NC04YTdhLWFkNWY0MGNiMzNjYQ=='
        },
    });
}

export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email, subdomainname = 'medattach3template') {
    // return axios.post(REQUEST_PASSWORD_URL, { email });
    const data = {
        username: email,
        subdomain: subdomainname,
        returnurl: `${APP_URL}/process-forgot-password`,
        loginurl: `${APP_URL}/auth/login`,
    };

    return fetch(`${API_URL}/forgotPassword`, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            // 'Access-Control-Allow-Origin': '*'
        },
    });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}

export function login_new(email, password) {
    return axios.post(`${API_URL}/login`, { email, password });
}

export function processPassword(token, subdomainname = 'medattach3template') {
    // return axios.post(REQUEST_PASSWORD_URL, { email });
    const data = {
        subdomain: subdomainname,
        returnurl: `${APP_URL}/process-forgot-password`,
        loginurl: `${APP_URL}/auth/login`,
        token,
    };

    return fetch(`${API_URL}/forgotPassword`, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    });
}

export function changePassword(old_pwd, new_pwd) {
    const data = {
        oldPassword: old_pwd,
        newPassword: new_pwd,
    };
    return fetch(`${API_URL}/changePassword`, {
        body: JSON.stringify(data),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

export function changeExpiredPassword(new_pwd) {
    const data = {
        newPassword: new_pwd,
    };
    return fetch(`${API_URL}/changePassword`, {
        body: JSON.stringify(data),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}
export function getConfiguration() {
    return fetch(`${API_URL}/configuration`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}

export function checkDomain() {
    return axios.get(API_URL);
}

export function getProcessCount(id) {
    return fetch(`${API_URL}/counts/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
        },
    });
}
