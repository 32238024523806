import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_metronic/layout';
import * as auth from '../_redux/authRedux';
import { allStorage } from '../../../components/commonfunction';
class Logout extends Component {
    componentDidMount() {
        this.props.logout();
    }

    /*  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
} */
    render() {
        const { hasAuthToken } = this.props;
        if (hasAuthToken === false) {
            let remember_me = false;
            let username = '';
            let password = '';
            if (
                localStorage.getItem('remember_me') &&
                localStorage.getItem('remember_me') === true
            ) {
                remember_me = localStorage.getItem('remember_me');
                username = localStorage.getItem('username');
                password = localStorage.getItem('password');
            }

            const localStorageData = allStorage();
            const headerStorage = {};
            for (const name in localStorageData) {
                if (
                    String(name).includes('header-reorder') ||
                    String(name).includes('header-width') ||
                    String(name).includes('filter-options') ||
                    String(name).includes('gmail_') ||
                    String(name).includes('sub_domain_name') ||
                    String(name).includes('tagsCurrentWidth') ||
                    String(name).includes('grouping_')
                ) {
                    headerStorage[name] = localStorageData[name];
                }
            }

            localStorage.clear();
            localStorage.setItem('remember_me', remember_me);
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
            for (const name in headerStorage) {
                localStorage.setItem(name, headerStorage[name]);
            }

            return <Redirect to='/auth/login' />;
        }
        return hasAuthToken ? (
            <LayoutSplashScreen />
        ) : (
            <Redirect to='/auth/login' />
        );
    }
}
export default connect(
    ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
    auth.actions
)(Logout);
