/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Button,
} from 'react-bootstrap';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import TransitionComponent from './TransitionComponent';

import { DocumentHistroy } from './DocumentHistroy';
import { Notes } from './Notes';
import { OwnerAutocomplete } from './OwnerAutocomplete';
import { AddEditTag } from '../../../components/commonAction/AddEditTag';
import ViewDetails from './ViewDetails';
import DetailMoreInformation from './DetailMoreInformation';
import {
    userPermission,
    stringEmptyOrUndefined,
    GetFieldData,
    getNotes,
    formatNameWithSpaceToUnderscore,
    formatPathName,
} from '../../../components/commonfunction';
import { ViewAllNotes } from './ViewAllNotes';
import RelatedDocument from './RelatedDocument';
import {
    showLoader,
    getListaction,
    inboxPage,
} from '../../_redux/actions/inboxActions';
import UpdateFaxDetails from './UpdateFaxDetails';
import Office365AuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
import GmailAuthenticationPage from '../../../components/composeDraftMail/GmailAuthenticationPage';
import ExportMenu from './ExportMenu';
import SendToFaxUploadModal from './SendToFaxUploadModal';
class DocumentDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open_transition_section: false,
            open_details_section: false,
            edit_transition: false,
            is_add_notes: '',
            notes_details: 1,
            notes_list: [],
            printElement: undefined,
            openHistroy: false,
            toggleRecentDoc: false,
            detailBigView:
                this.props.detailView !== undefined
                    ? this.props.detailView
                    : false,
            selectedDoc: [],
            openNote: false,
            docIds: [],
            docSpinner: false,
            rowDetails: undefined,
            ownerlist: [],
            showOwnerDropDown: false,
            tagData: [],
            tagCount: 0,
            openTag: false,
            docId: undefined,
            more_information: false,
            printSelectedDocId: [],
            viewAllNotes: false,
            noteCount: 0,
            viewNotes: false,
            show_owner: false,
            show_tag: false,
            show_branch: false,
            show_group: false,
            show_inbox: false,
            show_notes: true, // default true
            show_pdf: true, // default true
            show_delete: false,
            show_print: false,
            show_download: false,
            processId: null,
            statusId: null,
            statusArray: [],
            branchActionId: null,
            groupActionId: null,
            editId: '',
            detailPage:
                this.props.detailView !== undefined
                    ? this.props.detailView
                    : false,
            viewAllOpen: false,
            viewAllClose: true,
            viewNoteValue: [],
            menuBranch: '',
            menuGroup: '',
            menuInbox: '',
            resetCount: false,
            allActions: { details: '' },
            noteTypes: [],
            display_pdf: true,
            serviceType: false,
            relatedDocRefresh: true,
        };
        this.handleOwnerRef = React.createRef();
        this.onInitialActions = this.onInitialActions.bind(this);
        this.handleOwnerName = this.handleOwnerName.bind(this);
        this.handleOwnerHide = this.handleOwnerHide.bind(this);
        this.handleOwnerNameReset = this.handleOwnerNameReset.bind(this);
        this.handleTagOpen = this.handleTagOpen.bind(this);
        this.handleTagClose = this.handleTagClose.bind(this);
    }

    onInitialActions() {
        let tagData = [];
        let tagCount = 0;
        let doc_id = 0;
        let rowDetails = {};
        let processId;
        let statusId;
        if (
            this.props.inbox.selectedRowDetails !== undefined &&
            this.props.inbox.selectedRowDetails.statuses !== undefined &&
            this.props.inbox.selectedRowDetails.statuses[0] !== undefined &&
            this.props.inbox.selectedRowDetails.statuses[0].processId !==
                undefined
        ) {
            processId =
                this.props.inbox.selectedRowDetails.statuses[0].processId;
            statusId = this.props.inbox.selectedRowDetails.statuses[0].statusId;
        } else {
            processId = this.props.processId;
            statusId = this.props.statusId;
        }

        if (
            this.props.inbox.stateData !== undefined &&
            this.props.inbox.stateData.rowDetails !== undefined &&
            this.props.rowDetails !== undefined
        ) {
            rowDetails = this.props.inbox.stateData.rowDetails;
            let tags_data = '';
            doc_id = this.props.inbox.selectedRowDetails.miscPproperties.docId;
            this.props.inbox.stateData.rowData.forEach((data, index) => {
                if (data.doc_id === doc_id) {
                    tags_data = data.tags_data;
                }
            });

            if (
                tags_data !== undefined &&
                tags_data !== '' &&
                tags_data !== '|'
            ) {
                const lastChar = tags_data.substr(tags_data.length - 1); // => "1"
                if (String(lastChar) === '|') {
                    tags_data = tags_data.slice(0, -1); // 'abcde'
                }

                tagData = tags_data.split('|');
                tagCount = tagData.length;
            }
        }
        let ownerlist = [];
        if (this.props.ownerlist !== undefined) {
            ownerlist = this.props.ownerlist;
        }
        let permission = userPermission({
            processId: processId,
            statusId: statusId,
            menu_name: this.props.currentMenuName,
            configData: this.props.configData,
        });

        this.setState({ user_permission: permission });
        this.setState({ show_branch: permission.show_branch });
        permission = { ...permission, show_pdf: true, show_notes: true }; // default true
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ ownerFieldId: fieldDataObj.ownerFieldId });
        if (this.state.resetCount === false) {
            this.setState({ tagCount });
        }
        this.setState({
            rowDetails,
            ownerlist,
            tagData,
            // tagCount,
            docId: doc_id,
            processId,
            statusId,
            ...permission,
        });
    }

    formatName(name) {
        let name_array;
        let displayName;
        if (name !== '' && name !== undefined && name !== null) {
            name_array = name.split(' ');
            if (name_array.length > 1) {
                displayName = `${name_array[0]
                    .charAt(0)
                    .toUpperCase()}${name_array[1].charAt(0).toUpperCase()}`;
            } else {
                const username = name.substring(0, 3);

                if (username === 'WV-') {
                    displayName = `${name[3].toUpperCase()}${name[4].toUpperCase()}`;
                } else {
                    displayName = `${name_array[0]
                        .charAt(0)
                        .toUpperCase()}${name_array[0]
                        .charAt(1)
                        .toUpperCase()}`;
                }
            }
        }

        return displayName;
    }

    handleOwnerName() {
        let name = '';
        let bg_color = '#8B36BF';
        let titleName = '';

        this.props.inbox.stateData?.rowData?.forEach((data, index) => {
            // ? added by krish for optional values
            if (
                data.doc_id ===
                this.props.inbox.selectedRowDetails.miscPproperties.docId
            ) {
                name = data.owner_rawdata;
            }
        });
        let ownerColor = [];
        ownerColor = JSON.parse(localStorage.getItem('owner_bg_color'));

        if (name === undefined || String(name).trim() === '') {
            let ownerId = '';
            const fieldDataObj = GetFieldData({
                configData: this.props.configData,
            });
            if (this.props.inbox.selectedRowDetails.hasOwnProperty('fields')) {
                this.props.inbox.selectedRowDetails.fields.map((data) => {
                    if (data.fieldId === fieldDataObj.owner) {
                        ownerId = data.value;
                    }
                });
            }
            if (ownerColor !== null && Array.isArray(ownerColor)) {
                ownerColor.forEach((owner) => {
                    if (owner.ownerId === ownerId) {
                        name = owner.name;
                        titleName = owner.name;
                    }
                });
            }
        }

        if (ownerColor !== null && Array.isArray(ownerColor)) {
            ownerColor.forEach((owner) => {
                if (owner.name === name) {
                    bg_color = owner.colorCode;
                }
            });
        }

        if (this.state.show_owner === false) {
            return (
                <>
                    {/* <button id="owner-display-name" className="no-user-icon owner-logo" ref={this.handleOwnerRef}  >
					<i className="fas fa-user-plus"></i>
				</button> */}
                </>
            );
        }

        if (this.state.show_owner === false) {
            return (
                <>
                    <button
                        type='button'
                        id='owner-display-name'
                        className='no-user-icon owner-logo'
                        ref={this.handleOwnerRef}
                        style={{ cursor: 'not-allowed' }}
                        disabled={this.props.isLocked === true}
                    >
                        <i
                            className={
                                this.state.allActions.hasOwnProperty(
                                    'assign_to_user'
                                ) &&
                                this.state.allActions.assign_to_user.icon !==
                                    null
                                    ? this.state.allActions.assign_to_user.icon
                                    : 'fas fa-user-plus'
                            }
                            style={{
                                color:
                                    this.state.allActions.hasOwnProperty(
                                        'assign_to_user'
                                    ) &&
                                    this.state.allActions.assign_to_user
                                        .color !== null
                                        ? this.state.allActions.assign_to_user
                                              .color
                                        : '',
                            }}
                        />
                    </button>
                </>
            );
        }
        if (name === undefined || String(name).trim() === '') {
            return (
                <>
                    <button
                        type='button'
                        id='owner-display-name'
                        className='no-user-icon owner-logo'
                        ref={this.handleOwnerRef}
                        title={titleName}
                        disabled={
                            this.props.callingFrom === 'related_document' ||
                            this.props.isLocked === true
                        }
                        style={
                            this.props.callingFrom === 'related_document' ||
                            this.props.isLocked === true
                                ? { cursor: 'not-allowed' }
                                : {}
                        }
                        onClick={this.handleOwnerFocus.bind(this)}
                    >
                        <i
                            className={
                                this.state.allActions.hasOwnProperty(
                                    'assign_to_user'
                                ) &&
                                this.state.allActions.assign_to_user.icon !==
                                    null
                                    ? this.state.allActions.assign_to_user.icon
                                    : 'fas fa-user-plus'
                            }
                            style={{
                                color:
                                    this.state.allActions.hasOwnProperty(
                                        'assign_to_user'
                                    ) &&
                                    this.state.allActions.assign_to_user
                                        .color !== null
                                        ? this.state.allActions.assign_to_user
                                              .color
                                        : '',
                            }}
                        />
                    </button>
                </>
            );
        }
        name = this.formatName(name);
        return (
            <>
                <button
                    type='button'
                    id='owner-display-name'
                    style={{
                        cursor:
                            this.props.callingFrom === 'related_document' ||
                            this.props.isLocked
                                ? 'not-allowed'
                                : 'allowed',
                        backgroundColor: bg_color,
                    }}
                    className='username'
                    ref={this.handleOwnerRef}
                    title={titleName}
                    onClick={
                        this.props.callingFrom !== 'related_document'
                            ? this.handleOwnerFocus.bind(this)
                            : {}
                    }
                    disabled={this.props.isLocked}
                >
                    {name}
                </button>
            </>
        );
    }

    handleOwnerNameReset(name) {
        this.props.inbox.stateData.rowData.forEach((data, index) => {
            if (data.doc_id === this.state.rowDetails.doc_id) {
                data.owner_rawdata = name;
            }
        });
    }

    onEnter(e) {
        e.children[0].style.borderTopColor = 'rgb(0, 35, 58)';
        e.children[1].style.backgroundColor = 'rgb(0, 35, 58)';
    }

    handleOwnerFocus(event) {
        this.setState(
            {
                showOwnerDropDown: !this.state.showOwnerDropDown,
                openTag: false,
            },
            () => {
                if (
                    isMobile &&
                    document.getElementsByClassName('owner-autocomplete')
                ) {
                    const el =
                        document.getElementsByClassName(
                            'owner-autocomplete'
                        )[0];
                    el.style.left = '71.48438px';
                    el.style.top = '50px';
                }
            }
        );
    }

    handleOwnerHide() {
        this.setState({
            showOwnerDropDown: false,
        });
    }

    handleTagOpen() {
        this.setState({
            openTag: !this.state.openTag,
            showOwnerDropDown: false,
        });
    }

    handleTagClose() {
        this.setState({
            openTag: false,
        });
    }

    set_opendeletepopup = (event) => {
        this.props.openDeletePopUp(event);
    };

    set_handlenotestext = (event) => {
        this.props.handleNotesText(event);
    };

    open_transition_section = () => {
        this.setState({
            open_transition_section: !this.state.open_transition_section,
            open_details_section: false,
            open_notes_section: false,
        });
    };

    open_details_section() {
        this.setState({
            open_transition_section: false,
            open_details_section: !this.state.open_details_section,
            open_notes_section: false,
        });
    }

    open_notes_section = () => {
        this.setState({
            open_transition_section: false,
            open_details_section: false,
            open_notes_section: !this.state.open_notes_section,
            is_add_notes: 1,
        });
    };

    doCollapse = async () => {
        await document.body.classList.remove('aside-minimize');
        await document.body.classList.remove('doc-small-view');
        const elements = document.querySelectorAll('.rdg-row');

        this.setState({ row_transition: false });
        this.props.rowTransition();
        if (isMobile) {
            document.body.classList.remove('small-view-display');
        }
        setTimeout(() => {
            const checkBox =
                document.getElementsByClassName('rdg-checkbox-input');
            if (checkBox !== null) {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].disabled = false;
                    checkBox[i].removeAttribute('disabled');
                }
            }
        }, 20);
        this.props.inboxPage({
            docOpen: false,
            collapseSmallView: true,
        });
    };

    doExpand = () => {
        document.body.classList.remove('doc-small-view');
        document.body.classList.add('doc-detail-view');
        if (document.getElementById('left-aside') !== null) {
            document
                .getElementById('left-aside')
                .classList.add('aside-small-view');
        }
        const checkBox = document.getElementsByClassName('rdg-checkbox-input');
        if (checkBox !== null) {
            for (let i = 0; i < checkBox.length; i++) {
                checkBox[i].disabled = true;
            }
        }
        this.props.openBigView();
        this.setState({
            detailBigView: true,
            listingView: false,
        });

        if (isMobile) {
            document.body.classList.remove('small-view-display');
        }
        this.setState({
            printSelectedDocId: [],
        });
    };

    edit_details = () => {
        this.doExpand();
        this.props.editDetails();
    };

    viewAllNotes = async () => {
        await this.setState({ viewAllNotes: !this.state.viewAllNotes });
    };

    async componentDidMount() {
        const serviceType =
            this.props.configData?.emailIntegration?.serviceType ===
                'Office 365' ||
            this.props.configData?.emailIntegration?.serviceType === 'Google'
                ? true
                : false;
        this.setState({ serviceType }); // if service type is "Exchange" will implement later
        this.onInitialActions();
        const pushEditId = [];
        pushEditId.push(window.btoa(String(this.props.selectedDocId)));
        this.setState({ pushEditId: pushEditId });
        const editURL = `/documents/edit/${pushEditId}`;
        if (this.props.pageCount > 0) {
            this.setState({ editId: editURL });
        } else {
            this.setState({ editId: '' });
        }
        if (this.props.nextDocument === true) {
            this.props.resetNextDoc();
            this.doExpand();
        }
        const noteTypes = getNotes(
            this.props.configData,
            this.props.processId,
            this.props.statusId
        );
        await this.setState({
            noteTypes: noteTypes,
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (String(prevProps.stateToken) !== String(this.props.stateToken)) {
            setTimeout(() => {
                this.onInitialActions();
            }, 500);
        }
        if (prevState.noteCount !== this.state.noteCount) {
            if (this.state.noteCount !== 0) {
                this.setState({
                    viewNotes: false,
                });
                setTimeout(() => {
                    this.setState({
                        viewNotes: true,
                    });
                }, 100);
            } else {
                this.setState({
                    viewNotes: false,
                });
            }
        }
        if (
            String(prevState.selectedDocId) !== String(this.props.selectedDocId)
        ) {
            this.setState({
                relatedDocRefresh: false,
            });
            await this.setState(
                {
                    refreshRelatedDoc: false,
                    printSelectedDocId: [],
                    toggleRecentDoc: false,
                    selectedDoc: [],
                    selectedDocId: this.props.selectedDocId,
                    noteCount: this.props.noteCount,
                    //if we remove this three state variable then it will not close the open edit detail when we move form one doc to another doc
                    open_transition_section: false,
                    open_details_section: false,
                    open_notes_section: false,
                },
                () => {
                    setTimeout(() => {
                        this.onInitialActions();
                    }, 500);
                }
            );
            await this.setState({
                refreshRelatedDoc: true,
                relatedDocRefresh: true,
            });
        }

        const url = window.location.pathname.split('/');
        if (url[1] === 'detail') {
            if (this.state.detailBigView === false) {
                this.setState({ detailBigView: true });
            }
        }
    }

    async download() {
        await this.props.download();
    }

    edit_transition = () => {
        this.setState({
            edit_transition: true,
        });
    };

    close_transition = () => {
        this.setState({
            edit_transition: false,
        });
    };

    collapseBigView = () => {
        document.body.classList.remove('doc-detail-view');
        document.body.classList.add('doc-small-view');
        if (document.getElementById('left-aside') !== null) {
            document
                .getElementById('left-aside')
                .classList.remove('aside-small-view');
        }
        this.setState(
            {
                detailBigView: false,
            },
            () => {
                this.props.closeBigView();
                if (isMobile) {
                    document.body.classList.add('small-view-display');
                }
            }
        );
        this.props.updateState({
            edit_details: false,
        });
        this.props.inboxPage({
            fromCollapse: true,
            docOpen: true,
        });
    };

    openMore = () => {
        this.setState({ more_information: !this.state.more_information });
    };

    closeTransition = () => {
        if (
            this.props.calingFrom !== undefined &&
            (this.props.calingFrom === 'deleted_document' ||
                this.props.calingFrom === 'search')
        ) {
            // do nothing
        } else {
            this.props.getListaction(this.props.processId, this.props.statusId);
        }
        this.doCollapse();
    };

    Update_fax_details() {
        const selectedRowDetails = this.props.inbox.selectedRowDetails.fields;
        const Fields = this.props.configData.fields;
        const allFields = [];

        Fields.map((data) => {
            allFields.push({
                fieldId: data.fieldId,
                name: formatNameWithSpaceToUnderscore(data.name),
            });
        });

        let fieldId;
        allFields.map((dataValue) => {
            if (dataValue.name === 'fax_detail') {
                fieldId = dataValue.fieldId;
            }
        });
        selectedRowDetails.map((data) => {
            if (data.fieldId === fieldId) {
                this.setState({
                    updateValue: data.value,
                });
            }
        });

        this.setState({
            openUpdateNotes: true,
            selectedDocId: this.props.selectedDocId,
        });
    }
    closeFaxModal(param) {
        this.setState({
            showfaxNow: false,
        });
    }
    showFaxModal() {
        const selectedRowDetails = this.props.inbox.selectedRowDetails.fields;
        const Fields = this.props.configData.fields;
        const allFields = [];
        let fieldId;
        Fields.map((data) => {
            allFields.push({
                fieldId: data.fieldId,
                name: formatNameWithSpaceToUnderscore(data.name),
            });
        });
        selectedRowDetails.map((data) => {
            if (data.fieldId === fieldId) {
                this.setState({
                    updateValue: data.value,
                });
            }
        });
        this.setState({
            showfaxNow: true,
            selectedDocId: this.props.selectedDocId,
        });
    }
    sendDraft = async () => {
        const serviceType =
            this.props.configData?.emailIntegration?.serviceType;
        // eslint-disable-next-line eqeqeq

        if (serviceType == 'Office 365') {
            // check to authenticate draft mail
            Office365AuthenticationPage({
                selectedDocID: this.props.selectedDocId,
                configData: this.props.configData,
            });
        } else {
            GmailAuthenticationPage({
                selectedDocID: this.props.selectedDocId,
                configData: this.props.configData,
            });
        }
    };
    printIt = (data) => {
        // this.props.showLoader(true);
        // this.setState({
        //     printSelectedDocId: data,
        // });
        this.props.pdfPrintRef.current.printPdf();
    };

    render() {
        const backBtn = () => {
            if (this.state.detailPage === true) {
                return (
                    <>
                        {' '}
                        <div className='back_btn' style={{ marginLeft: '5px' }}>
                            <button
                                type='button'
                                onClick={() => this.props.goToPage()}
                            >
                                Back
                            </button>
                        </div>{' '}
                    </>
                );
            }
            return null;
        };
        if (isMobile) {
            setTimeout(() => {
                if (
                    document.getElementById('left-aside') !== null &&
                    this.props.isLocked === false
                ) {
                    document
                        .getElementById('left-aside')
                        .classList.add('no-lock-section');
                } else if (
                    document.getElementById('left-aside') !== null &&
                    this.props.isLocked === true
                ) {
                    document
                        .getElementById('left-aside')
                        .classList.remove('no-lock-section');
                }
            });
        }
        // const getPrintElement = (ele) => {
        //     if (this.state.printElement === undefined) {
        //         // Avoid Multiple Rerendering
        //         this.setState({
        //             printElement: ele,
        //         });
        //     }
        // };
        const handleHistroy = () => {
            this.setState({
                openHistroy: true,
            });
        };
        const handleHistroyClose = () => {
            this.setState({
                openHistroy: false,
            });
        };
        const handleReset = () => {
            this.setState({
                openNote: false,
            });
        };
        const toggleSpinner = () => {
            this.setState({
                docSpinner: !this.state.docSpinner,
            });
            this.props.enableLoader(!this.state.docSpinner);
        };
        let position = { left: 0, top: 0 };

        const path_name = window.location.pathname;
        const pathname = formatPathName(path_name);

        if (
            this.handleOwnerRef.current !== null &&
            this.state.detailPage === false
        ) {
            const pos = this.handleOwnerRef.current.getBoundingClientRect();
            position = { left: pos.left - 150, top: pos.top - 50 };
        }
        if (
            this.handleOwnerRef.current !== null &&
            this.state.detailPage === true
        ) {
            const pos = this.handleOwnerRef.current.getBoundingClientRect();
            position = { left: pos.left, top: pos.top - 60 };
        }

        if (
            this.handleOwnerRef.current !== null &&
            (pathname === 'search-patients' || pathname === 'search-physicians')
        ) {
            const pos = this.handleOwnerRef.current.getBoundingClientRect();
            position = { left: pos.left - 150, top: pos.top - 180 };
        }

        const resetCount = (count) => {
            this.setState({
                tagCount: count,
                resetCount: true,
            });
        };

        const goToFullScreen = () => {
            this.doExpand();
            setTimeout(() => {
                if (document.getElementById('viewAllNotes') !== null) {
                    document.getElementById('viewAllNotes').scrollIntoView();
                }
            }, 20);
        };

        return (
            <>
                <div
                    className={`toast-container ${this.state.toast_type}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '25%',
                    }}
                />
                {/* Details Top menu Sections */}
                {this.props.isLocked === true ? (
                    <div className='lock-message'>
                        <i className='fa fa-lock' aria-hidden='true' />
                        Document Locked. Someone else is working on this
                        document.
                    </div>
                ) : null}
                {this.state.detailBigView === true ? (
                    <div
                        className={
                            this.props.isLocked === true
                                ? 'd-flex align-items-center mt-5 mb-5'
                                : 'd-flex align-items-center mb-5'
                        }
                    >
                        {isMobile ? backBtn() : null}
                        {this.props.detailView === undefined ? (
                            <div
                                className='left-pdf-button collapse-button'
                                onClick={this.collapseBigView}
                                style={{ display: 'inline-block' }}
                            >
                                <button type='button' title='Collapse'>
                                    <i className='fas fa-arrow-to-right' />
                                    Collapse
                                </button>
                            </div>
                        ) : null}

                        {this.state.rowDetails !== undefined &&
                        this.state?.user_permission?.allActions?.hasOwnProperty(
                            'assign_to_user'
                        )
                            ? this.handleOwnerName()
                            : null}
                        {this.state.showOwnerDropDown &&
                        this.state?.user_permission?.allActions?.hasOwnProperty(
                            'assign_to_user'
                        ) ? (
                            <div
                                style={{
                                    ...{
                                        padding: '9px',
                                        borderRadius: '10px',
                                        border: '1px solid #00233A',
                                        width: '230px',
                                        background: '#fff',
                                        position: 'absolute',
                                        zIndex: 1,
                                    },
                                    ...position,
                                }}
                                className='owner-autocomplete'
                            >
                                <OwnerAutocomplete
                                    ownerlist={this.state.ownerlist}
                                    ownerName={this.props.inbox.stateData?.rowData?.filter(
                                        (item) =>
                                            item.doc_id ===
                                            this.props.rowDetails.doc_id
                                    )}
                                    ownerValue={
                                        this.state.rowDetails.owner_rawdata
                                    }
                                    handleHide={this.handleOwnerHide}
                                    docId={this.props.selectedDocId}
                                    handleOwnerNameReset={
                                        this.handleOwnerNameReset
                                    }
                                    actionId={this.state.ownerActionId}
                                    ownerFieldId={this.state.ownerFieldId}
                                />
                            </div>
                        ) : null}
                        {this.props.isLocked === true ? (
                            this.props.detailView === undefined &&
                            this.state.user_permission?.processName !==
                                'Export' &&
                            this.state?.user_permission?.allActions?.hasOwnProperty(
                                'update_tag'
                            ) ? (
                                <span
                                    style={{ cursor: 'not-allowed' }}
                                    className={
                                        this.state.tagCount > 0
                                            ? 'details-tag contain-tag-value ml-3'
                                            : 'details-tag ml-3'
                                    }
                                    id='details-tag'
                                    /* onClick={() => {
                                        showErrorToast('Docs Locked');
                                    }} */
                                    disabled
                                >
                                    <span className='fatag active '>
                                        <i
                                            className={
                                                this.state.allActions.hasOwnProperty(
                                                    'update_tag'
                                                ) &&
                                                this.state.allActions.update_tag
                                                    .icon !== null
                                                    ? this.state.allActions
                                                          .update_tag.icon
                                                    : 'fas fa-tag'
                                            }
                                            style={{
                                                color:
                                                    this.state.allActions.hasOwnProperty(
                                                        'update_tag'
                                                    ) &&
                                                    this.state.allActions
                                                        .update_tag.color !==
                                                        null
                                                        ? this.state.allActions
                                                              .update_tag.color
                                                        : '',
                                            }}
                                        />
                                    </span>
                                    <span className='count'>
                                        {this.state.tagData.length}{' '}
                                        {/* {this.state.tagCount} */}
                                    </span>
                                </span>
                            ) : null
                        ) : this.state.show_tag === true &&
                          this.state?.user_permission?.allActions?.hasOwnProperty(
                              'update_tag'
                          ) ? (
                            <Dropdown className='tag_dropdown '>
                                <Dropdown.Toggle className='dropdown_btn'>
                                    {this.props.detailView === undefined ? (
                                        <span
                                            className={
                                                this.state.tagCount > 0
                                                    ? 'details-tag contain-tag-value'
                                                    : 'details-tag'
                                            }
                                            title={this.state.tagData
                                                .filter((e) => e)
                                                .join(', ')}
                                            id='details-tag'
                                            onClick={() => {
                                                this.handleTagOpen();
                                            }}
                                        >
                                            <span
                                                className={
                                                    this.state.tagData.filter(
                                                        (e) => e
                                                    ).length > 0
                                                        ? 'fatag active'
                                                        : 'fatag'
                                                }
                                            >
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'update_tag'
                                                        ) &&
                                                        this.state.allActions
                                                            .update_tag.icon !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .update_tag
                                                                  .icon
                                                            : 'fas fa-tag'
                                                    }
                                                    style={{
                                                        color:
                                                            this.state.allActions.hasOwnProperty(
                                                                'update_tag'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .update_tag
                                                                .color !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .update_tag
                                                                      .color
                                                                : '',
                                                    }}
                                                />
                                            </span>
                                            <span className='count here'>
                                                {this.state.tagData.filter(
                                                    (e) => e
                                                ).length > 0
                                                    ? this.state.tagData.filter(
                                                          (e) => e
                                                      ).length
                                                    : null}
                                                {/* {this.state.tagCount} */}
                                            </span>
                                        </span>
                                    ) : null}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div>
                                        <AddEditTag
                                            page='inbox'
                                            selectedTag={
                                                this.props.menuSelectedTags
                                            }
                                            tagList={this.props.menuTags}
                                            tagColorArray={
                                                this.props.tagColorArray
                                            }
                                            selectedTagNameData={
                                                this.state.tagData
                                            }
                                            docId={this.props.selectedDocId}
                                            rowDetails={
                                                this.props.inbox.stateData
                                                    .rowDetails !== undefined
                                                    ? this.props.inbox.stateData
                                                          .rowDetails
                                                    : ''
                                            }
                                            resetCount={resetCount}
                                            updateTag={(tag) => {
                                                this.setState({ tagData: tag });
                                            }}
                                            handleTagClose={this.handleTagClose}
                                            refreshTag={this.props.refreshTag}
                                            allFields={
                                                this.props.configData.fields
                                            }
                                            allActions={this.props.allActions}
                                            processId={this.state.processId}
                                            statusId={this.state.statusId}
                                        />
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : this.props.detailView === undefined &&
                          this.state.show_tag === false &&
                          this.state.user_permission?.processName !==
                              'Export' &&
                          this.state?.user_permission?.allActions?.hasOwnProperty(
                              'update_tag'
                          ) ? (
                            <span
                                className={
                                    this.state.tagCount > 0
                                        ? 'details-tag contain-tag-value'
                                        : 'details-tag'
                                }
                                id='details-tag'
                                style={{ cursor: 'not-allowed' }}
                            >
                                <span
                                    className={
                                        this.state.tagCount > 0
                                            ? 'fatag active'
                                            : 'fatag'
                                    }
                                >
                                    <i
                                        className={
                                            this.state.allActions.hasOwnProperty(
                                                'update_tag'
                                            ) &&
                                            this.state.allActions.update_tag
                                                .icon !== null
                                                ? this.state.allActions
                                                      .update_tag.icon
                                                : 'fas fa-tag'
                                        }
                                        style={{
                                            color:
                                                this.state.allActions.hasOwnProperty(
                                                    'update_tag'
                                                ) &&
                                                this.state.allActions.update_tag
                                                    .color !== null
                                                    ? this.state.allActions
                                                          .update_tag.color
                                                    : '',
                                        }}
                                    />
                                </span>
                                <span className='count'>
                                    {this.state.tagData.length}{' '}
                                    {/* {this.state.tagCount} */}
                                </span>
                            </span>
                        ) : null}
                    </div>
                ) : null}
                {this.state.detailBigView === false ? (
                    <div>
                        <div
                            className={
                                this.props.isLocked === true
                                    ? 'pdf-detail d-flex align-items-stretch justify-content-between locked-screen'
                                    : 'pdf-detail d-flex align-items-stretch justify-content-between'
                            }
                        >
                            <div className='left-expand left-ex-btn'>
                                <div
                                    className='ml-0 left-pdf-button'
                                    onClick={this.doExpand}
                                    style={{ display: 'inline-block' }}
                                >
                                    <span title='Expand'>
                                        <i className='fas fa-arrow-to-left' />
                                        Expand
                                    </span>
                                </div>
                                <div
                                    className='left-pdf-button'
                                    onClick={this.doCollapse}
                                    style={{ display: 'inline-block' }}
                                >
                                    <span title='Collapse'>
                                        <i className='fas fa-arrow-to-right' />
                                        Collapse
                                    </span>
                                </div>
                            </div>

                            {process.env.REACT_APP_ENV === 'dev' ? (
                                <span className='document_file'>
                                    {' '}
                                    Document : #{this.props.selectedDocId}
                                </span>
                            ) : null}
                            <div
                                className={
                                    this.props.isLocked === true
                                        ? 'right-function action-locked'
                                        : 'right-function'
                                }
                            >
                                {this.props.showBranch ||
                                this.state.show_branch ||
                                this.props.showGroup ||
                                this.state.show_inbox ? (
                                    <OverlayTrigger
                                        placement='top'
                                        onEntering={this.entering}
                                        overlay={
                                            <Tooltip
                                                id='tooltip'
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 35, 58)',
                                                        color: 'white',
                                                    }}
                                                >
                                                    Transition
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            className={
                                                this.state
                                                    .open_transition_section
                                                    ? 'icon_fun outer-col active'
                                                    : 'icon_fun outer-col'
                                            }
                                            style={{
                                                display: 'inline-block',
                                            }}
                                            onClick={
                                                this.props.isLocked === true
                                                    ? null
                                                    : this
                                                          .open_transition_section
                                            }
                                        >
                                            <span
                                                className={
                                                    this.state
                                                        .open_transition_section
                                                        ? 'active'
                                                        : null
                                                }
                                                style={{
                                                    cursor:
                                                        this.props.isLocked ===
                                                        true
                                                            ? 'not-allowed'
                                                            : 'pointer',
                                                }}
                                            >
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'transition'
                                                        ) &&
                                                        this.state.allActions
                                                            .transition.icon !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .transition
                                                                  .icon
                                                            : 'fa-regular fa-arrow-right-arrow-left'
                                                    }
                                                    style={{
                                                        color:
                                                            this.state.allActions.hasOwnProperty(
                                                                'transition'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .transition
                                                                .color !== null
                                                                ? {
                                                                      color: this
                                                                          .state
                                                                          .allActions
                                                                          .show_pdf
                                                                          .color,
                                                                  }
                                                                : {},
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </OverlayTrigger>
                                ) : null}

                                <OverlayTrigger
                                    placement='top'
                                    onEntering={this.entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Details
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        className={
                                            this.state.open_details_section
                                                ? 'icon_fun outer-col active'
                                                : 'icon_fun outer-col'
                                        }
                                        style={{
                                            display: 'inline-block',
                                        }}
                                        onClick={() =>
                                            this.props.isLocked === true
                                                ? null
                                                : this.open_details_section()
                                        }
                                    >
                                        <span
                                            className={
                                                this.state.open_details_section
                                                    ? 'active'
                                                    : null
                                            }
                                            style={{
                                                cursor:
                                                    this.props.isLocked === true
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                            }}
                                        >
                                            {' '}
                                            {/* <i className='fa-solid fa-notes-medical'></i> */}
                                            <i
                                                className={
                                                    this.state.allActions.hasOwnProperty(
                                                        'details'
                                                    ) &&
                                                    this.state.allActions
                                                        .details.icon !== null
                                                        ? this.state.allActions
                                                              .details.icon
                                                        : 'fa-solid fa-notes-medical'
                                                }
                                                style={{
                                                    color:
                                                        this.state.allActions.hasOwnProperty(
                                                            'details'
                                                        ) &&
                                                        this.state.allActions
                                                            .details.color !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .details.color
                                                            : '',
                                                }}
                                            />
                                        </span>
                                    </div>
                                </OverlayTrigger>

                                {
                                    //this.props.showNotes &&
                                    //this.state.noteTypes.length > 0
                                    true ? (
                                        <OverlayTrigger
                                            placement='top'
                                            onEntering={this.entering}
                                            overlay={
                                                <Tooltip
                                                    id='tooltip'
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 35, 58)',
                                                        color: 'white',
                                                        marginBottom: '20px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor:
                                                                'rgb(0, 35, 58)',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Notes
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                className={
                                                    this.state
                                                        .open_notes_section
                                                        ? 'icon_fun outer-col active'
                                                        : 'icon_fun outer-col'
                                                }
                                                style={{
                                                    display: 'inline-block',
                                                }}
                                                onClick={
                                                    this.props.isLocked === true
                                                        ? null
                                                        : this
                                                              .open_notes_section
                                                }
                                            >
                                                <span
                                                    className={
                                                        this.state
                                                            .open_notes_section
                                                            ? 'active'
                                                            : null
                                                    }
                                                    style={
                                                        this.props.isLocked ===
                                                        true
                                                            ? {
                                                                  cursor: 'not-allowed',
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    <i className='fa-solid fa-sticky-note' />
                                                </span>
                                            </div>
                                        </OverlayTrigger>
                                    ) : null
                                }

                                {this.props.showPdf &&
                                this.state.user_permission?.processName !==
                                    'Export' ? (
                                    <OverlayTrigger
                                        placement='top'
                                        onEntering={this.entering}
                                        overlay={
                                            <Tooltip
                                                id='tooltip'
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 35, 58)',
                                                        color: 'white',
                                                    }}
                                                >
                                                    Edit Document
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        {this.state.editId !== '' &&
                                        this.props.isLocked !== true ? (
                                            // this.props.inboxPage.userDetail
                                            //     .userName === 'WV-VADIVELAN' ? (
                                            <a
                                                // to={{
                                                //     pathname: this.state.editId,
                                                //     selectedDocIds:
                                                //         this.state.editId,
                                                // }}
                                                onClick={() => {
                                                    this.props.updateState({
                                                        single_edit: true,
                                                    });
                                                    const currentPath =
                                                        window.location.pathname.split(
                                                            '/'
                                                        );
                                                    let pathName = `/${currentPath[1]}/edit`;
                                                    if (
                                                        currentPath[1] ===
                                                        'search'
                                                    ) {
                                                        if (
                                                            currentPath[2] ===
                                                            undefined
                                                        )
                                                            currentPath[2] =
                                                                'patients';
                                                        pathName = `/${currentPath[1]}/${currentPath[2]}/edit`;
                                                    }
                                                    this.props.history.push({
                                                        pathname: pathName,
                                                        search: `?edit=${window.btoa(
                                                            String(
                                                                this.props
                                                                    .rowDetails
                                                                    .doc_id
                                                            )
                                                        )}`,
                                                    });
                                                }}
                                            >
                                                <div
                                                    className='icon_fun outer-col'
                                                    style={{
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    <span>
                                                        <i
                                                            className={
                                                                this.state.allActions.hasOwnProperty(
                                                                    'show_pdf'
                                                                ) &&
                                                                this.state
                                                                    .allActions
                                                                    .show_pdf
                                                                    .icon !==
                                                                    null
                                                                    ? this.state
                                                                          .allActions
                                                                          .show_pdf
                                                                          .icon
                                                                    : 'fas fa-file-edit'
                                                            }
                                                            style={
                                                                this.state.allActions.hasOwnProperty(
                                                                    'show_pdf'
                                                                ) &&
                                                                this.state
                                                                    .allActions
                                                                    .show_pdf
                                                                    .color !==
                                                                    null
                                                                    ? {
                                                                          color: this
                                                                              .state
                                                                              .allActions
                                                                              .show_pdf
                                                                              .color,
                                                                      }
                                                                    : {}
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </a>
                                        ) : (
                                            // ) : (
                                            //     <NavLink
                                            //         to={{
                                            //             pathname:
                                            //                 this.state.editId,
                                            //             selectedDocIds:
                                            //                 this.state.editId,
                                            //         }}
                                            //     >
                                            //         <div
                                            //             className='icon_fun outer-col'
                                            //             style={{
                                            //                 display:
                                            //                     'inline-block',
                                            //             }}
                                            //         >
                                            //             <span>
                                            //                 <i
                                            //                     className={
                                            //                         this.state.allActions.hasOwnProperty(
                                            //                             'show_pdf'
                                            //                         ) &&
                                            //                         this.state
                                            //                             .allActions
                                            //                             .show_pdf
                                            //                             .icon !==
                                            //                             null
                                            //                             ? this
                                            //                                   .state
                                            //                                   .allActions
                                            //                                   .show_pdf
                                            //                                   .icon
                                            //                             : 'fas fa-file-edit'
                                            //                     }
                                            //                     style={{
                                            //                         color:
                                            //                             this.state.allActions.hasOwnProperty(
                                            //                                 'show_pdf'
                                            //                             ) &&
                                            //                             this
                                            //                                 .state
                                            //                                 .allActions
                                            //                                 .show_pdf
                                            //                                 .color !==
                                            //                                 null
                                            //                                 ? this
                                            //                                       .state
                                            //                                       .allActions
                                            //                                       .show_pdf
                                            //                                       .color
                                            //                                 : '#007DBC',
                                            //                     }}
                                            //                 ></i>
                                            //             </span>
                                            //         </div>
                                            //     </NavLink>
                                            // )
                                            <div
                                                className='icon_fun outer-col'
                                                style={{
                                                    display: 'inline-block',
                                                    cursor: 'not-allowed',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        cursor: 'not-allowed',
                                                    }}
                                                >
                                                    <i
                                                        className={
                                                            this.state.allActions.hasOwnProperty(
                                                                'show_pdf'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .show_pdf
                                                                .icon !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .show_pdf
                                                                      .icon
                                                                : 'fas fa-page-break'
                                                        }
                                                        style={{
                                                            color:
                                                                this.state.allActions.hasOwnProperty(
                                                                    'show_pdf'
                                                                ) &&
                                                                this.state
                                                                    .allActions
                                                                    .show_pdf
                                                                    .color !==
                                                                    null
                                                                    ? this.state
                                                                          .allActions
                                                                          .show_pdf
                                                                          .color
                                                                    : '#007DBC',
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </OverlayTrigger>
                                ) : null}

                                <div
                                    className='icon_fun'
                                    style={{
                                        display: 'inline-block',
                                    }}
                                >
                                    {this.props.isLocked === true ? (
                                        <span
                                            className='lock_drop'
                                            style={{ cursor: 'not-allowed' }}
                                        >
                                            <i
                                                className='fa fa-ellipsis-v'
                                                aria-hidden='true'
                                            />
                                        </span>
                                    ) : (
                                        <span>
                                            <Dropdown
                                                className='bulk-print-opt dropDown-svg'
                                                style={{}}
                                            >
                                                <OverlayTrigger
                                                    placement='top'
                                                    onEntering={this.entering}
                                                    overlay={
                                                        <Tooltip
                                                            id='tooltip'
                                                            style={{
                                                                backgroundColor:
                                                                    'rgb(0, 35, 58)',
                                                                color: 'white',
                                                                marginBottom:
                                                                    '20px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        'rgb(0, 35, 58)',
                                                                    color: 'white',
                                                                }}
                                                            >
                                                                Settings
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Dropdown.Toggle
                                                        style={{
                                                            background:
                                                                'transparent',
                                                            border: 'none',
                                                        }}
                                                        variant='success'
                                                        id='dropdown-basic'
                                                        title='Settings'
                                                    >
                                                        <i
                                                            className='fa fa-ellipsis-v'
                                                            aria-hidden='true'
                                                        />
                                                    </Dropdown.Toggle>
                                                </OverlayTrigger>

                                                <Dropdown.Menu>
                                                    <ExportMenu
                                                        permission={
                                                            this.state
                                                                .user_permission
                                                        }
                                                        callingFrom='smallView'
                                                        count={this.props.count}
                                                        doCollapse={() =>
                                                            this.doCollapse()
                                                        }
                                                        updateState={(
                                                            state
                                                        ) => {
                                                            this.props.updateState(
                                                                {
                                                                    ...state,
                                                                }
                                                            );
                                                        }}
                                                    />
                                                    {this.state.user_permission
                                                        ?.processName !==
                                                        'Export' && (
                                                        <Dropdown.Item
                                                            title='View History'
                                                            onClick={() =>
                                                                handleHistroy()
                                                            }
                                                        >
                                                            <span className='delivery-details'>
                                                                <i className='fa fa-history' />
                                                                View History
                                                            </span>
                                                        </Dropdown.Item>
                                                    )}

                                                    {this.state.allActions
                                                        .fax_now ? (
                                                        <Dropdown.Item
                                                            title='Fax Now'
                                                            onClick={() =>
                                                                this.showFaxModal()
                                                            }
                                                        >
                                                            <i className='fas fa-fax' />
                                                            Send To Fax
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {this.state.allActions
                                                        .update_fax_details ? (
                                                        <Dropdown.Item
                                                            title='Update Fax Details'
                                                            onClick={() =>
                                                                this.Update_fax_details()
                                                            }
                                                        >
                                                            <span className='delivery-details'>
                                                                <svg
                                                                    className='icon-fill'
                                                                    width='39'
                                                                    height='34'
                                                                    viewBox='0 0 39 34'
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                >
                                                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                                </svg>
                                                                Update Fax
                                                                Details
                                                            </span>
                                                        </Dropdown.Item>
                                                    ) : null}
                                                    <Dropdown.Item
                                                        title='Print'
                                                        className='print-dropdown'
                                                        onClick={() =>
                                                            this.printIt([
                                                                this.props
                                                                    .selectedDocId,
                                                            ])
                                                        }
                                                    >
                                                        <span>
                                                            {' '}
                                                            <i
                                                                className={
                                                                    this.state.allActions.hasOwnProperty(
                                                                        'print'
                                                                    ) &&
                                                                    this.state
                                                                        .allActions
                                                                        .print
                                                                        .icon !==
                                                                        null
                                                                        ? this
                                                                              .state
                                                                              .allActions
                                                                              .print
                                                                              .icon
                                                                        : 'fas fa-print'
                                                                }
                                                                style={{
                                                                    color:
                                                                        this.state.allActions.hasOwnProperty(
                                                                            'print'
                                                                        ) &&
                                                                        this
                                                                            .state
                                                                            .allActions
                                                                            .print
                                                                            .color !==
                                                                            null
                                                                            ? this
                                                                                  .state
                                                                                  .allActions
                                                                                  .print
                                                                                  .color
                                                                            : {},
                                                                }}
                                                            />
                                                            Print{' '}
                                                        </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            this.download()
                                                        }
                                                        title='Download'
                                                    >
                                                        <span>
                                                            <i
                                                                className={
                                                                    this.state.allActions.hasOwnProperty(
                                                                        'download'
                                                                    ) &&
                                                                    this.state
                                                                        .allActions
                                                                        .download
                                                                        .icon !==
                                                                        null
                                                                        ? this
                                                                              .state
                                                                              .allActions
                                                                              .download
                                                                              .icon
                                                                        : 'fas fa-download'
                                                                }
                                                                style={{
                                                                    color:
                                                                        this.state.allActions.hasOwnProperty(
                                                                            'download'
                                                                        ) &&
                                                                        this
                                                                            .state
                                                                            .allActions
                                                                            .download
                                                                            .color !==
                                                                            null
                                                                            ? this
                                                                                  .state
                                                                                  .allActions
                                                                                  .download
                                                                                  .color
                                                                            : {},
                                                                }}
                                                            />
                                                            Download
                                                        </span>
                                                    </Dropdown.Item>
                                                    {this.state.serviceType &&
                                                    this.state?.user_permission
                                                        ?.processName !==
                                                        'Export' ? (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.sendDraft();
                                                            }}
                                                            title='Send via email'
                                                        >
                                                            <span>
                                                                <i className='fa-solid fa-envelope' />
                                                                Send via email
                                                            </span>
                                                        </Dropdown.Item>
                                                    ) : null}
                                                    {this.state?.user_permission
                                                        ?.show_delete ? (
                                                        <Dropdown.Item
                                                            onClick={
                                                                this
                                                                    .set_opendeletepopup
                                                            }
                                                            title='Delete'
                                                        >
                                                            <span>
                                                                <i
                                                                    className={
                                                                        this.state.allActions.hasOwnProperty(
                                                                            'delete'
                                                                        ) &&
                                                                        this
                                                                            .state
                                                                            .allActions
                                                                            .delete
                                                                            .icon !==
                                                                            null
                                                                            ? this
                                                                                  .state
                                                                                  .allActions
                                                                                  .delete
                                                                                  .icon
                                                                            : 'fas fa-trash-alt'
                                                                    }
                                                                    style={{
                                                                        color:
                                                                            this.state.allActions.hasOwnProperty(
                                                                                'delete'
                                                                            ) &&
                                                                            this
                                                                                .state
                                                                                .allActions
                                                                                .delete
                                                                                .color !==
                                                                                null
                                                                                ? this
                                                                                      .state
                                                                                      .allActions
                                                                                      .delete
                                                                                      .color
                                                                                : '#007DBC',
                                                                    }}
                                                                />
                                                                Delete
                                                            </span>
                                                        </Dropdown.Item>
                                                    ) : null}
                                                    {/* {process.env
                                                        .REACT_APP_ENV ===
                                                    'dev' ? (
                                                        <Dropdown.Item
                                                            style={{
                                                                cursor: 'default',
                                                            }}
                                                            title='Download'
                                                        >
                                                            {' '}
                                                            <Link
                                                                to={{
                                                                    pathname: `/detail/${this.props.selectedDocId}`,
                                                                    state: {
                                                                        processId:
                                                                            this
                                                                                .state
                                                                                .processId,
                                                                        statusId:
                                                                            this
                                                                                .state
                                                                                .statusId,
                                                                    },
                                                                }}
                                                            >
                                                                View Detail{' '}
                                                            </Link>{' '}
                                                        </Dropdown.Item>
                                                    ) : null} */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Solved by another way */}
                        {/* {this.state.printSelectedDocId.length > 0 ? (
                            <PrintPdfDocument
                                docId={this.state.printSelectedDocId}
                            />
                        ) : null} */}
                        {/* Transition Section */}
                        {this.state.open_transition_section ? (
                            <div className='wrap_transition p-4'>
                                <div className='d-flex transition_part transition_title_mobile align-items-stretch justify-content-between mb-5 pb-2'>
                                    <div className='transition_fun'>
                                        <span>
                                            <i
                                                className={
                                                    this.state.allActions.hasOwnProperty(
                                                        'transition'
                                                    ) &&
                                                    this.state.allActions
                                                        .transition.icon !==
                                                        null
                                                        ? this.state.allActions
                                                              .transition.icon
                                                        : 'fa-regular fa-arrow-right-arrow-left'
                                                }
                                                title='Transition'
                                                style={{
                                                    color:
                                                        this.state.allActions.hasOwnProperty(
                                                            'transition'
                                                        ) &&
                                                        this.state.allActions
                                                            .transition
                                                            .color !== null
                                                            ? this.state
                                                                  .allActions
                                                                  .transition
                                                                  .color
                                                            : '',
                                                }}
                                            />
                                            Transition
                                        </span>
                                    </div>
                                </div>

                                <TransitionComponent
                                    content='normal'
                                    menu_name={this.props.menu_name}
                                    processId={this.state.processId}
                                    statusId={this.state.statusId}
                                    statusArray={this.state.statusArray}
                                    branches={this.props.branches}
                                    groups={this.props.groups}
                                    selectedDocId={this.props.selectedDocId}
                                    editTransition={false}
                                    branchActionId={
                                        this.state?.user_permission
                                            ?.branchActionId
                                    }
                                    groupActionId={
                                        this.state?.user_permission
                                            ?.groupActionId
                                    }
                                    showBranch={this.state.show_branch}
                                    showGroup={this.state.show_group}
                                    showInbox={this.state.show_inbox}
                                    closeTransition={(param) => {
                                        this.closeTransition.call(this);
                                    }}
                                    isLocked={this.props.isLocked}
                                    menuGroup={this.props.menuGroup}
                                    menuBranch={this.props.menuBranch}
                                    menuInbox={this.props.menuInbox}
                                    calingFrom={this.props.calingFrom}
                                />
                            </div>
                        ) : null}

                        {/* Details Section */}

                        {this.state.open_details_section ? (
                            <div className='wrap_details p-4'>
                                <div className='d-flex details_part mb-3 align-items-stretch justify-content-between'>
                                    <div className='details_fun'>
                                        <span>
                                            <i
                                                title='Details'
                                                className='fa-solid fa-notes-medical'
                                            >
                                                {' '}
                                            </i>
                                            Details
                                        </span>
                                    </div>
                                    {(this.state.user_permission.allActions.hasOwnProperty(
                                        'save_partial_attach_to_chart'
                                    ) &&
                                        this.state.user_permission.allActions
                                            .save_partial_attach_to_chart
                                            .actionId !== '') ||
                                    (this.state.user_permission.allActions.hasOwnProperty(
                                        'attach_to_chart'
                                    ) &&
                                        this.state.user_permission.allActions
                                            .attach_to_chart.actionId !== '') ||
                                    (this.state.user_permission.allActions.hasOwnProperty(
                                        're_attach_to_chart'
                                    ) &&
                                        this.state.user_permission.allActions
                                            .re_attach_to_chart.actionId) ? (
                                        <div className='edit_details'>
                                            <Button
                                                variant='primary'
                                                title='Edit Details'
                                                disabled={
                                                    this.props.isLocked === true
                                                }
                                                onClick={this.edit_details}
                                            >
                                                Edit Details
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>
                                <ViewDetails />
                                {/* <div className="text_detail mb-2"> <span>No details added yet. Click on the "Edit Details" button above to get started.</span></div> */}
                                <div
                                    className='more_info'
                                    onClick={this.openMore}
                                >
                                    <a href='#ngo' className='mt-4 mb-4'>
                                        {this.state.more_information ? (
                                            <div>
                                                <i className='fa-regular fa-chevron-up' />
                                                <button type='button'>
                                                    {' '}
                                                    Less Information
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <i className='fa-regular fa-chevron-down' />
                                                <button type='button'>
                                                    {' '}
                                                    More Information
                                                </button>
                                            </div>
                                        )}
                                    </a>
                                </div>
                                {this.state.more_information ? (
                                    <DetailMoreInformation />
                                ) : null}
                            </div>
                        ) : null}
                        {/* {process.env.REACT_APP_ENV === 'dev' &&
                        this.state.open_details_section ? (
                            <>
                                <div className='wrap_details detail_section wrap_note p-4'>
                                    <div className='d-flex mb-5 details_part align-items-stretch justify-content-between'>
                                        <div className='details_fun'>
                                            <span>
                                                <i
                                                    title='Notes'
                                                    className='fa-solid fa-sticky-note'
                                                >
                                                    {' '}
                                                </i>
                                                Notes
                                            </span>
                                        </div>
                                        {this.state.noteCount > 0 ? (
                                            <div
                                                className='edit_details'
                                                onClick={this.viewAllNotes}
                                            >
                                                <span>
                                                    View all notes
                                                    <i
                                                        className='fa fa-angle-right'
                                                        aria-hidden='true'
                                                    ></i>
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                    <Notes
                                        openNote={this.state.open_notes_section}
                                        docId={this.props.selectedDocId}
                                        type='normal'
                                        handleReset={handleReset}
                                        updateState={() => {
                                            let count = this.state.noteCount;
                                            this.setState({
                                                noteCount: count++,
                                            });
                                        }}
                                        viewAllOpen={true}
                                    />
                                </div>
                            </>
                        ) : null} */}

                        {/* Notes Section */}
                        {this.state.open_notes_section ? (
                            <div className='wrap_details wrap_note p-4'>
                                <div
                                    className={
                                        this.state.noteCount === 0
                                            ? 'd-flex details_part align-items-stretch justify-content-between'
                                            : 'd-flex mb-5 details_part align-items-stretch justify-content-between'
                                    }
                                >
                                    <div className='details_fun'>
                                        <span>
                                            <i
                                                title='Notes'
                                                className='fa-solid fa-sticky-note'
                                            >
                                                {' '}
                                            </i>
                                            Notes
                                        </span>
                                    </div>
                                    {/* this.state.noteCount > 0 ? (
                                        <div
                                            className='edit_details'
                                            onClick={() => goToFullScreen()}
                                        >
                                            <span>
                                                View all notes
                                                <i
                                                    className='fa fa-angle-right'
                                                    aria-hidden='true'
                                                />
                                            </span>
                                        </div>
                                    ) : null */}
                                </div>
                                {this.state.noteCount === 0 && (
                                    <div className='no_result norecordfound mt-0 mb-3'>
                                        <span>No Notes Found </span>
                                    </div>
                                )}

                                {this.state.viewNotes === true ? (
                                    <ViewAllNotes
                                        selectedDocId={this.props.selectedDocId}
                                    />
                                ) : null}

                                {this.props.showNotes &&
                                this.state.noteTypes.length > 0 ? (
                                    <>
                                        <hr />

                                        <Notes
                                            openNote={
                                                this.state.open_notes_section
                                            }
                                            docId={this.props.selectedDocId}
                                            type='normal'
                                            updateState={() => {
                                                let countNotes =
                                                    this.state.noteCount + 1;

                                                this.setState({
                                                    noteCount: countNotes,
                                                });
                                            }}
                                            handleReset={handleReset}
                                            viewAllOpen
                                        />
                                    </>
                                ) : null}
                            </div>
                        ) : null}
                        {/* <div className="separator_line"><hr/></div> */}
                        {this.state?.user_permission?.processName !==
                            'Export' &&
                        this.state.relatedDocRefresh === true ? (
                            <RelatedDocument
                                selectedDocId={this.props.selectedDocId}
                                isLocked={this.props.isLocked}
                                toggleSpinner={toggleSpinner}
                            />
                        ) : null}
                    </div>
                ) : null}

                <UpdateFaxDetails
                    calingFrom='smallview'
                    openUpdateNotes={this.state.openUpdateNotes}
                    selectedDocId={[this.state.selectedDocId]}
                    updateState={(state) => {
                        this.setState({ ...state });
                    }}
                    updateValue={this.state.updateValue}
                    user_permission={this.state.user_permission}
                />

                <SendToFaxUploadModal
                    calingFrom='smallview'
                    showfaxNow={this.state.showfaxNow}
                    processId={this.state.processId}
                    statusId={this.state.statusId}
                    selectedDocId={[this.state.selectedDocId]}
                    updateState={(state) => {
                        this.setState({ ...state });
                    }}
                    updateValue={this.state.updateValue}
                    user_permission={this.state.user_permission}
                />
                {/* PDF preview Section this.props.pdf_preview */}
                {/* {this.state.display_pdf ? (
                    <PdfPreview
                        setElement={getPrintElement}
                        docId={this.props.selectedDocId}
                    // selectedDoc={this.props.selectedDocId}
                    // isLocked={this.props.isLocked}
                    />
                ) : null} */}

                <Modal
                    show={this.state.openHistroy}
                    onHide={() => {
                        handleHistroyClose();
                    }}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start history_header'
                    >
                        <Modal.Title className='d-flex d-flex wrap_history align-items-stretch justify-content-between'>
                            <div className='history_popup'>
                                <i className='fa fa-history' />
                                View History
                            </div>
                            <div className='d-flex align-items-center'>
                                <i
                                    title='Close'
                                    className='fa-light fa-xmark d-flex justify-content-end '
                                    aria-hidden='true'
                                    onClick={() => {
                                        handleHistroyClose();
                                    }}
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0 history-pop'>
                        <DocumentHistroy
                            openHistroy={this.state.openHistroy}
                            docId={this.state.docId}
                        />
                    </Modal.Body>
                </Modal>
                <Notes
                    openNote={this.state.openNote}
                    docId={this.props.selectedDocId}
                    handleReset={handleReset}
                    openBigView={() => {
                        this.openBigView();
                    }}
                    updateState={() => {}}
                />

                {/* <Spinner_2 show={this.state.docSpinner} /> */}
            </>
        );
    }
}

const mapDispatchToProps = {
    showLoader: (canShow) => showLoader(canShow),
    getListaction: getListaction,
    inboxPage,
};
const mapStateToProps = (state, ownProps) => {
    const getInboxLocally = (name) => {
        switch (name) {
            case 'inbox': {
                let detailsData = localStorage.getItem('inbox_data');
                if (detailsData !== null) {
                    detailsData = JSON.parse(detailsData);
                } else {
                    detailsData = {};
                }
                return detailsData;
            }
            default:
                return {};
        }
    };
    return {
        inbox:
            stringEmptyOrUndefined(state.inbox.stateData) &&
            Object.keys(state.inbox.stateData).length > 0
                ? state.inbox
                : getInboxLocally('inbox'),
        processId: state.inbox.processId,
        statusId: state.inbox.statusId,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        stateToken: state.inbox.stateToken,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        // inboxPage: state.inbox.inboxPage,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentDetail))
);
