/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable array-callback-return */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import {
    checkIsActive,
    getConfig,
    GetCounter,
    toAbsoluteUrl,
    getdefaultLinkFromMenu,
    constructMenu,
    constructFormMenu,
    getdefaultLinkFromFormMenu,
} from '../../../../_helpers';
import { isMobile } from 'react-device-detect';
import {
    allStorage,
    unlockAllDocumentInStorage,
    formatNameWithSpaceTohipen,
} from '../../../../../app/components/commonfunction';
import{  updateSearchState } from '../../../../../app/pages/_redux/actions/searchActions';

import { getConfigData } from '../../../../../app/pages/_redux/actions/configActions';

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const history = useHistory();
    // START: Worked by vijay for adding sample data for menu section on 01-07-2021

    const current_path = location.pathname;
    const path_arr = current_path.split('/');
    var current_menu = path_arr[1];

    const CounterData = GetCounter();
    const configData = getConfig(); 
    const loopData = [1,2]

    const menuItems = configData.length > 0 ? configData[0].menuItems : [];
    let getItemLocal = localStorage.getItem(
        'initialCustomSearchNamedIdsNeedingConfiguration'
    );
    let initialconfigData =[]
    initialconfigData.push(JSON.parse(getItemLocal));
    
    const customSearchQueries =
       initialconfigData.length > 0 ? initialconfigData[0]: [];
    const configuredRecordsets =
        configData.length > 0 ? configData[0].configuredRecordsets : [];
    const dispatch = useDispatch();
    const reduxStore = useSelector((state) => state);
    const configurationData = useSelector((state) => state.configReducerPersist.configData)
    
    var customMenuArrayHandled = [];

    var show_user_management = 0;
    var customMenuArray = [];
    if (menuItems && menuItems.length > 0) {
      
        // Chat included hardcore here 
        if (process.env.REACT_APP_ENV === 'dev') {
            menuItems.push({
                menuId:8,
                menuName: "Chat",
                displayCounts:false,

            })
        }
        menuItems.map((data, key) => {
            if (data.menuName.toLowerCase() === 'user administration') {
                show_user_management = 1;
            }
            customMenuArray.push(data.menuId);
        });
        localStorage.setItem('customMenuArray', customMenuArray);
        customMenuArrayHandled = localStorage
            .getItem('customMenuArray')
            .split(',');
    }

    var patientRecordset = '';
    var physicianRecordset = '';
   
    if (configuredRecordsets !== undefined && configuredRecordsets.length !== 0) {
        configuredRecordsets.map((item, index) => {
            if (
                String(formatNameWithSpaceTohipen(item.name)) ===
                String(formatNameWithSpaceTohipen('Patient Data'))
            ) {
                patientRecordset = item.recordsetId;
            }
            if (
                String(formatNameWithSpaceTohipen(item.name)) ===
                String(formatNameWithSpaceTohipen('Physician Directory'))
            ) {
                physicianRecordset = item.recordsetId;
            }
        });
    }
   

    var reportsArray = configData.length > 0 ? configData[0].reports : [];
    var formsArray = configData.length > 0 ? configData[0].forms : [];

    const getdefaultLink = getdefaultLinkFromMenu(reportsArray, menuItems);
    getdefaultLinkFromFormMenu(
        formsArray,
        menuItems
    );

    const reportsCategory = constructMenu(reportsArray, menuItems);
    const formsCategory = constructFormMenu(formsArray, menuItems);

    const uploadTypes = configData.length > 0 ? configData[0].uploadTypes : [];
    if (menuItems && menuItems.length > 0) {
        menuItems.sort((a, b) => (a.menuId > b.menuId ? 1 : -1));
    }

    const getMenuItemActive = (url, hasSubmenu = false) => {      
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
            : '';
    };

    const getMenuItemActiveCustom = (url) => {
        if (url === current_menu) {
            return 'menu-item-active menu-item-open';
        }
    };
    const closeMenu = () => {
        document.getElementById('kt_header_mobile_toggle').click();
    };

    const process_ids = [];

    if (localStorage.getItem('forcelogout') === '1111') {
        let reponse = unlockAllDocumentInStorage();
        reponse.then((data) => {
            // Need to handle
        });
        // eslint-disable-next-line no-use-before-define
        fetch(API_URL + `/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + localStorage.getItem('WV-Token'),
            },
        }).then((response) => {});

        var remember_me = localStorage.getItem('remember_me');
        var username = localStorage.getItem('username');
        var password = localStorage.getItem('password');

        let localStorageData = allStorage();
        let headerStorage = {};
        for (let name in localStorageData) {
            if (
                String(name).includes('header-reorder') ||
                String(name).includes('header-width') ||
                String(name).includes('filter-options') ||
                String(name).includes('gmail_') ||
				String(name).includes('sub_domain_name') ||
                String(name).includes('tagsCurrentWidth') ||
                String(name).includes('searchFilter_') ||
                String(name).includes('grouping_')
            ) {
                headerStorage[name] = localStorageData[name];
            }
        }

        localStorage.clear();

        localStorage.setItem('remember_me', remember_me);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);

        for (let name in headerStorage) {
            localStorage.setItem(name, headerStorage[name]);
        }

        localStorage.setItem('forcelogout', 0);

        history.push('/auth/logout');
    }
    let userDetail = localStorage.getItem('user_details');
    let userName = '', claimsArr, adminTags = false, adminEsign = false;
    if (userDetail !== null) {
        userDetail = JSON.parse(localStorage.getItem('user_details'));
        userName = userDetail.userName;
        if (userDetail.claims !==undefined && userDetail.claims !== null) {
            claimsArr = userDetail.claims
            if (claimsArr.includes("admintags")) {
                adminTags = true
            } 
             if (claimsArr.includes("adminesign")) {
                adminEsign = true
            }
        }
    }    
    const esignMenuIndex = menuItems.findIndex(menu => menu.menuName === "E-Sign");
    if (esignMenuIndex !== -1) {
        menuItems.splice(esignMenuIndex, 1);
    }
    if (adminEsign && configuredRecordsets.some((recordset) => recordset.name === 'All Entities')) {
        menuItems.push({
            menuId: 9,
            menuName: "E-Sign",
            displayCounts: false
        });
    }
    if (
        localStorage.getItem('valid_user') == 'false' ||
        localStorage.getItem('valid_user') === null
    ) {
        return null;
    }
    const API_URL = process.env.REACT_APP_EXTERNAL_API_URL;
    const logoutClick = async () => {
       
        let reponse = unlockAllDocumentInStorage();
        reponse.then((data) => {
            // Need to handle
        });
        fetch(API_URL + `/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + localStorage.getItem('WV-Token'),
            },
        }).then((response) => {});

        let localStorageData = allStorage();
        let headerStorage = {};
        for (let name in localStorageData) {
            if (
                String(name).includes('header-reorder') ||
                String(name).includes('header-width') ||
                String(name).includes('filter-options') ||
                String(name).includes('gmail_') ||
				String(name).includes('sub_domain_name') ||
                String(name).includes('tagsCurrentWidth')
            ) {
                headerStorage[name] = localStorageData[name];
            }
        }
        var remember_me = localStorage.getItem('remember_me');
        var username = localStorage.getItem('username');
        var password = localStorage.getItem('password');
        await localStorage.clear();
        localStorage.setItem('remember_me', remember_me);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);

        for (let name in headerStorage) {
            localStorage.setItem(name, headerStorage[name]);
        }

        //history.push('/auth/login');
        //return;
		window.location.reload();
    };

    const addNewUserAction = () => {
        history.push('/users/users?create=new');
    };
    const doCollapse = async () => {
        if (document.body.classList.contains('doc-detail-view')) {
            //document.body.classList.remove('doc-detail-view');
            //document.body.classList.remove('aside-minimize');
        } else {
            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
            await dispatch(
                updateSearchState({
                    //selectedRows: new Set(),
                    deletedPage: {
                        ...reduxStore.search.deletedPage,
                        row_transition: false,
                    },
                })
            );
        }
        const elements = document.querySelectorAll('.rdg-row');
        if (isMobile) {
            document.body.classList.remove('small-view-display');
        }
        setTimeout(() => {
            const checkBox =
                document.getElementsByClassName('rdg-checkbox-input');
            if (checkBox !== null) {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].disabled = false;
                    checkBox[i].removeAttribute('disabled');
                }
            }
        }, 20);
    };

    const getDefaultUrl = (itemcat) => {
        var linking = '';

        {
            reportsArray.map((item, index) => {
                var relatedToMenus = item.relatedToMenus;
                if (item.relatedToMenus.length === 0) {
                    relatedToMenus = customMenuArrayHandled;
                }
                let isFounded = relatedToMenus.some((ai) =>
                    customMenuArrayHandled.includes(String(ai))
                );

                if (
                    index === 0 &&
                    isFounded &&
                    String(formatNameWithSpaceTohipen(item.category)) ===
                        String(formatNameWithSpaceTohipen(itemcat))
                ) {
                    linking =
                        '/reports/' +
                        formatNameWithSpaceTohipen(item.category) +
                        '/' +
                        formatNameWithSpaceTohipen(item.name) +
                        '/' +
                        item.reportId;
                    return linking;
                }
            });
        }

        return linking;
    };
    
    const getMenuItemDisplay = (itemcat) => {
        var display = 'block';
        var exists = 0;
        {
            reportsArray.map((item, index) => {
                var relatedToMenus = item.relatedToMenus;
                if (item.relatedToMenus.length === 0) {
                    relatedToMenus = customMenuArrayHandled;
                }
                let isFounded = relatedToMenus.some((ai) =>
                    customMenuArrayHandled.includes(String(ai))
                );

                if (
                    isFounded &&
                    String(formatNameWithSpaceTohipen(item.category)) ===
                        String(formatNameWithSpaceTohipen(itemcat))
                ) {
                    exists++;
                }
            });
            if (exists === 0) {
                display = 'none';
            } else {
                display = 'block';
            }
            return display;
        }
    };

    const getDefaultUrlForm = (itemcat) => {
        var linking = '';
        {
            formsArray.map((item, index) => {
                if (
                    index === 0 &&
                    String(formatNameWithSpaceTohipen(item.category)) ===
                        String(formatNameWithSpaceTohipen(itemcat))
                ) {
                    linking =
                        '/forms/' +
                        formatNameWithSpaceTohipen(item.category) +
                        '/' +
                        formatNameWithSpaceTohipen(item.name) +
                        '/' +
                        item.formId;
                    return linking;
                }
            });
        }

        return linking;
    };

    return (
        <div
            id='kt_header_menu'
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li className={`menu-item menu-item-rel  pr-10 logo-header`}>
                    <NavLink
                        className='menu-link menu_logo'
                        to='/dashboard'
                        title='dashboard'
                    >
                        <span className='menu-text'></span>
                    </NavLink>
                    {isMobile && window.screen.width <= 960 && (
                        <span
                            className='close-menu'
                            id='close-menu'
                            onClick={closeMenu}
                        >
                            <i className='fa-light fa-xmark' />
                        </span>
                    )}
                </li>
                {
                    <li
                        className={`menu-item menu-item-rel ${getMenuItemActive(
                            '/dashboard'
                        )}`}
                    >
                        <NavLink
                            title='Dashboard'
                            className='menu-link'
                            to='/dashboard'
                        >
                            <span className='menu-text text-white'>
                                Dashboard
                            </span>
                            {layoutProps.rootArrowEnabled && (
                                <i className='menu-arrow' />
                            )}
                        </NavLink>
                    </li>
                }
                {/* Worked for dynamic menu building on header by vijay */}

                {
                    //menuItems.sort((a, b) => {(a.menuId > b.menuId) ? 1 : -1)};
                }
                {/*reports && reports.length > 0 ?
					
                    reports.map((item, index) => { 
                        var report_link = 	'/reports/' +
                                          formatNameWithSpaceTohipen(
                                              item.category
                                          ) +
                                          '/' +
                                          formatNameWithSpaceTohipen(
                                              item.name
                                          ) +
                                          '/' +
                                          item.reportId;
                        //return;
                    });            
                :null*/}
            
                {menuItems && menuItems.length > 0
                    ? //
                      //menuItems.sort((a,b)=> a.menuId-b.menuId)

                      menuItems.map((data, key) => {
                            var menu_to = '#';
                            if (data.menuName.toLowerCase() == 'dashboard') {
                                return;
                            }
                            if (data.menuName.toLowerCase() == 'enhancement request') {
                                return;
                            }
                            if (
                                data.menuName.toLowerCase() ==
                                'user administration'
                            ) {
                                show_user_management = 1;
                                return;
                            }

                            if (data.menuName.toLowerCase() === 'inbox' || data.menuName.toLowerCase() === 'outbox') { // added by krish
                                menu_to = '/' + data.menuName.toLowerCase();
                            }
                            if (process.env.REACT_APP_ENV === 'dev') {
                                if (data.menuName.toLowerCase() === 'chat' ) {
                                    menu_to = '/' + data.menuName.toLowerCase();
                                }
                            }

                        //   hidden by krish inboxLink and outboxlink comes as first data only always
                        //   menu_to = '/' + data.menuName.toLowerCase();
						//  if (data.menuName.toLowerCase() === 'inbox') {
						// 	  menu_to = localStorage.getItem('inboxLink')
						//  }
						//  if (data.menuName.toLowerCase() === 'outbox') {
						// 	  menu_to = localStorage.getItem('outboxLink')
						//  }
						 
						 if (data.menuName.toLowerCase() === 'search') {
                              if (
                                  patientRecordset !== '' &&
                                  data.recordSets.includes(patientRecordset)
                              ) {
                                  menu_to =
                                      '/' +
                                      data.menuName.toLowerCase() +
                                      '/patients';
                              } else {
                                  menu_to =
                                      '/' +
                                      data.menuName.toLowerCase() +
                                      '/physicians';
                              }
                          }
                          if (data.menuName.toLowerCase() === 'reports')                           
                             menu_to = '/' + data.menuName.toLowerCase();
                            // menu_to = getdefaultLink;
                          if (data.menuName.toLowerCase() === 'forms')
                              menu_to = '/' + data.menuName.toLowerCase();
                          if (data.menuName.toLowerCase() === 'e-sign')
                              menu_to = '/' + data.menuName.toLowerCase();
                          // menu_to = getdefaultLinkForm;

                          //var menu_link  = [];
                          //menu_link.push('/'+data.menuName.toLowerCase());
                          var count = 0;
                          var statusId = '';
                          var processId = '';
                          var menuName = '';
                          // if((data.menuName.toLowerCase() == 'outbox')&& (userName!=="WV-BPRASANNA") ){
                          // 	var menu_to = '/outbox_';
                          // }
                          var submenus = [];
                          var process_steps = '';
                          if (data.processes && data.processes.length > 0) {
                           
                          
                              data.processes.map((value, index) => {
                                  process_ids.push(value.processId);
                                  processId = value.processId;
                                  process_steps = 'p_' + value.processId; //+'_s_'+val.statusId;
                                    //header count need to handle here 
                                  if (CounterData[process_steps]) {
                                      count = count + CounterData[process_steps];
                                  }
                                  //menu_link.push('/'+value.processName.toLowerCase());
                                  if (
                                      value.statuses &&
                                      value.statuses.length > 0
                                  ) {
                                       
                                        submenus = submenus.concat(value.statuses); // handled by krish for sorting the window location and left menu
                                      //value.statuses.map((vstatus, kstatus) => {

                                      //menu_link.push('/'+vstatus.name.toLowerCase());
                                      //menu_link.push('/status-'+vstatus.statusId);
                                      //if(kstatus === 0) {
                                      //statusId	=	vstatus.statusId;
                                      //menuName	=	vstatus.name.toLowerCase();
                                      //}
                                      //});
                                  }
                              });
                             

                              /* if(processId !== '' && statusId !== '' && menuName !== '') {
						menu_to = '/'+data.menuName.toLowerCase()+'/'+menuName+'/'+processId+'/'+statusId;
					} */
                          }

                          //localStorage.setItem('process_ids',JSON.stringify(process_ids));

                          return (
                              <li
                                  key={'main-menu-' + key}
                                  id={data.menuId}
                                  className={
                                      data.menuName === 'Search'
                                          ? `menu-item menu-item-submenu  ${getMenuItemActive(
                                                menu_to
                                            )}  ${getMenuItemActiveCustom(
                                                'search'
                                            )}`
                                          : data.menuName === 'Reports'
                                          ? `menu-item menu-item-submenu  ${getMenuItemActive(
                                                menu_to
                                            )}  ${getMenuItemActiveCustom(
                                                'reports'
                                            )}`
                                          : data.menuName === 'Forms'
                                          ? `menu-item menu-item-submenu  ${getMenuItemActive(
                                                menu_to
                                            )}  ${getMenuItemActiveCustom(
                                                'forms'
                                            )}`
                                            : data.menuName === 'E-Sign'
                                            ? `menu-item menu-item-submenu  ${getMenuItemActive(
                                                menu_to
                                            )}  ${getMenuItemActiveCustom(
                                                'e-sign'
                                            )}`
										   : data.menuName === 'Chat' &&  process.env.REACT_APP_ENV === 'dev'
                                            ? `menu-item menu-item-submenu  ${getMenuItemActive(
                                                menu_to
                                            )}  ${getMenuItemActiveCustom(
                                                'chat'
                                            )}`
										  :(data.menuName === 'Inbox-' || data.menuName === 'Outbox' )
                                          ? `menu-item menu-item-submenu  ${getMenuItemActive(
                                                menu_to
                                            )}  ${getMenuItemActive(
                                                '/' + data.menuName.toLowerCase()
                                            )}`
                                          : `menu-item menu-item-submenu ${getMenuItemActive(
                                                menu_to
                                            )}`
                                  }
                                  data-menu-toggle='hover'
                                  aria-haspopup='true'
                              >
                                  <NavLink
                                      title={data.menuName}
                                      className={
                                          isMobile &&
                                          window.screen.width <= 960 &&
                                          submenus !== '' &&
                                          submenus.length
                                              ? 'menu-link menu-toggle'
                                              : isMobile &&
                                                window.screen.width <= 960 &&
                                                (data.menuName === 'Search' ||
                                                    data.menuName ===
                                                        'Reports' ||
                                                    data.menuName === 'Forms')
                                              ? 'menu-link menu-toggle'
                                              : 'menu-link menu-item-rel'
                                      }
                                      to={menu_to}
                                  >
                                      <span className='menu-text'>
                                          {data.menuName} 
                                      </span>

                                      {count && count !== '' ? (
                                          <b className='count ml-2 mt-0'>
                                              {count}
                                          </b>
                                      ) : null}                                    

                                      {layoutProps.rootArrowEnabled && (
                                          <i className='menu-arrow' />
                                      )}
                                      {isMobile &&
                                          window.screen.width <= 960 &&
                                          submenus !== '' &&
                                          submenus.length !==0 && (
                                              <i className='menu-arrow' />
                                          )}
                                      {isMobile &&
                                          window.screen.width <= 960 &&
                                          (data.menuName === 'Search' ||
                                              data.menuName === 'Reports' ||
                                              data.menuName === 'Forms') && (
                                              <i className='menu-arrow' />
                                          )}
                                  </NavLink>

                                  {isMobile &&
                                  window.screen.width <= 960 &&
                                  data.menuName === 'Reports' ? (
                                      <div className='menu-submenu '>
                                          <i className='menu-arrow' />
                                          <ul className='menu-subnav'>
                                              <li
                                                  className='menu-item  menu-item-parent'
                                                  aria-haspopup='true'
                                              >
                                                  <span className='menu-link'>
                                                      <span className='menu-text'>
                                                          {data.menuName} 
                                                      </span>
                                                  </span>
                                              </li>
                                          </ul>

                                          <ul
                                              className='menu-subnav menu-nav p-0 custom-search'
                                              key='search-custom'
                                          >
                                              {reportsCategory.map(
                                                  (itemcat) => {
                                                      return (
                                                          <React.Fragment
                                                              key={
                                                                  'aside-frag-' +
                                                                  itemcat
                                                              }
                                                          >
                                                              <li
                                                                  style={{
                                                                      display: `${getMenuItemDisplay(
                                                                          itemcat
                                                                      )}`,
                                                                  }}
                                                                  className={`menu-item menu-item-submenu	
																		${getMenuItemActiveCustom(itemcat)} 
																		`}
                                                                  aria-haspopup='true'
                                                                  data-menu-toggle='hover'
                                                              >
                                                                  <NavLink
                                                                      className={
                                                                          'menu-link menu-toggle'
                                                                      }
                                                                      title={
                                                                          itemcat
                                                                      }
                                                                      id='menu_search_1'
                                                                      to={`/report/${itemcat}`}
                                                                  >
                                                                      <span className='menu-text'>
                                                                          {
                                                                              itemcat 
                                                                          } 
                                                                      </span>
                                                                      <i className='menu-arrow' />
                                                                  </NavLink>
                                                                  <div className='menu-submenu '>
                                                                      <i className='menu-arrow' />
                                                                      <ul className='menu-subnav'>
                                                                          <li
                                                                              className='menu-item  menu-item-parent'
                                                                              aria-haspopup='true'
                                                                          >
                                                                              <span className='menu-link'>
                                                                                  <span className='menu-text'>
                                                                                      {
                                                                                          itemcat
                                                                                      }
                                                                                  </span>
                                                                              </span>
                                                                          </li>

                                                                          {reportsArray.map(
                                                                              (
                                                                                  item,
                                                                                  index
                                                                              ) => {
                                                                                  //if item.reportId === value)
                                                                                  var relatedToMenus =
                                                                                      item.relatedToMenus;
                                                                                  if (
                                                                                      item
                                                                                          .relatedToMenus
                                                                                          .length ===
                                                                                      0
                                                                                  ) {
                                                                                      relatedToMenus =
                                                                                          customMenuArrayHandled;
                                                                                  }

                                                                                  let isFounded =
                                                                                      relatedToMenus.some(
                                                                                          (
                                                                                              ai
                                                                                          ) =>
                                                                                              customMenuArrayHandled.includes(
                                                                                                  String(
                                                                                                      ai
                                                                                                  )
                                                                                              )
                                                                                      );

                                                                                  if (
                                                                                      isFounded &&
                                                                                      data.reports.includes(
                                                                                          item.reportId
                                                                                      ) &&
                                                                                      String(
                                                                                          item.category
                                                                                      ) ===
                                                                                          String(
                                                                                              itemcat
                                                                                          )
                                                                                  ) {
                                                                                      return (
                                                                                          <li
                                                                                              className='menu-item '
                                                                                              aria-haspopup='true'
                                                                                          >
                                                                                              <NavLink
                                                                                                  className={`menu-link ${getMenuItemActive(
                                                                                                      '/reports/' +
                                                                                                          formatNameWithSpaceTohipen(
                                                                                                              item.category
                                                                                                          ) +
                                                                                                          '/' +
                                                                                                          formatNameWithSpaceTohipen(
                                                                                                              item.name
                                                                                                          ) +
                                                                                                          '/' +
                                                                                                          item.reportId
                                                                                                  )}`}
                                                                                                  to={
                                                                                                      '/reports/' +
                                                                                                      formatNameWithSpaceTohipen(
                                                                                                          item.category
                                                                                                      ) +
                                                                                                      '/' +
                                                                                                      formatNameWithSpaceTohipen(
                                                                                                          item.name
                                                                                                      ) +
                                                                                                      '/' +
                                                                                                      item.reportId
                                                                                                  }
                                                                                                  
                                                                                              >
                                                                                                  <span className='menu-text'>
                                                                                                      {
                                                                                                          item.name
                                                                                                      } 
                                                                                                  </span>
                                                                                              </NavLink>
                                                                                          </li>
                                                                                      );
                                                                                  }
                                                                              }
                                                                          )}
                                                                      </ul>
                                                                  </div>
                                                              </li>
                                                          </React.Fragment>
                                                      );
                                                  }
                                              )}
                                          </ul>
                                      </div>
                                  ) : null}
                                  {isMobile &&
                                  window.screen.width <= 960 &&
                                  data.menuName === 'Forms' ? (
                                      <div className='menu-submenu '>
                                          <i className='menu-arrow' />
                                          <ul className='menu-subnav'>
                                              <li
                                                  className='menu-item  menu-item-parent'
                                                  aria-haspopup='true'
                                              >
                                                  <span className='menu-link'>
                                                      <span className='menu-text'>
                                                          {data.menuName}
                                                      </span>
                                                  </span>
                                              </li>
                                          </ul>

                                          <ul
                                              className='menu-subnav menu-nav p-0 custom-search'
                                              key='search-custom'
                                          >
                                              {formsCategory.map((itemcat) => {
                                                  return (
                                                      <React.Fragment
                                                          key={
                                                              'aside-frag-' +
                                                              itemcat
                                                          }
                                                      >
                                                          <li
                                                              className={`menu-item menu-item-submenu	
																		${getMenuItemActiveCustom(itemcat)} 
																		`}
                                                              aria-haspopup='true'
                                                              data-menu-toggle='hover'
                                                          >
                                                              <NavLink
                                                                  className={
                                                                      'menu-link menu-toggle'
                                                                  }
                                                                  title={
                                                                      itemcat
                                                                  }
                                                                  id='menu_search_1'
                                                                  to={`${getDefaultUrlForm(
                                                                      itemcat
                                                                  )}`}
                                                              >
                                                                  <span className='menu-text'>
                                                                      {itemcat}
                                                                  </span>
                                                                  <i className='menu-arrow' />
                                                              </NavLink>
                                                              <div className='menu-submenu '>
                                                                  <i className='menu-arrow' />
                                                                  <ul className='menu-subnav'>
                                                                      <li
                                                                          className='menu-item  menu-item-parent'
                                                                          aria-haspopup='true'
                                                                      >
                                                                          <span className='menu-link'>
                                                                              <span className='menu-text'>
                                                                                  {
                                                                                      itemcat
                                                                                  }
                                                                              </span>
                                                                          </span>
                                                                      </li>

                                                                      {formsArray.map(
                                                                          (
                                                                              item,
                                                                              index
                                                                          ) => {
                                                                              //if item.reportId === value)
                                                                              if (
                                                                                  data.forms.includes(
                                                                                      item.formId
                                                                                  ) &&
                                                                                  String(
                                                                                      item.category
                                                                                  ) ===
                                                                                      String(
                                                                                          itemcat
                                                                                      )
                                                                              ) {
                                                                                  return (
                                                                                      <li
                                                                                          className='menu-item '
                                                                                          aria-haspopup='true'
                                                                                      >
                                                                                          <NavLink
                                                                                              className={`menu-link ${getMenuItemActive(
                                                                                                  '/forms/' +
                                                                                                      formatNameWithSpaceTohipen(
                                                                                                          item.category
                                                                                                      ) +
                                                                                                      '/' +
                                                                                                      formatNameWithSpaceTohipen(
                                                                                                          item.name
                                                                                                      ) +
                                                                                                      '/' +
                                                                                                      item.formId
                                                                                              )}`}
                                                                                              to={
                                                                                                  '/forms/' +
                                                                                                  formatNameWithSpaceTohipen(
                                                                                                      item.category
                                                                                                  ) +
                                                                                                  '/' +
                                                                                                  formatNameWithSpaceTohipen(
                                                                                                      item.name
                                                                                                  ) +
                                                                                                  '/' +
                                                                                                  item.formId
                                                                                              }
                                                                                          >
                                                                                              <span className='menu-text'>
                                                                                                  {
                                                                                                      item.name
                                                                                                  }
                                                                                              </span>
                                                                                          </NavLink>
                                                                                      </li>
                                                                                  );
                                                                              }
                                                                          }
                                                                      )}
                                                                  </ul>
                                                              </div>
                                                          </li>
                                                      </React.Fragment>
                                                  );
                                              })}
                                          </ul>
                                      </div>
                                  ) : null}
                                    {isMobile &&
                                  window.screen.width <= 960 && process.env.REACT_APP_ENV === 'dev' &&
                                  data.menuName === 'Chat' ? (
                                      <div className='menu-submenu '>
                                          <i className='menu-arrow' />
                                          <ul className='menu-subnav'>
                                              <li
                                                  className='menu-item  menu-item-parent'
                                                  aria-haspopup='true'
                                              >
                                                  <span className='menu-link'>
                                                      <span className='menu-text'>
                                                          {data.menuName}
                                                      </span>
                                                  </span>
                                              </li>
                                          </ul>                                        
                                      </div>
                                  ) : null}
                                  {isMobile &&
                                  window.screen.width <= 960 &&
                                  data.menuName === 'Search' ? (
                                      <div className='menu-submenu '>
                                          <i className='menu-arrow' />
                                          <ul className='menu-subnav'>
                                              <li
                                                  className='menu-item  menu-item-parent'
                                                  aria-haspopup='true'
                                              >
                                                  <span className='menu-link'>
                                                      <span className='menu-text'>
                                                          {data.menuName}
                                                      </span>
                                                  </span>
                                              </li>
                                              {patientRecordset !== '' &&
                                              data.recordSets.includes(
                                                  patientRecordset
                                              ) ? (
                                                  <li
                                                      key={'search-option-1'}
                                                      className={`menu-item ${getMenuItemActive(
                                                          '/search/patients'
                                                      )} ${getMenuItemActive(
                                                          '/search'
                                                      )}`}
                                                      aria-haspopup='true'
                                                  >
                                                      <NavLink
                                                          className={`menu-link ${getMenuItemActive(
                                                              '/search/patients'
                                                          )} ${getMenuItemActive(
                                                              '/search'
                                                          )}`}
                                                          title='Patient'
                                                          id='menu_search_patient'
                                                          to='/search/patients'
                                                      >
                                                          <i className='fa-solid fa-user'></i>
                                                          <span className='menu-text'>
                                                              Patients
                                                          </span>
                                                      </NavLink>
                                                  </li>
                                              ) : null}
                                              {physicianRecordset !== '' &&
                                              data.recordSets.includes(
                                                  physicianRecordset
                                              ) ? (
                                                  <li
                                                      key={'search-option-2'}
                                                      className={`menu-item ${getMenuItemActive(
                                                          '/search/patients'
                                                      )}`}
                                                      aria-haspopup='true'
                                                  >
                                                      <NavLink
                                                          className={`menu-link ${getMenuItemActive(
                                                              '/search/physicians'
                                                          )}`}
                                                          title='Physician'
                                                          id='menu_search_physician'
                                                          to='/search/physicians'
                                                      >
                                                          <i className='fa-solid fa-user-doctor'></i>
                                                          <span className='menu-text'>
                                                              Physicians
                                                          </span>
                                                      </NavLink>
                                                  </li>
                                              ) : null}
                                          </ul>

                                          <span
                                              className='sub-title-menu custome-search-queries'
                                              onClick={() => {
                                                  doCollapse();
                                              }}
                                          >
                                              <i className='fa-solid fa-file-magnifying-glass'></i>
                                              <p className='menu-text'>
                                                CUSTOM SEARCH QUERIES 
                                              </p>{' '}
                                          </span>
                                          <ul
                                              className='menu-subnav menu-nav p-0 custom-search'
                                              key='search-custom'
                                          >
                                              {data.searchQueries.map(
                                                  (value, index) => {
                                                      return (
                                                          <React.Fragment
                                                              key={
                                                                  'aside-frag-' +
                                                                  index
                                                              }
                                                          >
                                                              {customSearchQueries.map(
                                                                  (
                                                                      item,
                                                                      index
                                                                  ) => {
                                                                      if (
                                                                          item.id ==
                                                                          value
                                                                      ) {
                                                                          return (
                                                                              <li
                                                                                  className='menu-item '
                                                                                  aria-haspopup='true'
                                                                              >
                                                                                  <NavLink
                                                                                      className={`menu-link ${getMenuItemActive(
                                                                                          '/search/custom-search/' +
                                                                                              item.id
                                                                                      )}`}
                                                                                      to={
                                                                                          '/search' +
                                                                                          '/' +
                                                                                          'custom-search' +
                                                                                          '/' +
                                                                                          formatNameWithSpaceTohipen(
                                                                                              item.name
                                                                                          ) +
                                                                                          '/' +
                                                                                          item.id
                                                                                      }
                                                                                  >
                                                                                      {/*'/'+current_menu+'/'+String(val.name).replace(/\s/g,'').toLowerCase()*/}
                                                                                      {/* <i
																							   style={{
																								  color: val.color,
																							  }}
																							  className={`fas d-flex align-items-center `}
																		  ></i>*/}

                                                                                      <span className='menu-text'>
                                                                                          {
                                                                                              item.name
                                                                                          }
                                                                                      </span>
                                                                                  </NavLink>
                                                                              </li>
                                                                          );
                                                                      }
                                                                  }
                                                              )}
                                                          </React.Fragment>
                                                      );
                                                  }
                                              )}
                                          </ul>
                                      </div>
                                    ) : null}
                                    {(isMobile &&
                                        window.screen.width <= 960 &&
                                        (data.menuName === 'Inbox' || data.menuName === 'Outbox'))?
                                    (                                     
                                        <div className='menu-submenu '>
                                            <i className='menu-arrow' />
                                            <ul className='menu-subnav'>
                                                <li
                                                    className='menu-item  menu-item-parent'
                                                    aria-haspopup='true'
                                                >
                                                    <span className='menu-link'>
                                                        <span className='menu-text'>
                                                            {data.menuName}
                                                        </span>
                                                    </span>
                                                </li>
                                                {data.processes.map((item, index)=>{
                                                    let statusName='';
                                                    let initialStatusId = item.statusIds[0]
                                                    let processId =item.processId
                                                    item.statuses.map((itx)=>{
                                                        if(itx.statusId === initialStatusId ){
                                                            statusName=formatNameWithSpaceTohipen(itx.name)
                                                        }
                                                    })
                                                    let mainMenu = formatNameWithSpaceTohipen(data.menuName)
                                                    let process_steps = 'p_' + item.processId; //+'_s_'+val.statusId;
                                                  
                                                    let processCount = ''
                                                    if (CounterData[process_steps]) {
                                                        processCount = CounterData[process_steps];
                                                    }
                                                    const sortedStatuses = item.statuses.sort((a, b) => a.statusId - b.statusId);
                                                    return(
                                                        <ul
                                                            className='menu-subnav menu-nav'
                                                            key ={index} 
                                                        >
                                                        
                                                            <li                                                                
                                                                className={`menu-item menu-item-submenu	`}
                                                                aria-haspopup='true'
                                                                data-menu-toggle='hover'
                                                                key={item.processName}
                                                                title={item.processName}
                                                            >
                                                                <NavLink
                                                                    className={String(location.pathname).includes(processId) ?
                                                                        'menu-link menu-toggle active' : 'menu-link menu-toggle'
                                                                    }
                                                                    //id='menu_search_1'               
                                                                                        
                                                                    to={`/${mainMenu}/${statusName}/${processId}/${initialStatusId}`}
                                                                >
                                                                    <span className='menu-text'>
                                                                        {item.processName}
                                                                    </span>
                                                                    {processCount && processCount !== '' ? (
                                                                        <b className='count ml-2 mt-0'>
                                                                            {processCount}
                                                                        </b>
                                                                    ) : null}
                                                                    <i className='menu-arrow' />
                                                                </NavLink>
                                                                <div className='menu-submenu '>
                                                                    <i className='menu-arrow' />
                                                                    <ul className='menu-subnav'>
                                                                        <li
                                                                            className='menu-item  menu-item-parent'
                                                                            aria-haspopup='true'
                                                                           key={item.processId}  
                                                                        >
                                                                            <span className='menu-link'>
                                                                                <span className='menu-text'>
                                                                                    {item.processName}
                                                                                </span>
                                                                                
                                                                            </span>
                                                                        </li>
                                                                        {sortedStatuses.map((category, catindex)=>{
                                                                            let subStatusName = formatNameWithSpaceTohipen(category.name)
                                                                            let status_steps = process_steps +'_s_' +category.statusId;
                                                                            let sub_count = ''
                                                                            if (
                                                                                CounterData[status_steps]
                                                                            ) {
                                                                                sub_count =CounterData[status_steps];
                                                                            }
                                                                            let countstyle = {};
                                                                            if (category.color) {
                                                                                let hex = category.color;
                                                                                let red = parseInt(
                                                                                    hex[1] + hex[2],
                                                                                    16
                                                                                );
                                                                                let green = parseInt(
                                                                                    hex[3] + hex[4],
                                                                                    16
                                                                                );
                                                                                let blue = parseInt(
                                                                                    hex[5] + hex[6],
                                                                                    16
                                                                                );
                                                                                countstyle = {
                                                                                    color: category.color,
                                                                                    backgroundColor:
                                                                                        'rgb(' +
                                                                                        red +
                                                                                        ', ' +
                                                                                        green +
                                                                                        ', ' +
                                                                                        blue +
                                                                                        ',.25)',
                                                                                };
                                                                            }
                                                                            let status_icon_font = category.icon;
                                                                            if (status_icon_font === null)
                                                                                status_icon_font = 'fas fa-envelope-open-text';
                                                                            let status_icon_color = category.color;
                                                                            return(  
                                                                                <li
                                                                                    className='menu-item '
                                                                                    aria-haspopup='true'
                                                                                    key ={catindex} 
                                                                                    
                                                                                >
                                                                                    <NavLink
                                                                                        className={`menu-link`}                                                                                          
                                                                                         to={`/${mainMenu}/${subStatusName}/${processId}/${category.statusId}`}                                   
                                                                                    >
                                                                                        <i
                                                                                            style={{
                                                                                                color: category.color,
                                                                                            }}
                                                                                            className={`fas ${status_icon_font} d-flex align-items-center `}
                                                                                        ></i>
                                                                                        <span className='menu-text'>
                                                                                            {category.name}                                     
                                                                                        </span>
                                                                                         {sub_count !== '' ? (
                                                                                            <b
                                                                                                style={
                                                                                                    countstyle
                                                                                                }
                                                                                                className='count'
                                                                                            >
                                                                                                {
                                                                                                    sub_count
                                                                                                }
                                                                                            </b>
                                                                                        ) : null}
                                                                                    </NavLink>
                                                                                </li> 
                                                                            )
                                                                        })}                                                           
                                                                    </ul>
                                                                </div>
                                                            </li>                                                        
                                                        </ul>
                                                    )
                                                })}
                                                 
                                            </ul>
                                        </div> 
                                  ) : null}
                                 
                              </li>
                          );
                      })
                    : null}
                    {adminTags &&
                        <li
                            className={`menu-item menu-item-rel ${getMenuItemActive(
                                '/admin'
                            )}`}
                        >
                            <NavLink
                                title='admin'
                                className='menu-link'
                                to='/admin'
                            >
                                <span className='menu-text text-white'>
                                    Admin
                                </span>
                                {layoutProps.rootArrowEnabled && (
                                    <i className='menu-arrow' />
                                )}
                            </NavLink>
                        </li>}
                {/* Upload Pages*/}
                {isMobile && window.screen.width <= 960 ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            '/upload',
                            true
                        )} ${getMenuItemActive('/send-to-fax', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'
                    >
                        <NavLink className='menu-link menu-toggle' to='/upload'>
                            <span className='svg-icon menu-icon'>
                                <SVG
                                    alt='upload-user'
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/Media/upload-user.svg'
                                    )}
                                />
                            </span>
                            <span className='menu-text'>Upload</span>
                            <i className='menu-arrow' />
                        </NavLink>
                        <div className='menu-submenu '>
                            <i className='menu-arrow' />
                            <ul className='menu-subnav'>
                                <li
                                    className='menu-item  menu-item-parent'
                                    aria-haspopup='true'
                                >
                                    <span className='menu-link'>
                                        <span className='menu-text'>
                                            Upload
                                        </span>
                                    </span>
                                </li>
                                {uploadTypes.length > 0
                                    ? uploadTypes.map((data) => {
                                          var text = '';
                                          var link_url = '';
                                          if (data.type_flag == 'Upload') {
                                              text = 'Upload Document';
                                              link_url =
                                                  '/upload/' + data.docId;
                                          } else if (data.type_flag == 'Fax') {
                                              text = 'Send To Fax';
                                              link_url =
                                                  '/send-to-fax/' + data.docId;
                                          }
                                          return (
                                              <li
                                                  aria-haspopup='true'
                                                  key={
                                                      'first-level-upload-browse-' +
                                                      data.docId
                                                  }
                                                  className={`menu-item ${getMenuItemActive(
                                                      '/upload/' + data.docId
                                                  )}`}
                                              >
                                                  <NavLink
                                                      className='menu-link'
                                                      to={link_url}
                                                      title='Upload Document'
                                                  >
                                                      <span className='menu-text'>
                                                          {text}
                                                      </span>
                                                  </NavLink>
                                              </li>
                                          );
                                      })
                                    : null}
                            </ul>
                        </div>
                    </li>
                ) : null}

                {/* Upload Pages*/}

                {/* User DDL*/}
                {isMobile && window.screen.width <= 960 ? (
                    <li
                        className={`menu-item menu-item-submenu  ${getMenuItemActive(
                            '/users/',
                            true
                        )} ${getMenuItemActive('/change-password', true)} `}
                        aria-haspopup='true'
                        data-menu-toggle='hover'
                    >
                        <NavLink className='menu-link menu-toggle' to='/users'>
                            <span className='svg-icon menu-icon'>
                                <SVG
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/General/User.svg'
                                    )}
                                />
                            </span>
                            <span alt='user' className='menu-text'>
                                {userDetail.userDisplayName}
                            </span>
                            <i className='menu-arrow' />
                        </NavLink>
                        <div className='menu-submenu '>
                            <i className='menu-arrow' />
                            <ul className='menu-subnav'>
                                <li
                                    className='menu-item  menu-item-parent'
                                    aria-haspopup='true'
                                >
                                    <span className='menu-link'>
                                        <span className='menu-text'>
                                            {userDetail.userDisplayName}
                                        </span>
                                    </span>
                                </li>

                                <li
                                    key={'first-level-user-1'}
                                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                                        '/users/my-account'
                                    )} ${getMenuItemActive(
                                        '/users/change-password'
                                    )}`}
                                >
                                    <NavLink
                                        className='menu-link menu-toggle'
                                        to='/users/my-account/'
                                        title='Settings & Preferences'
                                    >
                                        <span className='menu-text'>
                                            Settings & Preferences
                                        </span>
                                        <i className='menu-arrow' />
                                    </NavLink>
                                    <div className='menu-submenu '>
                                        <i className='menu-arrow' />
                                        <ul className='menu-subnav'>
                                            <li
                                                className='menu-item  menu-item-parent'
                                                aria-haspopup='true'
                                            >
                                                <span className='menu-link'>
                                                    <span className='menu-text'>
                                                        Settings & Preferences
                                                    </span>
                                                </span>
                                            </li>
                                            <li
                                                key={'first-level-user-24'}
                                                className={`menu-item ${getMenuItemActive(
                                                    '/users/my-account'
                                                )}`}
                                            >
                                                <NavLink
                                                    className='menu-link'
                                                    title='My Account'
                                                    to='/users/my-account'
                                                >
                                                    <span className='menu-text'>
                                                        My Account
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li
                                                key={'first-level-user-11'}
                                                className={`menu-item ${getMenuItemActive(
                                                    '/users/change-password'
                                                )}`}
                                            >
                                                <NavLink
                                                    className='menu-link'
                                                    title='My Account'
                                                    to='/users/change-password'
                                                >
                                                    <span className='menu-text'>
                                                        Change Password
                                                    </span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li
                                    key={'first-level-user-22'}
                                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                                        '/users/users'
                                    )} ${getMenuItemActive('/users/groups')}`}
                                    style={{
                                        display: show_user_management
                                            ? 'block'
                                            : 'none',
                                    }}
                                >
                                    <NavLink
                                        className='menu-link  menu-toggle'
                                        title='User Management'
                                        to='users'
                                    >
                                        <span className='menu-text'>
                                            User Management
                                        </span>
                                        <i className='menu-arrow' />
                                    </NavLink>
                                    <div className='menu-submenu '>
                                        <i className='menu-arrow' />
                                        <ul className='menu-subnav'>
                                            <li
                                                className='menu-item  menu-item-parent'
                                                aria-haspopup='true'
                                            >
                                                <span className='menu-link'>
                                                    <span className='menu-text'>
                                                        User Management
                                                    </span>
                                                </span>
                                            </li>
                                            <li
                                                key={'first-level-user-2'}
                                                className={`menu-item ${getMenuItemActive(
                                                    '/users/my-account'
                                                )}`}
                                            >
                                                <NavLink
                                                    className='menu-link'
                                                    title='User Management'
                                                    to='/users/users'
                                                >
                                                    <span className='menu-text'>
                                                        User Management
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li
                                                key={'first-level-user-3'}
                                                className={`menu-item ${getMenuItemActive(
                                                    '/users/groups'
                                                )}`}
                                            >
                                                <NavLink
                                                    className='menu-link'
                                                    title='Groups Management'
                                                    to='/users/groups'
                                                >
                                                    <span className='menu-text'>
                                                        Groups Management
                                                    </span>
                                                </NavLink>
                                            </li>
											
                                        </ul>
                                    </div>
                                </li>
                                <li key={'first-level-user-add'}>
                                    <div
                                        className='left-nav-add-user'
                                        style={                                           
                                            {
                                                display: show_user_management
                                                    ? 'block'
                                                    : 'none',
                                                marginLeft: '10px'
                                            }
                                        }
                                    >
                                        {' '}
                                        <Button
                                            className='empty'
                                            variant='success'
                                            onClick={() => addNewUserAction()}
                                            title='Add New User'
                                        >
                                            <i className='fas fa-plus'></i> Add
                                            New User
                                        </Button>
                                    </div>
                                </li>
								<li
                                    key={'first-level-user-3'}
                                    className={`menu-item ${getMenuItemActive(
                                        '/auth/logout'
                                    )}`}
                                >
                                    <span
                                        className='menu-link'
                                        title='Logout'
                                        onClick={logoutClick}
                                    >
                                        <i className='fas fa-sign-out-alt align-items-center pr-3'></i>
                                        <span className='menu-text'>
                                            Logout
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>
                ) : null}

                {/* User DDL*/}
            </ul>
            {/*end::Header Nav*/}
        </div>
    );
}
