/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import ReactDOM from 'react-dom';
import jsPDF from 'jspdf';
import {
    // formatNameWithSpaceToUnderscore,
    reportColorCodeArray,
    getRandomColors,
    sortObjectByKeys,
    formatHeaderNameWithOutSpace,
    reportPhysicianColorCode,
} from '../../components/commonfunction';
import { deletedPage } from '../_redux/actions/searchActions';
import Spinner from '../outbox/components/Spinner';
import SearchFilter from '../../components/searchFilter/SearchFilter';

const _ = require('lodash');

class StackedBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTxtTimeId: null,
            noDataFound: this.props.noDataFound,
            barSeriesPage: 1,
            filteredData: this.props.filteredData,
            filterTxt: this.props.filterTxt,
            rows: this.props.rows,
            totalCount: this.props.totalCount,
            graphicalField: this.props.graphicalField,
            statusCount: 0,
            outboundStatus: [],
            allRowData: this.props.filteredData,
            currentPage: 1,
            totalPhysician: 0,
            numberPhysician: [],
            chartFilterTxt: '',
            barImgUrl: '',
            filterCondition: false,
            // stacked bar
            barseries: [
                {
                    name: 'FAX IN PROGRESS',
                    data: [44, 0, 41, 37, 22, 43, 0],
                },
                {
                    name: 'ERROR',
                    data: [53, 32, 0, 52, 13, 43, 32],
                },
                {
                    name: 'REMOVED',
                    data: [12, 17, 0, 9, 0, 11, 0],
                },
                {
                    name: 'AWAITING SIGNATURE',
                    data: [0, 7, 5, 8, 6, 0, 4],
                },
            ],
            baroptions: {
                chart: {
                    id: `stack`,
                    type: 'bar',
                    height: 600,
                    width: 715,
                    stacked: true,
                    toolbar: {
                        //         show: true,
                        tools: {
                            download: true,
                            selection: true,
                            //zoom: true,
                            // zoomin: true,
                            //zoomout: true,
                            //pan: true,
                            reset:
                                true |
                                '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [
                                {
                                    icon: '<i class="fa-solid fa-file-arrow-down"></i>',
                                    class: 'custom-icon',
                                    index: 0,
                                    title: 'Download PDF',
                                    click: () => {
                                        this.downloadPDF();
                                    },
                                },
                            ],
                        },
                    },
                    events: {
                        legendClick: function (
                            chartContext,
                            seriesIndex,
                            config
                        ) {
                            props.radialChartOnClick(
                                config.config.series[seriesIndex].name,
                                'Stack'
                            );
                        },
                    },
                    animations: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    // default: true,
                    //enabled: true,
                    style: {
                        fontSize: '11px',
                    },
                },
                legend: {
                    // default: true,
                    showForSingleSeries: true,
                    show: true,
                    position: 'top',
                    horizontalAlign: 'left',
                    // offsetX: 0,
                    width: '50%',
                    fontSize: '13px',
                    labels: {
                        colors: undefined,
                        useSeriesColors: false,
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        radius: 12,
                        /* onClick: function (val) {
                               
                                    return val;
                                },
                          offsetX: 0,
                          offsetY: 0 */
                    },
                    onItemClick: {
                        toggleDataSeries: false,
                        // highlightDataSeries: true,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '70%',
                    },
                },
                stroke: {
                    width: 0,
                    colors: ['#fff'],
                },
                /* title: {
                     show: false,
                    text: 'Fiction Books Sales',
                }, */
                xaxis: {
                    categories: [
                        'phy1',
                        'phy2',
                        'phy3',
                        'phy4',
                        'phy5',
                        'phy6',
                        'phy7',
                    ],
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                    show: false,
                },
                /* yaxis: {
                    title: {
                        text: undefined,
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + 'K';
                        },
                    },
                }, */
                /* fill: {
                    opacity: 1,
                },
 */
                colors: ['#0029BC', '#00B1BC', '#8DC63F', '#ECB445', '#D84C3A'],
                responsive: [
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                width: '100%',
                                height: 350,
                            },
                            legend: {
                                show: true,
                                position: 'bottom',
                                horizontalAlign: 'center',
                                width: '100%',
                                fontSize: '10px',
                            },
                        },
                    },
                ],
            },
            nextPrevLoader: 0,
        };

        this.chartFilter = this.chartFilter.bind(this);
    }

    async componentDidMount() {
        if (
            this.props.deleted_Page.chartFilterTxt !== '' &&
            this.props.deleted_Page.chartFilterTxt !== undefined &&
            // eslint-disable-next-line eqeqeq
            this.props.deleted_Page.stackFilter == '' &&
            this.props.deleted_Page.stackFilter === undefined
        ) {
            await this.setState({
                filterCondition: true,
            });
            await this.refreshStackedBar();
            await this.props.radialChartOnClick(
                this.props.chartFilterTxt,
                this.props.deleted_Page.chartName
            );
        } else if (
            this.props.deleted_Page.stackFilter !== '' &&
            this.props.deleted_Page.chartFilterTxt !== '' &&
            this.props.deleted_Page.chartFilterTxt !== undefined &&
            (this.props.deleted_Page.stackFilter === '' ||
                this.props.deleted_Page.stackFilter === undefined)
        ) {
            await this.setState({
                filterCondition: true,
            });
            await this.refreshStackedBar();
            await this.chartFilter(this.props.deleted_Page.stackFilter);
            await this.setState({
                chartFilterTxt: this.props.deleted_Page.stackFilter,
            });
            await this.props.radialChartOnClick(
                this.props.deleted_Page.chartFilterTxt,
                this.props.deleted_Page.chartName
            );
        } else if (
            this.props.deleted_Page.stackFilter !== '' &&
            this.props.deleted_Page.stackFilter !== '' &&
            this.props.deleted_Page.chartFilterTxt !== '' &&
            this.props.deleted_Page.chartFilterTxt !== undefined
        ) {
            await this.setState({
                chartFilterTxt: this.props.deleted_Page.stackFilter,
            });
            await this.chartFilter(this.props.deleted_Page.stackFilter);
        } else if (this.props.deleted_Page.stackFilter !== '') {
            await this.setState({
                chartFilterTxt: this.props.deleted_Page.stackFilter,
            });
            await this.chartFilter(this.props.deleted_Page.stackFilter);

            // setTimeout(
            //     () => {
            //         this.props.radialChartOnClick(
            //             this.props.deleted_Page.chartFilterTxt,
            //             'Stack'
            //         );
            //     },3000
            // );
        } else {
            await this.drawBar(this.state.currentPage);
        }
    }
    loadNext = async () => {
        if (this.props.chartName !== 'Stack') {
            await this.refreshStackedBar();
            await this.props.updateState({
                chartName: 'Stack',
            });
            await this.props.deletedPage({
                chartName: 'Stack',
            });
        }
        if (this.state.currentPage < this.state.totalPhysician) {
            const next = this.state.currentPage + 1;
            this.setState({ currentPage: next });
            this.drawBar(next);
        }

        this.setState({
            nextPrevLoader: 0,
        });
    };

    loadPrev = async () => {
        if (this.props.chartName !== 'Stack') {
            await this.refreshStackedBar();
            await this.props.updateState({
                chartName: 'Stack',
            });
            await this.props.deletedPage({
                chartName: 'Stack',
            });
        }
        if (this.state.currentPage > 1) {
            const prev = this.state.currentPage - 1;
            this.setState({ currentPage: prev });
            this.drawBar(prev);
        }
        this.setState({
            nextPrevLoader: 0,
        });
    };

    async componentDidUpdate(prevState, prevProps) {
        if (
            _.isEqual(this.state.filteredData, this.props.filteredData) ===
                false &&
            this.state.chartFilterTxt !== ''
        ) {
            this.setState(
                {
                    filteredData: this.props.filteredData,
                    currentPage: 1,
                },
                () => {
                    this.drawBar(this.state.currentPage);
                }
            );
        }
        if (
            _.isEqual(this.state.filteredData, this.props.filteredData) ===
                false &&
            this.props.filterTxt !== '' &&
            // eslint-disable-next-line eqeqeq
            this.props.chartName == ''
        ) {
            this.setState(
                {
                    filteredData: this.props.filteredData,
                    currentPage: 1,
                },
                () => {
                    this.drawBar(this.state.currentPage);
                }
            );
        }
        // eslint-disable-next-line eqeqeq
        if (prevState.filterTxt !== '' && this.props.filterTxt == '') {
            await this.setState({
                filterCondition: true,
            });
            await this.drawBar(1);
        }
        if (
            this.props.stackFilter === undefined &&
            prevState.stackFilter !== undefined &&
            prevState.stackFilter !== '' &&
            this.props.chartName === 'Radial'
        ) {
            this.setState({
                chartFilterTxt: '',
            });
            await this.refreshStackedBar();
            await this.props.radialChartOnClick(
                this.props.chartFilterTxt,
                'Stack'
            );
        }
        if (this.props.noDataOnClick === true) {
            this.refreshStackedBar();
        }
        // Next Previous loader handling
        if (prevProps.nextPrevLoader !== this.state.nextPrevLoader) {
            switch (this.state.nextPrevLoader) {
                case -1:
                    return setTimeout(async () => {
                        this.loadPrev();
                    }, 100);
                case 1:
                    return setTimeout(async () => {
                        this.loadNext();
                    }, 100);
            }
        }
        // Search Text
        if (prevProps.chartFilterTxt !== this.state.chartFilterTxt) {
            if (this.state.searchTxtTimeId == null) {
                this.state.searchTxtTimeId = setTimeout(
                    () => this.chartFilter(this.state.chartFilterTxt),
                    500
                );
            } else {
                clearTimeout(this.state.searchTxtTimeId);
                this.state.searchTxtTimeId = setTimeout(
                    () => this.chartFilter(this.state.chartFilterTxt),
                    500
                );
            }
        }
        // This condition handled for refersh entire data grid , chart filter text alone not cleared
        if (
            this.props.deleted_Page.chartFilterTxt === '' &&
            this.props.deleted_Page.refreshTableDataGrid === true
        ) {
            this.setState({
                chartFilterTxt: '',
            });
            this.props.deletedPage({
                refreshTableDataGrid: false,
            });
        }
    }

    async chartFilter(searchText = '') {
        // Block Code S1008 For ( unwanted Render )
        if (this.props.chartFilterTxt !== '') {
            await this.refreshStackedBar();
        }

        await this.setState({ chartFilterTxt: searchText });
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
        const isValid = (str) => {
            // eslint-disable-next-line no-useless-escape
            return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText) === false) {
            // Blocking Special Character
            return [];
        }
        // End

        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();

        let allData = this.props.allRows;
        if (this.props.filterTxt !== '') {
            allData = this.props.filteredData;
        }
        let graphicalField = this.state.graphicalField
            ? this.state.graphicalField
            : '';
        var promise = new Promise(function (resolve, reject) {
            const value = _.filter(allData, function (data) {
                // filter Operation
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        // eslint-disable-next-line no-continue
                        continue;
                    } // Excluding few Property from filter

                    let dataFilter = data[graphicalField];
                    passed =
                        String(dataFilter).toLowerCase().match(inputData) !=
                            null &&
                        String(dataFilter).toLowerCase().match(inputData)
                            .length > 0;
                    if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                }
                return passed;
            });
            resolve(value);
        });
        if (searchText == '') {
            let filteredData = allData;
            this.setState({
                chartfilteredData: filteredData,
                filteredData: filteredData,
                chartName: 'Stack',
                noDataFound: filteredData.length === 0,
            });
            this.props.updateState({
                noDataFound: filteredData.length === 0,
                chartName: 'Stack',
                stackFilter: inputData,
                filteredData: filteredData,
                chartfilteredData: filteredData,
                // filterTxt: searchText,
                filteredRowData: filteredData.slice(0, 10),
                totalCount: filteredData.length,
                isFilterRowEmpty: filteredData.length === 0,
                //chartFilterTxt: searchText,
            });
            this.props.deletedPage({
                stackFilter: inputData,
            });
            this.props.resetPopOverData(filteredData);

            this.drawBar(1);
        } else {
            promise
                .then(async (filteredData) => {
                    this.setState({
                        chartfilteredData: filteredData,
                        filteredData: filteredData,
                        chartName: 'Stack',
                        noDataFound: filteredData.length === 0,
                    });
                    this.props.updateState({
                        chartName: 'Stack',
                        noDataFound: filteredData.length === 0,
                        stackFilter: inputData,
                        filteredData: filteredData,
                        chartfilteredData: filteredData,
                        // filterTxt: searchText,
                        filteredRowData: filteredData.slice(0, 10),
                        totalCount: filteredData.length,
                        isFilterRowEmpty: filteredData.length === 0,
                        //chartFilterTxt: searchText,
                    });
                    this.props.deletedPage({
                        stackFilter: inputData,
                    });
                    this.props.resetPopOverData(filteredData);

                    this.drawBar(1);
                })
                .catch((data) => {});
        }

        // const filteredData = _.filter(allData, function (data) {
        //     // filter Operation
        //     let passed = false;
        //     for (const property in data) {
        //         if (
        //             property === 'doc_id' ||
        //             property === 'tag_id' ||
        //             property === 'id'
        //         ) {
        //             // eslint-disable-next-line no-continue
        //             continue;
        //         } // Excluding few Property from filter

        //         let dataFilter = data[graphicalField];
        //         passed =
        //             String(dataFilter).toLowerCase().match(inputData) != null &&
        //             String(dataFilter).toLowerCase().match(inputData).length >
        //                 0;
        //         if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
        //     }
        //     return passed;
        // });

        // if (filteredData.length === 0) {
        //     this.setState({
        //         noDataFound: true,
        //     });
        //     this.props.updateState({
        //         noDataFound: true,
        //     });
        // } else {
        //     this.setState({
        //         noDataFound: false,
        //     });
        //     this.props.updateState({
        //         noDataFound: false,
        //     });
        // }
        // await this.setState({
        //     chartfilteredData: filteredData,
        //     filteredData: filteredData,
        //     chartName: 'Stack',
        // });
        // await this.props.updateState({
        //     chartName: 'Stack',
        //     stackFilter: inputData,
        //     filteredData: filteredData,
        //     chartfilteredData: filteredData,
        //     // filterTxt: searchText,
        //     filteredRowData: filteredData,
        //     totalCount: filteredData.length,
        //     isFilterRowEmpty: filteredData.length === 0,
        // });
        // this.props.deletedPage({
        //     stackFilter: inputData,
        // });
        // await this.props.resetPopOverData(filteredData);

        // await this.drawBar(1);
    }

    barseriesFunction = async (result, page) => {
        /*  const sortedResult = sortObjectByKeys(result);
            const numberOfPages = Object.keys(sortedResult).length / 30;

            this.setState({
                totalPhysician: Object.keys(sortedResult).length,
                numberOfPages: parseInt(numberOfPages, 10),
            });

            const start = (page - 1) * 30;
            const limit = page * 30;
            // var o={a:7, b:8, c:9};
            const limitedResult = {};

            for (let index = start; index < limit; index++) {
                const key = Object.keys(sortedResult)[index];

                if (sortedResult[key] !== undefined) {
                    limitedResult[key] = result[key];
                }
            }

          */
        let limit = this.state.currentPage * 30;
        let start = (page - 1) * 30;

        const physicianName = [];
        let newObject = {};
        const detailData = [];
        if (result !== undefined) {
            for (const property in result) {
                if (result[property] !== undefined) {
                    physicianName.push(property);
                    const newArray = result[property];
                    const eachOutboundStatus = [];
                    newArray.map((data) => {
                        eachOutboundStatus.push(data[this.props.barseriesData]);
                    });

                    const counts = {};
                    eachOutboundStatus.forEach((x) => {
                        counts[x] = (counts[x] || 0) + 1;
                    });

                    newObject = {
                        [property]: counts,
                    };
                    detailData.push(newObject);
                }
            }

            let detailUser = {};
            // var statusArrayTemp = this.props.outboundstatus;
            // const sortedStatus = statusArrayTemp.sort((a, b) => a-b);
            // const sortedStatus = statusArrayTemp.sort((a, b) => a-b);

            await detailData.map(async (item) => {
                // eslint-disable-next-line guard-for-in
                for (const property in item) {
                    let fillEmpty = {};
                    const newSet = item[property];
                    let arraySetConvert = [];
                    arraySetConvert = Array.from(
                        this.props.outboudData[this.props.barseriesData] ||
                            arraySetConvert
                    );
                    arraySetConvert = arraySetConvert.sort();
                    // eslint-disable-next-line no-loop-func
                    arraySetConvert.forEach((data) => {
                        for (const status in newSet) {
                            if (String(status) === String(data)) {
                                fillEmpty = {
                                    ...fillEmpty,
                                    [data]: newSet[status],
                                };
                                break;
                            } else {
                                fillEmpty = {
                                    ...fillEmpty,
                                    [data]: 0,
                                };
                            }
                        }

                        detailUser = {
                            ...detailUser,
                            [property]: fillEmpty,
                        };
                    });
                }
            });

            const barseries = [];
            let statusArrayProp;
            let inputlength;

            let randomcolorsArray;

            let arraySetConvert;
            if (
                this.props.outboudData !== undefined &&
                this.props.barseriesData !== undefined &&
                this.props.outboudData[this.props.barseriesData] !== undefined
            ) {
                arraySetConvert = Array.from(
                    this.props.outboudData[this.props.barseriesData]
                );
                arraySetConvert = arraySetConvert.sort();

                statusArrayProp = Array.from(
                    this.props.outboudData[this.props.barseriesData]
                );
                inputlength = statusArrayProp.length;

                randomcolorsArray = await this.getRandomColorCodeArray(
                    inputlength,
                    page
                );
                const numberOfPages = arraySetConvert.length / 30;
                this.setState({
                    totalPhysician: arraySetConvert.length,
                    numberOfPages: parseInt(numberOfPages, 10),
                });
                arraySetConvert.forEach((data, index) => {
                    if (index >= start && index < limit) {
                        const countStatus = [];
                        // eslint-disable-next-line guard-for-in
                        for (const property in detailUser) {
                            const seprateCount = detailUser[property];
                            for (const key in seprateCount) {
                                if (key === data) {
                                    countStatus.push(seprateCount[key]);
                                }
                            }
                        }
                        if (data !== '') {
                            barseries.push({
                                name: data,
                                data: countStatus,
                            });
                        } else {
                            barseries.push({
                                name: 'Blank',
                                data: countStatus,
                            });
                        }
                    }
                });
            }

            this.setState({
                baroptions: {
                    ...this.state.baroptions,
                    xaxis: {
                        ...this.state.baroptions.xaxis,
                        categories: physicianName,
                    },
                    colors: randomcolorsArray,
                },
                barseries: barseries,
            });
            setTimeout(() => {
                /* this.setState({
                    noDataFound: false,
                })   */
            }, 2000);
        }
    };

    physicianSplittedArray = async (array, page_size, page_number) => {
        return array.slice(
            page_number * page_size,
            page_number * page_size + page_size
        );
    };

    drawBar = async (page) => {
        /* await this.setState({
            noDataFound: true,
        }) */
        let allData;

        if (
            this.state.chartFilterTxt !== '' &&
            this.state.chartFilterTxt !== undefined &&
            this.state.filterCondition !== true
        ) {
            allData = this.state.chartfilteredData;
        } else if (
            this.props.filterTxt !== '' &&
            this.props.filterTxt !== undefined &&
            this.state.filteredData !== undefined &&
            this.props.selectedRadialChart !== '' &&
            this.state.filterCondition !== true
        ) {
            allData = this.props.filterAllRows;
        } else if (
            this.props.filterTxt !== '' &&
            this.props.filterTxt !== undefined &&
            this.state.filteredData !== undefined &&
            this.props.selectedRadialChart === '' &&
            this.state.filterCondition !== true
        ) {
            allData = this.props.filteredData;
        } else if (this.state.filterCondition === true) {
            allData = this.props.allRows;
            this.setState({
                filterCondition: false,
            });
        } else if (
            this.props.chartFilterTxt !== '' &&
            this.props.chartFilterTxt !== undefined
        ) {
            allData = this.props.allRows;
        } else {
            // allData = this.props.allRows;
            allData = this.state.filteredData;
        }

        let statuscounting = 0;
        const graphicalField = this.state.graphicalField;

        let del;
        // error came if condition added
        // if (this.state.allRowData !== undefined) {
        this.props.OutboundStatusFumction(allData);

        if (allData !== undefined) {
            const result = await allData.reduce(function (r, a, c) {
                statuscounting++;

                del = a[graphicalField];
                if (del !== '' && del !== ',') {
                    if (del === '') del = '(Empty)';
                    // const formatted = formatNameWithSpaceToUnderscore(del);
                    r[del] = r[del] || [];
                    r[del].push(a);
                }
                return r;
            }, Object.create(null));

            const sortedResult = sortObjectByKeys(result);

            const numberOfPages = Object.keys(sortedResult).length / 10;

            this.setState({
                grpahicalFieldNameFormat2Length:
                    Object.keys(sortedResult).length,
                //numberOfPages: parseInt(numberOfPages, 10),
            });

            const start = (page - 1) * 10;
            const limit = page * 10;
            // var o={a:7, b:8, c:9};
            const limitedResult = {};

            for (let index = start; index < limit; index++) {
                const key = Object.keys(sortedResult)[index];

                if (sortedResult[key] !== undefined) {
                    limitedResult[key] = result[key];
                }
            }

            await this.barseriesFunction(sortedResult, page);

            await this.setState({
                statusCount: statuscounting,
                outboundStatus: sortedResult,
                // numberPhysician: limitedResult,
            });
        }
    };

    getRandomColorCodeArray = async (resultCount, page) => {
        /* let coun = 0;
        // eslint-disable-next-line eqeqeq
        if (resultCount != 0) {
            coun = resultCount;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const property in this.state.outboundStatus) {
                coun++;
            }
        }
        const colorCode = reportColorCodeArray();
        const originalColorCount = colorCode.length;
        const totalColorCount = Math.floor(originalColorCount / coun);

        const randomcolors = getRandomColors(colorCode, totalColorCount);

        return randomcolors; */
        let coun = 0;
        // eslint-disable-next-line eqeqeq
        if (resultCount != 0) {
            coun = resultCount;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const property in this.state.outboundStatus) {
                coun++;
            }
        }

        let colorCode = await reportColorCodeArray();
        let originalColorCount = colorCode.length;
        if (coun > originalColorCount) {
            colorCode = await reportPhysicianColorCode(coun, page);
            originalColorCount = colorCode.length;
        }
        const totalColorCount = Math.floor(originalColorCount / coun);
        const randomcolors = await getRandomColors(colorCode, totalColorCount);

        return randomcolors;
    };

    getDataUri = async (chartId) => {
        // eslint-disable-next-line no-return-await
        return await ApexCharts.exec(chartId, 'dataURI').then(({ imgURI }) => {
            this.setState({ barImgUrl: imgURI });
            return imgURI;
        });
    };

    downloadPDF = () => {
        this.getDataUri(this.state.baroptions.chart.id);

        setTimeout(() => {
            // eslint-disable-next-line new-cap
            const pdf = new jsPDF();
            const pdfname = `${formatHeaderNameWithOutSpace(
                this.props.pageTitle
            )}-${this.props.graphicalField}.pdf`;
            pdf.setFontSize(20);
            pdf.text(20, 25, this.props.pageTitle);
            pdf.addImage(this.state.barImgUrl, 'PNG', 10, 50);
            pdf.save(pdfname);
        }, 1000);
    };

    refreshStackedBar = async () => {
        const paginatedRowData = _.slice(this.props.allRows, 0, 10);
        await this.props.updateState({
            refreshStackedBar: true,
            stackFilter: undefined,
            filteredData: this.props.allRows,
            allRows: this.props.allRows,
            paginatedRowData: paginatedRowData,
            rowData: paginatedRowData,
            perPage: 10,
            chartFilterTxt: '',
            propertyTiles: 'All Data',
            selectedRadialChart: 'All Data',
            currentPage: 0,
            // totalCount: this.props.allRows.length,
        });
        await this.props.filterDataGrid(this.props.filterTxt);
        await this.setState({
            filteredData: this.props.allRows,
            allRows: this.props.allRows,
            chartFilterTxt: '',
            noDataFound: false,
        });
        await this.drawBar(1);
    };

    render() {
        return (
            <div className='report-doc'>
                <div className='report-doc-top'>
                    <h4>
                        Per {this.props.grpahicalFieldNameFormat2} (
                        {this.state.grpahicalFieldNameFormat2Length})
                    </h4>

                    <div className='filter-sec'>
                        {
                            // RemoveBy S1008, Separate component SearchFilter
                            /* <div className='filter'>
                                <
                                    type='text'
                                    placeholder=''
                                    className='form-control'
                                    value={this.state.chartFilterTxt}
                                    onChange={(event) => {
                                        this.setState({
                                            chartFilterTxt: event.target.value,
                                        });
                                        // this.chartFilter(event.target.value)
                                    }}
                                />
    
                                <i className='fa-regular fa-magnifying-glass' />
                            </div> */
                        }
                        {
                            // Custom SeachFilter
                        }
                        <SearchFilter
                            value={this.state.chartFilterTxt}
                            callBack={this.chartFilter}
                        />
                        <div className='rpt-navs'>
                            <span
                                className={
                                    this.state.currentPage > 1
                                        ? 'nav-btn'
                                        : 'nav-btn disabled'
                                }
                                onClick={() => {
                                    // this.loadPrev();
                                    this.setState({
                                        nextPrevLoader: -1,
                                    });
                                }}
                            >
                                <i className='fa-light fa-chevron-left' />
                            </span>
                            <span
                                className={
                                    this.state.totalPhysician > 30 &&
                                    this.state.numberOfPages >=
                                        this.state.currentPage
                                        ? 'nav-btn'
                                        : 'nav-btn disabled'
                                }
                                onClick={
                                    this.state.totalPhysician > 30 &&
                                    this.state.numberOfPages >=
                                        this.state.currentPage
                                        ? () => {
                                              this.setState({
                                                  nextPrevLoader: 1,
                                              });
                                          }
                                        : null
                                }
                            >
                                <i className='fa-light fa-chevron-right' />
                            </span>
                        </div>
                        <div className='reload-outer'>
                            <span
                                className='reload'
                                onClick={() => {
                                    this.refreshStackedBar();
                                    this.setState({ currentPage: 1 });
                                    this.drawBar(1);
                                    this.props.deletedPage({
                                        chartName: '',
                                        chartFilterTxt: '',
                                        currentPage_Report: 0,
                                        perPage_Report: this.state.perPage,
                                        stackFilter: '',
                                    });
                                }}
                            >
                                <i
                                    title='Refresh'
                                    className={
                                        this.state.displayAnimation
                                            ? 'fas fa-sync-alt refreshing'
                                            : 'fas fa-sync-alt'
                                    }
                                />
                            </span>
                        </div>
                    </div>
                </div>
                {this.state.noDataFound === false ? (
                    <Chart
                        options={this.state.baroptions}
                        series={this.state.barseries}
                        type='bar'
                        height={
                            this.state.grpahicalFieldNameFormat2Length > 10
                                ? 600
                                : 453
                        }
                    />
                ) : (
                    <div className='content-center'>No Data Found</div>
                )}
                {
                    <div className='grouping-loader'>
                        {' '}
                        <Spinner show={this.state.nextPrevLoader != 0} />{' '}
                    </div>
                }
            </div>
        );
    }
}

// const domContainer = document.querySelector('#chart');
// ReactDOM.render(React.createElement(ApexChart), domContainer);
const mapDispatchToProps = {
    deletedPage,
};
const mapStateToProps = (state) => {
    return {
        outbox: state.outbox,
        deleted_Page: state.search.deletedPage,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StackedBar));
// export default StackedBar;

// ${formatHeaderNameWithOutSpace(
//     this.props.pageTitle
// )}-${this.props.graphicalField} // krish for chart id
