import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DeleteModalProps {
    deleteModel: boolean;
    setDeleteModel: React.Dispatch<React.SetStateAction<boolean>>;
    onDelete: () => void;
    title: string;
    warningText: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    deleteModel,
    setDeleteModel,
    onDelete,
    title,
    warningText,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClose = () => {
        setDeleteModel(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await onDelete();
        } catch (error) {
            console.error('Error deleting data:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <div>
            <Modal
                className='delete-popup'
                show={deleteModel}
                onHide={handleClose}
                animation={false}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className=''>{title}</div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleClose}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-dark mb-0'>{warningText}</p>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                title='Cancel'
                                variant='secondary'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                title='Delete'
                                className='danger'
                                onClick={handleDelete}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                ) : (
                                    <span>Delete</span>
                                )}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DeleteModal;

