/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getConfigurationData,
    GetDropdownData,
    getPageTitle,
    getCount,
    getDocumentMetaWithDocId,
    lockDocument,
    GetStore,
    configurationRecordSetId,
    configurationCustomeSearchId,
} from '../api/api';
import {
    Spinner,
    SpinnerTwo,
    SpinnerBlur,
    SpinnerThree,
} from './components/Spinner';
import DocumentDetail from './components/DocumentDetail';
import DetailBigView from './components/DetailBigView';
import {
    setSelectedRow,
    getRulesJson,
    getCountData,
    showLoader,
    reRenderInbox,
    hideBigView,
    getOwners,
    setProcessId,
    setStatusId, //kalpana added
    setCallingPage,
    setDocOwner,
    setMenuName,
    setPageData,
    getListaction,
    updateList,
    updateInboxState,
    clearInboxState,
    setRowData,
    getSelectedRowDetails,
    inboxPage,
    editDetailPage,
} from '../_redux/actions/inboxActions';
import { getOwnerForAuth, logOut } from '../_redux/actions/chkauthActions';
import { getDDLlist, getConfigData } from '../_redux/actions/configActions';
import {
    getDocumentIdFromArray,
    removeCheckboxClass,
    rowHeightDataGrid,
    handleClickOutside,
    download,
    download_multiple,
    userPermission,
    stringEmptyOrUndefined,
    GetFieldData,
    RefreshCounts,
    formatHeaderNameWithOutSpace,
    getDropDownDataFromApi,
    formatNameWithSpaceToUnderscore,
    showErrorToast,
} from '../../components/commonfunction';
import DataGridComponent from '../../components/datagrid/DataGrid';
import { getProcessCount } from '../_redux/authCrud';
import DeleteModal from './components/DeleteModal';
import { InboxState, DefaultState, GridState } from '../../components/variable';
import EditWorkPage from '../EditWorkPage';
import DocumentDetailViewComponent from './components/DocumentDetailViewComponent';
import MultipleEditPage from '../MultipleEditPage';
import PdfPreview from './components/PdfPreview';
import { isMobile } from 'react-device-detect';
import SearchFilter from '../../components/searchFilter/SearchFilter';

let userdetails = localStorage.getItem('user_details');
userdetails = JSON.parse(userdetails);
let dispname = '';
let uname = [];
let username = '';
if (userdetails) {
    username = userdetails.userDisplayName;
    uname = username.split(' ');
    if (uname.length > 1) {
        dispname = `${uname[0].charAt(0).toUpperCase()}${uname[1]
            .charAt(0)
            .toUpperCase()}`;
    } else {
        dispname = `${uname[0].charAt(0).toUpperCase()}${uname[0]
            .charAt(1)
            .toUpperCase()}`;
    }
}

const statusArray = [];

export const ThemeContext = React.createContext({ name: 'Abu' });

class InboxPage extends React.Component {
    constructor(props) {
        super(props);
        this.pdfPrintRef = React.createRef();
        this.state = {
            ...InboxState,
            nextDocument: false,
            single_edit: false,
            perPage: 10,
            pdf_preview: true,
            dataGridRefersh: true,
            tagDropDownData: [],
            detailBigViewExpand: false,
            pathName: '',
        };
        this.refreshTable = this.refreshTable.bind(this);
        this.getTransitonDropDownData =
            this.getTransitonDropDownData.bind(this);
    }

    async componentDidMount() {
        //if (this._isMounted) {
        this.getTransitonDropDownData();

        const fieldDataTemp = GetFieldData({
            configData: this.props.configData,
        });
        GetStore(fieldDataTemp);

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');

        if (window.innerHeight < 825) {
            await this.setState({ perPage: 10 });
        } else {
            await this.setState({ perPage: 25 });
        }

        let userDetail = localStorage.getItem('user_details');

        if (userDetail !== null) {
            userDetail = JSON.parse(localStorage.getItem('user_details'));
            await this.props.inboxPage({ userDetail: userDetail });
        }
        let processId = '';
        let statusId = '';
        let menu_name = '';

        const fieldData = GetFieldData({
            configData: this.props.configData,
        });
        GetStore(fieldData);
        if (this.props.match.params.menuName !== undefined) {
            menu_name = this.props.match.params.menuName;
        }
        if (this.props.match.params.processId !== undefined) {
            processId = this.props.match.params.processId;
        }
        if (this.props.match.params.statusId !== undefined) {
            statusId = this.props.match.params.statusId;
        }
        if (String(processId).trim() === '' && String(statusId) === '') {
            if (this.props.processId !== undefined)
                processId = this.props.processId;
            else processId = fieldData.firstProcessId;

            if (this.props.statusId !== undefined)
                statusId = this.props.statusId;
            else statusId = fieldData.firstStatusId;

            // processId = fieldData.firstProcessId;
            // statusId = fieldData.firstStatusId;
            const curr_menu = `menu_p_${processId}_s_${statusId}`;
            const element = document.getElementById(curr_menu);
            if (element !== null) {
                element.classList.add('root-active');
            }
        }

        //await this.neededConfiguration(statusId);
        // setTimeout(() => {
        await this.setState({
            processId: processId,
            statusId: statusId,
            menu_name: menu_name,
        });

        // Set Row data in redux
        await this.props.setMenuName(menu_name);
        await this.props.clearInboxState(DefaultState);
        if (String(processId).includes('=') !== true) {
            // THIS CONDITION ADD FOR RD DOCUMENTS
            await this.props.getOwners(processId, statusId);
        }

        let calingFrom = 'inbox-outbox';
        if (
            this.props.calingFrom !== undefined &&
            this.props.calingFrom === 'search'
        ) {
            calingFrom = 'search';
        }
        this.setState({
            calingFrom: calingFrom,
        });
        let queryData = '';
        if (
            this.props.query !== undefined &&
            this.props.query !== '' &&
            this.props.query !== null
        ) {
            queryData = this.props.query;
        }

        await this.setState({
            queryData: queryData,
        });

        await this.props.getListaction(
            processId,
            statusId,
            calingFrom,
            queryData
        );
        if (processId !== undefined && statusId !== undefined) {
            //await this.props.getOwnerForAuth(processId, statusId);
        }
        await this.props.setProcessId(processId);
        await this.props.setStatusId(statusId);
        await this.props.setCallingPage(calingFrom); //kalpana added

        // End
        let permissionAllactions = await userPermission({
            processId: processId,
            statusId: statusId,
            menu_name: menu_name,
            configData: this.props.configData,
        });
        await this.setState({
            userPermission: permissionAllactions,
        });
        if (String(processId).trim() === '' && String(statusId) === '') {
            let permission = userPermission({
                processId: processId,
                statusId: statusId,
                menu_name: menu_name,
                configData: this.props.configData,
            });
            permission = {
                ...permission,
                show_pdf: true,
                show_notes: true,
            }; // default true
            this.props.inboxPage({
                permission: permission,
            });

            /*
            const config = this.props.configData;
            let result = [];
            if (stringEmptyOrUndefined(config)) {
                result = config.tags !== undefined ? config.tags : [];
            }
            const newTagArray = [];
            result.forEach((tagdata, tagindex) => {
                newTagArray[tagdata.name.toLowerCase()] = tagdata.color;
            }); */
            this.setState({
                //   ...this.state,
                ...permission,
            });
        }

        const titlePage = getPageTitle({ processId, statusId });

        this.setState({ pageTitle: titlePage });

        // if (
        //     stringEmptyOrUndefined(fieldData) &&
        //     stringEmptyOrUndefined(fieldData.attachmentTypeId)
        // ) {
        //     getDropdownData(fieldData.attachmentTypeId)
        //         .then((response) => {
        //             return response.json();
        //         })
        //         .then((data) => {})
        //         .catch((error) => {});
        // }
        document.addEventListener('mousedown', handleClickOutside);
        // Fixes for Mobile Responsive issue
        const asideOn = document.getElementById('kt_aside_mobile_toggle');
        if (asideOn !== null) {
            asideOn.addEventListener('click', () => {
                const ktAside = document.getElementById('kt_aside');
                if (ktAside !== null) {
                    ktAside.classList.add('aside-on');
                }
            });
        }
        // below line added for merg split unproper close
        this.props.inboxPage({
            related_doc_detail: false,
            multiple_edit_page: false,
            single_edit: false,
        });
        // End
        var currentPathName = window.location.pathname.split('/');

        if (currentPathName.length > 2) {
            if (currentPathName[2] === 'edit') {
                this.setState({ single_edit: true });
            }
            if (currentPathName[2] === 'detail') {
                this.props.inboxPage({ related_doc_detail: true });
            }
            if (currentPathName[2] === 'editmultiple') {
                this.props.inboxPage({ multiple_edit_page: true });
            }
            if (currentPathName[2] === 'undefined') {
                this.props.logOut();
            }
            // setTimeout(() => {
            //     this.props.logOut();
            // }, 5000)
        }
    }
    configurationRecordSet(id) {
        configurationRecordSetId(id)
            .then(async (data) => {
                if (this.props.getConfigData !== undefined) {
                    await this.props.getConfigData(data);
                    // this.props.getConfigData()
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    }

    configurationCustomeSearchId = (id) => {
        configurationCustomeSearchId(id)
            .then(async (data) => {
                if (this.props.getConfigData !== undefined) {
                    await this.props.getConfigData(data);
                    // this.props.getConfigData()
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    };

    async componentDidUpdate(prevProps, prevState) {
        if (
            this.props.location.pathname === '/inbox' &&
            this.props.location.pathname !== this.state.pathName
        ) {
            this.setState({
                single_edit: false,
                pathName: this.props.location.pathname,
                row_transition: false,
                detailBigView: false,
            });
            this.props.inboxPage({
                related_doc_detail: false,
                multiple_edit_page: false,
                single_edit: false,
            });
            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
            //this.componentDidMount()
            //this.refreshTable();
        }
        if (this.props.location.pathname !== this.state.pathName) {
            this.setState({
                pathName: this.props.location.pathname,
            });
        }
        document.addEventListener('mousedown', handleClickOutside);
        let processId = '';
        let statusId = '';
        let menu_name = '';
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (this.props.match.params.menuName !== undefined) {
            menu_name = this.props.match.params.menuName;
        }
        if (this.props.match.params.processId !== undefined) {
            processId = this.props.match.params.processId;
        }
        if (this.props.match.params.statusId !== undefined) {
            statusId = this.props.match.params.statusId;
        }

        if (String(processId).trim() === '' && String(statusId).trim() === '') {
            //processId = fieldData.firstProcessId;
            //statusId = fieldData.firstStatusId;
            if (this.props.processId !== undefined)
                processId = this.props.processId;
            else processId = fieldData.firstProcessId;

            if (this.props.statusId !== undefined)
                statusId = this.props.statusId;
            else statusId = fieldData.firstStatusId;
            const curr_menu = `menu_p_${processId}_s_${statusId}`;

            if (window.location.pathname === '/inbox') {
                await this.props.history.push({
                    pathname: `/inbox/${fieldData.firstStatusName}/${processId}/${statusId}`,
                    // pathname: `/inbox/intake/1/7`
                });
            }

            const element = document.getElementById(curr_menu);
            if (element !== null) {
                element.classList.add('root-active');
            }
        }

        const filterKey = `searchFilter_${processId}_${statusId}`;
        const savedFilter = localStorage.getItem(filterKey) ?? '';

        if (savedFilter !== this.state.filterTxt) {
            this.setState({ filterTxt: savedFilter });
        }

        if (String(prevProps.stateToken) !== String(this.props.stateToken)) {
            const requiredProperty = {};
            for (const property in GridState) {
                if (this.props.stateData[property] !== undefined) {
                    requiredProperty[property] = this.props.stateData[property];
                }
            }

            await this.setState({
                //...this.state,
                ...requiredProperty,
            });
        }

        let permission = userPermission({
            processId: processId,
            statusId: statusId,
            menu_name: menu_name,
            configData: this.props.configData,
        });

        permission = {
            show_tag: permission.show_tag,
            show_owner: permission.show_owner,
            show_pdf: true,
            show_notes: true,
        };

        if (permission.show_tag !== this.state.show_tag) {
            await this.setState(
                {
                    ...permission,
                },
                () => {}
            );
        }
        if (permission.show_owner !== this.state.show_owner) {
            await this.setState(
                {
                    ...permission,
                },
                () => {}
            );
        }

        if (
            this.props.loading === false &&
            prevState.displayAnimation === true
        ) {
            await this.setState({ displayAnimation: false });
        }

        if (
            this.props.match.params.statusId !== undefined &&
            this.state.statusId !== undefined &&
            String(this.props.match.params.statusId) !==
                String(this.state.statusId) &&
            this.props.related_doc_detail !== true &&
            this.props.multiple_edit_page !== true &&
            String(this.props.location.pathname).includes('editmultiple') !==
                true
        ) {
            // await this.neededConfiguration(statusId);

            let permission = userPermission({
                processId: processId,
                statusId: statusId,
                menu_name: menu_name,
                configData: this.props.configData,
            });
            await this.setState({
                processId: processId,
                statusId: statusId,
                show_owner: false,
                show_tag: false,
                show_branch: false,
                show_group: false,
                show_inbox: false,
                show_notes: true,
                show_pdf: true,
                show_delete: false,
                show_print: true,
                show_download: true,
                NewPermission: permission,
            });

            if (
                String(processId).trim() !== '' &&
                String(statusId).trim() !== ''
            ) {
                permission = {
                    ...permission,
                    show_pdf: true,
                    show_notes: true,
                };
                await this.setState(
                    {
                        ...InboxState,
                        ...permission,
                        row_transition: false,
                        selectedRows: new Set(),
                        filterTxt: localStorage.getItem(filterKey) ?? '',
                    },
                    () => {
                        this.props.updateList('');
                        this.props.setMenuName(menu_name);
                        this.props.clearInboxState({
                            groupBy: [],
                            groupByData: {},
                            filterTxt: localStorage.getItem(filterKey) ?? '',
                            perPage: 10,
                            currentPage: 0,
                            totalCount: 0,
                            sortDirection: 'NONE',
                            sortColumn: '',
                            selectedRows: new Set(),
                            rowData: [],
                            filteredRowData: [],
                            header: [],
                            expandedRow: new Set(),
                            expandedGroupIds: new Set(),
                            tempExpandedGroupIds: new Set(),
                            uniqueData: {},
                            row_transition: false,
                            rowClickClass: [],
                        });
                        if (String(processId).includes('=') !== true) {
                            this.props.getOwners(processId, statusId);
                            this.props.getListaction(
                                processId,
                                statusId,
                                this.state.calingFrom,
                                this.state.queryData
                            );
                            RefreshCounts(processId, statusId, this.props);
                        }

                        this.props.setProcessId(processId);
                        this.props.setStatusId(statusId);
                        this.props.setCallingPage(this.state.calingFrom); //kalpana added
                        //this.getTransitonDropDownData();

                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                        // End
                    }
                );
            } else {
                await this.setState({
                    show_owner: true,
                    show_tag: true,
                    show_branch: true,
                    show_group: true,
                    show_inbox: true,
                    show_notes: true,
                    show_pdf: true,
                    show_delete: true,
                    show_print: true,
                    show_download: true,
                    processId: processId,
                    statusId: statusId,
                });
            }

            const titlePage = getPageTitle({ processId, statusId });
            await this.setState({ pageTitle: titlePage, menu_name: menu_name });
        }
    }

    async getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });

        GetStore(fieldData);

        if (stringEmptyOrUndefined(fieldData.tags)) {
            await getDropDownDataFromApi(fieldData.tags).then(async (data) => {
                if (data.values) {
                    await this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }

        if (
            stringEmptyOrUndefined(fieldData) &&
            stringEmptyOrUndefined(fieldData.branchFieldId) &&
            !this.props.ddl.hasOwnProperty('branch')
        ) {
            await GetDropdownData(fieldData.branchFieldId)
                .then((response) => {
                    //  if (this._isMounted) {
                    return response.json();
                    //}
                })
                .then((data) => {
                    if (data.values && data.values.length > 0) {
                        let newArray = [];
                        data.values.map((item) => {
                            if (
                                newArray.includes(item) !== true &&
                                item !== '' &&
                                item !== null &&
                                item !== undefined
                            ) {
                                newArray.push(item);
                            }
                        });
                        // if (this._isMounted) {
                        this.setState({ branches: newArray });

                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]: newArray,
                        });
                        //  }
                    }
                })
                .catch((error) => {});
        }
        if (
            stringEmptyOrUndefined(fieldData) &&
            stringEmptyOrUndefined(fieldData.groupFieldId) &&
            !this.props.ddl.hasOwnProperty('group')
        ) {
            await GetDropdownData(fieldData.groupFieldId)
                .then((response) => {
                    //if (this._isMounted) {
                    return response.json();
                    //}
                })
                .then((data) => {
                    if (data.values && data.values.length > 0) {
                        let newArray = [];
                        data.values.map((item) => {
                            if (
                                newArray.includes(item) !== true &&
                                item !== '' &&
                                item !== null &&
                                item !== undefined
                            ) {
                                newArray.push(item);
                            }
                        });
                        this.setState({ groups: newArray });
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]: newArray,
                        });
                    }
                })
                .catch((error) => {});
        }

        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('attachmenttype')) {
                await getDropDownDataFromApi(fieldData.attachmentTypeId).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('attachmentlocation')) {
                await getDropDownDataFromApi(
                    fieldData.attachmentLocationId
                ).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            }

            if (!this.props.ddl.hasOwnProperty('newreferral')) {
                await getDropDownDataFromApi(fieldData.newReferralId).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('serviceline')) {
                await getDropDownDataFromApi(fieldData.serviceLineId).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            /* if (!this.props.ddl.hasOwnProperty('team')) {
                await getDropDownDataFromApi(fieldData.teamId).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            } */

            if (!this.props.ddl.hasOwnProperty('payorsource')) {
                await getDropDownDataFromApi(fieldData.payor_source).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('caretype')) {
                await getDropDownDataFromApi(fieldData.care_type).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('episodestatus')) {
                await getDropDownDataFromApi(fieldData.episode_status).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
            if (!this.props.ddl.hasOwnProperty('tags')) {
                await getDropDownDataFromApi(fieldData.tags).then((data) => {
                    if (data.values) {
                        // data.values.push('developer');
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                        this.setState({
                            tagDropDownData: data.values,
                        });
                    }
                });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', handleClickOutside);

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        this.props.inboxPage({
            related_doc_detail: false,
            multiple_edit_page: false,
            single_edit: false,
        });
    }
    async configurationApiCall() {
        var customSearchQueries = [];
        customSearchQueries = this.props.customSearchQueries;

        await customSearchQueries.map(async (data) => {
            if (data.name === 'By Batch' || data.name === 'By Order') {
                await this.configurationCustomeSearchId(data.id);
                this.setState({ byBatchSearchId: data.id });
            }
        });
        if (
            this.props.configData.recordsetNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (
                        data.name === 'Payor Sources' ||
                        data.name === 'Order Data' ||
                        data.name === 'Patient Data Modify' ||
                        data.name === 'Order Data Modify' ||
                        data.name === 'Episode Data' ||
                        data.name === 'Branch Data' ||
                        data.name === 'Episode Visit Data' ||
                        data.name === 'Physicians By Location' ||
                        data.name === 'Locations By Physician Standard' ||
                        data.name === 'Attachment Data'
                    ) {
                        await this.configurationRecordSet(data.id);
                    }
                }
            );
        }
    }

    onRowClick = async (rowDetails, rowHeader, stateData) => {
        // Remove previous edit type from redux
        this.props.inboxPage({
            editDetailsSwitch: {
                docId: '',
                editType: '',
            },
        });
        const docid = rowDetails.doc_id;
        document.body.classList.add('doc-small-view');
        if (String(rowHeader.key) === 'tags') {
            let tagIndex;
            await stateData.filteredRowData.map((rows, index) => {
                if (String(rows.doc_id) === String(docid)) {
                    tagIndex = index;
                }
                return rows;
            });
            this.setState(
                {
                    rowData: stateData.rowData,
                    selectedTagDocId: docid,
                    selected_tag: rowDetails.tags_data,
                    tagIndex: tagIndex,
                    selectedColumn: rowHeader.key,
                    showData: true,
                    reInitiateGrid: false,
                    edit_details: false,
                },
                () => {
                    this.props.updateInboxState({
                        tempExpandedGroupIds: stateData.tempExpandedGroupIds,
                        customGroupedData: stateData.customGroupedData,
                        expandedGroupIds: stateData.expandedGroupIds,
                        groupByData: stateData.groupByData,
                        groupByIndex: stateData.groupByIndex,
                        groupBy: stateData.groupBy,
                        sortDirection: stateData.sortDirection,
                        sortColumn: stateData.sortColumn,
                        statictics: stateData.statictics,
                        row_transition: stateData.row_transition,
                        //    refreshDataGrid: stateData.refreshDataGrid,
                        modifiedColumnsNames: stateData.modifiedColumnsNames,
                        header: stateData.header,
                        currentStateMenuName: stateData.currentStateMenuName,
                        dateFormatFields: stateData.dateFormatFields,
                        totalColumn: stateData.totalColumn,
                        totalCount: stateData.totalCount,
                        tableDesign: stateData.tableDesign,
                        uniqueData: stateData.uniqueData,
                        filteredRowData: stateData.filteredRowData,
                        rowData: stateData.rowData,
                        perPage: stateData.perPage,
                        currentPage: stateData.currentPage,
                        allActions: stateData.allActions,
                        filterTxt: stateData.filterTxt,
                        selectedColumn: rowHeader.key,
                        selectedTagDocId: docid,
                        // selectedRowData: rowDetails,
                        selected_tag: rowDetails.tags_data,
                        tagIndex: tagIndex,
                        showData: true,
                        reInitiateGrid: false,
                    });
                }
            );
            this.setState({
                tagRefresh: true,
            });
            return true;
        }

        if (String(rowHeader.key) === 'owner') {
            const docid = rowDetails.doc_id;
            await this.setState(
                {
                    //onRowClickId: rowDetails.doc_id,
                    //rowClickClass: [rowDetails.id],
                    menu_branch: '',
                    menu_group: '',
                    menu_inbox: '',
                    //row_transition: '',
                    // blur_spinner:true,
                    // rowDetails: rowDetails,
                    reInitiateGrid: false,
                    showData: true,
                    selectedColumn: rowHeader.key,
                    edit_details: false,
                },
                async () => {
                    await this.handleMultiSelectClose();

                    this.setState(
                        {
                            //  selectedDocId: docid,
                            selectedRows: new Set(),
                        },
                        () => {
                            //this.setState({ row_transition: true });
                            this.props.updateInboxState({
                                ...stateData,
                                //row_transition: true,

                                tempExpandedGroupIds:
                                    stateData.tempExpandedGroupIds,
                                customGroupedData: stateData.customGroupedData,
                                expandedGroupIds: stateData.expandedGroupIds,
                                groupByData: stateData.groupByData,
                                groupByIndex: stateData.groupByIndex,
                                groupBy: stateData.groupBy,
                                sortDirection: stateData.sortDirection,
                                sortColumn: stateData.sortColumn,
                                //  statictics: stateData.statictics,

                                row_transition: stateData.row_transition,
                                //  refreshDataGrid: stateData.refreshDataGrid,
                                //   reInitiateGrid: stateData.reInitiateGrid,
                                modifiedColumnsNames:
                                    stateData.modifiedColumnsNames,
                                header: stateData.header,
                                currentStateMenuName:
                                    stateData.currentStateMenuName,
                                dateFormatFields: stateData.dateFormatFields,
                                totalColumn: stateData.totalColumn,
                                totalCount: stateData.totalCount,
                                tableDesign: stateData.tableDesign,
                                uniqueData: stateData.uniqueData,
                                filteredRowData: stateData.filteredRowData,
                                rowData: stateData.rowData,
                                perPage: stateData.perPage,
                                currentPage: stateData.currentPage,
                                allActions: stateData.allActions,
                                filterTxt: stateData.filterTxt,

                                menu_branch: this.state.menu_branch,
                                menu_group: this.state.menu_group,
                                is_locked_by: this.state.is_locked_by,
                                noteCount: this.state.noteCount,
                                pageCount: this.state.pageCount,
                                //   rowDetails: this.state.rowDetails,
                                showData: this.state.showData,
                                selectedColumn: this.state.selectedColumn,
                                selectedRows: new Set(),
                            });
                        }
                    );
                }
            );

            if (
                this.props.inbox.selectedRow === undefined ||
                String(this.props.inbox.selectedRow) !== String(docid)
            ) {
                await this.props.setSelectedRow(docid);
            }
            return true;
        }

        if (String(rowHeader.key) === 'select-row') {
            await this.configurationApiCall();
            await this.setState({
                showData: false,
                selectedColumn: rowHeader.key,
                reInitiateGrid: false,
                edit_details: false,
            });
            return true;
        } else {
            // this.props.setSelectedRow(1);
            await this.handleMultiSelectClose();
            await this.configurationApiCall();
            const docid = rowDetails.doc_id;

            await this.setState(
                {
                    onRowClickId: rowDetails.doc_id,
                    rowClickClass: [rowDetails.id],
                    menu_branch: '',
                    menu_group: '',
                    menu_inbox: '',
                    //  row_transition: '',
                    // blur_spinner:true,
                    rowDetails: rowDetails,
                    reInitiateGrid: false,
                    showData: true,
                    selectedColumn: rowHeader.key,
                    edit_details: false,
                },
                async () => {
                    let ValidationRules;
                    let key;
                    const fieldData = GetFieldData({
                        configData: this.props.configData,
                    });
                    //   await this.handleMultiSelectClose();

                    document.body.classList.add('aside-minimize');
                    const response = getDocumentMetaWithDocId({ docid });

                    const configData = getConfigurationData();
                    let singleStatus = true;
                    await response.then(async (data) => {
                        data.miscPproperties.rulesJson = JSON.parse(
                            data.miscPproperties.rulesJson
                        );
                        /* console.info(
                            'Validation rule',
                            JSON.stringify(data.miscPproperties.rulesJson)
                        ); */
                        // Wrong Conversion
                        // ValidationRules = {};
                        // for (const property in data.miscPproperties.rulesJson
                        //     .ValidationRules) {
                        //     if (data.miscPproperties.rulesJson.ValidationRules) {
                        //         key = property
                        //             .replace('(', '')
                        //             .replace(')', '')
                        //             .replace(',', '');
                        //         ValidationRules[formatNameWithSpaceToUnderscore(key)] = data.miscPproperties.rulesJson.ValidationRules[property];
                        //     }
                        // }
                        // data.miscPproperties.rulesJson.ValidationRules =
                        //     ValidationRules;

                        if (data.statuses.length > 1) {
                            showErrorToast(
                                `Error in processing Document ${data.miscPproperties.docId} . Please contact WorldView Customer Support for further assistance.`
                            );
                            this.setState({
                                row_transition: false,
                            });
                            document.body.classList.remove('aside-minimize');
                            singleStatus = false;
                            return false;
                        } else {
                            await this.props.getSelectedRowDetails(data);
                            this.setState({
                                selectedDetailsOfRow: data,
                            });
                            //
                            let formatted_rowDetail = '';
                            if (data.hasOwnProperty('fields')) {
                                formatted_rowDetail = data.fields;
                            }

                            const allFields = this.props.configData.fields;
                            const finalRowDetail = {};
                            if (formatted_rowDetail.length > 0) {
                                formatted_rowDetail.map((rowdetail) => {
                                    allFields.map((allfields) => {
                                        const temp = { ...rowdetail };
                                        if (
                                            rowdetail.fieldId ===
                                            allfields.fieldId
                                        ) {
                                            temp.fieldName = allfields.name;
                                            const removeSpace =
                                                formatNameWithSpaceToUnderscore(
                                                    allfields.name
                                                );
                                            finalRowDetail[removeSpace] = temp;
                                        }
                                    });
                                });
                            }

                            this.props.editDetailPage({
                                selectedRowDetail: finalRowDetail,
                            });
                            //
                            if (data.miscPproperties.isLocked !== undefined) {
                                this.setState({
                                    is_locked: data.miscPproperties.isLocked,
                                });
                                setTimeout(() => {
                                    if (
                                        this.state.is_locked === true &&
                                        document.querySelector(
                                            '.rdg-row.row-selected-once'
                                        ) !== null
                                    ) {
                                        document
                                            .querySelector(
                                                '.rdg-row.row-selected-once'
                                            )
                                            .classList.add('locked');
                                        if (
                                            document.getElementById(
                                                'left-aside'
                                            ) !== null
                                        ) {
                                            document
                                                .getElementById('left-aside')
                                                .classList.remove(
                                                    'no-lock-section'
                                                );
                                        }
                                    } else if (
                                        document.getElementById(
                                            'left-aside'
                                        ) !== null
                                    ) {
                                        document
                                            .getElementById('left-aside')
                                            .classList.add('no-lock-section');
                                    }
                                }, 10);
                            }
                            if (data.miscPproperties.lockedBy !== undefined) {
                                this.setState({
                                    is_locked_by: data.miscPproperties.lockedBy,
                                });
                            }
                            if (data.miscPproperties.noteCount !== undefined) {
                                this.setState({
                                    noteCount: data.miscPproperties.noteCount,
                                });
                            }
                            if (data.miscPproperties.pageCount !== undefined) {
                                this.setState({
                                    pageCount: data.miscPproperties.pageCount,
                                });
                            }
                            const foundFields = [];

                            if (data.fields !== undefined) {
                                data.fields.forEach((dataValue) => {
                                    const fields = dataValue.fieldId;
                                    if (
                                        String(fields) ===
                                        String(fieldData.branchFieldId)
                                    ) {
                                        this.setState({
                                            menu_branch: dataValue.value,
                                        });
                                    }
                                    if (
                                        String(fields) ===
                                        String(fieldData.groupFieldId)
                                    ) {
                                        this.setState({
                                            menu_group: dataValue.value,
                                        });
                                    }

                                    if (
                                        String(fields) ===
                                        String(fieldData.workflow_status)
                                    ) {
                                        this.setState({
                                            menu_inbox: dataValue.value,
                                        });
                                    }
                                    for (const property in configData) {
                                        if (
                                            String(
                                                configData[property].fieldId
                                            ) === String(fields)
                                        ) {
                                            foundFields.push(
                                                configData[property]
                                            );
                                        }
                                    }
                                });
                            }
                        }
                    });
                    if (singleStatus) {
                        this.setState(
                            {
                                row_transition: true,
                                selectedDocId: docid,
                                selectedRows: new Set(),
                            },
                            () => {
                                this.props.updateInboxState({
                                    rowClickClass: this.state.rowClickClass,
                                    tempExpandedGroupIds:
                                        stateData.tempExpandedGroupIds,
                                    customGroupedData:
                                        stateData.customGroupedData,
                                    expandedGroupIds:
                                        stateData.expandedGroupIds,
                                    groupByData: stateData.groupByData,
                                    groupByIndex: stateData.groupByIndex,
                                    groupBy: stateData.groupBy,
                                    sortDirection: stateData.sortDirection,
                                    sortColumn: stateData.sortColumn,
                                    statictics: stateData.statictics,
                                    refreshDataGrid: stateData.refreshDataGrid,
                                    // reInitiateGrid: stateData.reInitiateGrid,
                                    modifiedColumnsNames:
                                        stateData.modifiedColumnsNames,
                                    header: stateData.header,
                                    currentStateMenuName:
                                        stateData.currentStateMenuName,
                                    dateFormatFields:
                                        stateData.dateFormatFields,
                                    totalColumn: stateData.totalColumn,
                                    totalCount: stateData.totalCount,
                                    tableDesign: stateData.tableDesign,
                                    uniqueData: stateData.uniqueData,
                                    filteredRowData: stateData.filteredRowData,
                                    rowData: stateData.rowData,
                                    perPage: stateData.perPage,
                                    currentPage: stateData.currentPage,
                                    allActions: stateData.allActions,
                                    filterTxt: stateData.filterTxt,
                                    selectedDocId: docid,
                                    row_transition: true,
                                    //menu_branch: this.state.menu_branch,
                                    menu_group: this.state.menu_group,
                                    is_locked_by: this.state.is_locked_by,
                                    noteCount: this.state.noteCount,
                                    pageCount: this.state.pageCount,
                                    onRowClickId: this.state.onRowClickId,
                                    rowDetails: rowDetails,
                                    reInitiateGrid: this.state.reInitiateGrid,
                                    showData: this.state.showData,
                                    selectedColumn: this.state.selectedColumn,
                                    selectedRows: new Set(),
                                });
                            }
                        );

                        if (this.state.is_locked !== true) {
                            lockDocument(docid)
                                .then((data) => {})
                                .catch((err) => {});
                        }
                    }
                }
            );
        }

        const elem = document.getElementById('last-pos');
        if (elem !== null) {
            elem.parentElement.removeChild(elem);
        }
    };

    async refreshTable() {
        const processId = this.state.processId;
        const statusId = this.state.statusId;
        await this.props.updateList('');
        const filterKey = `searchFilter_${processId}_${statusId}`;
        const savedFilter = localStorage.getItem(filterKey) ?? '';

        this.setState(
            {
                rowData: [],
                filteredRowData: [],
                currentPage: 0,
                perPage: 10,
                sortDirection: 'NONE',
                sortColumn: '',
                filterTxt: savedFilter,
                groupBy: [],
                rowClickClass: [],
                groupByData: {},
                tempExpandedGroupIds: new Set(),
                expandedGroupIds: new Set(),
                selectedRows: new Set(),
                displayAnimation: true,
                showLoader: true,
                row_transition: false,
                openedRowId: {},
            },
            () => {
                document.body.classList.remove('aside-minimize');
                this.props.clearInboxState({
                    rowData: [],
                    filteredRowData: [],
                    currentPage: 0,
                    perPage: 10,
                    sortDirection: 'NONE',
                    sortColumn: '',
                    filterTxt: savedFilter,
                    groupBy: [],
                    rowClickClass: [],
                    groupByData: {},
                    tempExpandedGroupIds: new Set(),
                    expandedGroupIds: new Set(),
                    selectedRows: new Set(),
                    displayAnimation: true,
                    showLoader: true,
                    row_transition: false,
                    openedRowId: {},
                });
                this.props.getListaction(
                    processId,
                    statusId,
                    this.state.calingFrom,
                    this.state.queryData
                );
            }
        );
        const count = [];
        const oldCount = this.props.count;
        getProcessCount(processId)
            .then((response) => {
                // status = response.status;
                return response.json();
            })
            .then(async (data) => {
                count.push(data);
                await oldCount.map((oldCountMap) => {
                    if (
                        oldCountMap.process !== undefined &&
                        oldCountMap.process.processId !== undefined &&
                        data.process !== undefined &&
                        data.process.processId !== undefined &&
                        oldCountMap.process.processId !== data.process.processId
                    ) {
                        count.push(oldCountMap);
                    }
                    return oldCountMap;
                });
                this.props.getCountData(count);
            });
        await getCount(processId, statusId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // let countArray=[]
                // if(this.props.count!==""){
                //   countArray=this.props.count
                // }
                // countArray.push(data)
                // this.props.getCountData(countArray);
                localStorage.setItem('refresh_count', data.count);
                let countData = localStorage.getItem(
                    `CountData${data.processId}`
                );
                if (countData !== null) {
                    let totalCount = 0;
                    countData = JSON.parse(countData);
                    if (countData.statuses !== undefined) {
                        countData.statuses.map((arrayval) => {
                            if (
                                arrayval.processId === data.processId &&
                                arrayval.statusId === data.statusId
                            ) {
                                arrayval.count = data.count;
                            }
                            totalCount += arrayval.count;
                            return arrayval;
                        });
                    }
                    if (countData.process !== undefined) {
                        countData.process.count = totalCount;
                    }
                    localStorage.setItem(
                        `CountData${data.processId}`,
                        JSON.stringify(countData)
                    );
                }
            });
        await this.props.history.push(window.location.pathname);
    }

    openDeletePopUp() {
        const selectedRows = Array.from(this.state.selectedRows);
        let rowsToDelete = this.state.rowData.filter((data) => {
            return selectedRows.indexOf(data.id) !== -1;
        });

        if (rowsToDelete.length === 0 && this.state.rowClickClass.length > 0) {
            rowsToDelete = this.state.rowData.filter((data) => {
                return this.state.onRowClickId === data.doc_id;
            });
        }
        if (rowsToDelete.length === 0) {
            rowsToDelete = this.state.rowsToDelete;
        }
        this.setState({
            openDelete: true,
            rowsToDelete: rowsToDelete,
            deleteComments: [],
        });
    }

    closeDeletePopUp(param) {
        const { removeRow, rowsToDelete } = param;
        if (removeRow !== undefined && removeRow === true) {
            if (removeRow && rowsToDelete.length > 0) {
                const docIds = [];
                rowsToDelete.forEach((data) => {
                    docIds.push(data.doc_id);
                });
                const rowData = this.state.rowData.filter((data) => {
                    return !(docIds.indexOf(data.doc_id) !== -1);
                });
                const filteredRowData = this.state.filteredRowData.filter(
                    (data) => {
                        return !(docIds.indexOf(data.doc_id) !== -1);
                    }
                );
                this.setState(
                    {
                        openDelete: false,
                        selectedRows: new Set(),
                        selectedIndexes: [],
                        rowsToDelete: [],
                        rowData: rowData,
                        filteredRowData: filteredRowData,
                        row_transition: false,
                    },
                    () => {
                        this.props.updateInboxState(this.state);
                        this.props.getListaction(
                            this.state.processId,
                            this.state.statusId,
                            this.state.calingFrom,
                            this.state.queryData
                        );

                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                    }
                );
            }
            return;
        }
        this.setState({
            openDelete: false,
        });
    }

    setBranch = (event) => {
        this.setState({ menu_branch: event.target.value });
    };

    set_group = (event) => {
        this.setState({ menu_group: event.target.value });
    };

    set_inbox = (event) => {
        this.setState({ menu_inbox: event.target.value });
    };

    entering = (e) => {
        e.children[0].style.borderTopColor = 'rgb(0, 35, 58)';
        e.children[1].style.backgroundColor = 'rgb(0, 35, 58)';
    };

    download_multiple = async () => {
        this.setState({ blur_spinner: true }, () => {
            download_multiple({
                selectedRows: this.state.selectedRows,
                rowData: this.state.rowData,
            });
            this.setState({ blur_spinner: false });
        });
    };

    enableLoader(value) {
        this.setState({ blur_spinner: value });
    }

    async restrictFilterDataGrid(searchText = '') {
        const isValid = (str) => {
            // eslint-disable-next-line no-useless-escape
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText.trim()) === false) {
            return [];
        } else {
            const { processId, statusId } = this.state;
            const filterKey = `searchFilter_${processId}_${statusId}`;

            localStorage.setItem(filterKey, searchText);
            this.setState({ filterTxt: searchText });
        }
    }

    rowTransition = async () => {
        this.setState(
            {
                row_transition: false,
                detailBigView: false,
            },
            () => {
                //if we update it takes old row state variable.
                this.props.updateInboxState({
                    // ...this.state,
                    row_transition: false,
                    detailBigView: false,
                });
            }
        );
    };

    handleMultiSelectClose = () => {
        removeCheckboxClass();
        this.setState({
            selectedRows: new Set(),
        });
        this.setState({
            close_tagmenu: false,
            open_addtag: false,
            open_replacetag: false,
            open_deletetag: false,
            menu_selected_tagonly: [],
            menu_selected_tag: [],
        });
    };

    openBigView = () => {
        this.setState({
            detailBigView: true,
        });
    };

    onclose_tag = (event) => {
        if (event === false && this.state.tag_action === true) {
            this.setState({ selectedRows: new Set() });
        }
    };

    closeBigView = () => {
        this.setState({ detailBigView: false }, () => {
            // this.props.updateInboxState({
            //     ...this.state,
            //     detailBigView: false,
            // });
        });
    };

    editDetails = () => {
        this.setState({ edit_details: true });
    };

    refreshTag = async (smallView) => {
        this.setState({ tagLoader: true });
        // await this.getTableData(
        //     this.state.processId,
        //     this.state.statusId,
        //     true
        // );
        this.setState({ refresh_table: false });
        this.setState({ refresh_table: true });
    };

    onSelectedRowsChange(rows, gridStateData) {
        const selectedRowData = getDocumentIdFromArray(
            gridStateData.filteredRowData,
            Array.from(rows)
        );
        const pushEditId = [];
        selectedRowData.forEach((v, i) => {
            pushEditId.push(v.doc_id);
        });
        const selectedRows = rows;
        this.setState(
            {
                selectedRows: selectedRows,
                // selectedRows: [],
                printSelectedDocId: [],
                close_tagmenu: false,
                open_addtag: false,
                open_replacetag: false,
                open_deletetag: false,
                menu_selected_tagonly: [],
                menu_selected_tag: [],
                detailBigView: false,
                editId: pushEditId,
            },

            () => {
                this.props.updateInboxState({
                    tempExpandedGroupIds: gridStateData.tempExpandedGroupIds,
                    customGroupedData: gridStateData.customGroupedData,
                    expandedGroupIds: gridStateData.expandedGroupIds,
                    groupByData: gridStateData.groupByData,
                    groupByIndex: gridStateData.groupByIndex,
                    groupBy: gridStateData.groupBy,
                    sortDirection: gridStateData.sortDirection,
                    sortColumn: gridStateData.sortColumn,
                    statictics: gridStateData.statictics,

                    refreshDataGrid: gridStateData.refreshDataGrid,
                    reInitiateGrid: gridStateData.reInitiateGrid,
                    modifiedColumnsNames: gridStateData.modifiedColumnsNames,
                    header: gridStateData.header,
                    currentStateMenuName: gridStateData.currentStateMenuName,
                    dateFormatFields: gridStateData.dateFormatFields,
                    totalColumn: gridStateData.totalColumn,
                    totalCount: gridStateData.totalCount,
                    tableDesign: gridStateData.tableDesign,
                    uniqueData: gridStateData.uniqueData,
                    filteredRowData: gridStateData.filteredRowData,
                    perPage: gridStateData.perPage,
                    currentPage: gridStateData.currentPage,
                    filterTxt: gridStateData.filterTxt,
                    // row_transition: gridStateData.row_transition,

                    filteredData: gridStateData.filteredRowData,
                    rowData: gridStateData.rowData,

                    allActions: gridStateData.allActions,
                    selectedRows: selectedRows,
                    detailBigView: false,
                    editId: pushEditId,
                });
            }
        );
    }

    resetBigView = () => {
        this.rowTransition();

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        this.refreshTable();
    };

    refreshForNextDocument = (docid) => {
        this.setState({ row_transition: false, detailBigView: false }, () => {
            this.setState({
                nextDocument: true,
                selectedDocId: docid,
                row_transition: true,
                detailBigView: true,
                onRowClickId: docid, // workitem=9201
            });
        });
    };

    render() {
        return (
            <>
                {/* {this.state.single_edit === false? */}
                <ThemeContext.Provider value={{ name: 'inbox' }}>
                    <div
                        className={
                            this.state.detailBigView &&
                            this.state.is_locked === false
                                ? ''
                                : this.state.is_locked &&
                                  this.state.detailBigView
                                ? ''
                                : this.state.is_locked
                                ? 'scrol-outer'
                                : 'scrol-outer bigViewClosed'
                        }
                        style={
                            this.state.single_edit === true ||
                            this.props.related_doc_detail === true ||
                            this.props.multiple_edit_page === true
                                ? { display: 'none' }
                                : {}
                        }
                    >
                        <div
                            id='inbox3-overall'
                            className={`toast-container ${this.state.toast_type}`}
                            //need to check Aiswarya
                            // style={{
                            //     position: 'absolute',
                            //     top: 0,
                            //     right: 0,
                            //     width: '25%',
                            // }}
                        />
                        {this.state.detailBigView === false ? (
                            <div
                                id='right-aside'
                                className='right-aside py-5'
                                // style={{ height: (95 * window.innerHeight) / 100 }}
                                style={
                                    isMobile
                                        ? { height: window.innerHeight - 112 }
                                        : { height: window.innerHeight - 158 }
                                }
                                // style={window.innerHeight < 700 ?
                                //     { height: window.innerHeight + 170 } :
                                //     { height: window.innerHeight - 158 }}
                            >
                                <div className=''>
                                    <div className='d-flex justify-content-between mb-5 pl-7 pr-7'>
                                        <div
                                            className='d-flex align-items-center'
                                            style={{ float: 'left' }}
                                        >
                                            <h3 className='m-0 title-color'>
                                                {this.props.calingFrom !==
                                                    undefined &&
                                                this.props.calingFrom ===
                                                    'search'
                                                    ? this.props.pageTitle
                                                    : this.state.pageTitle}
                                            </h3>
                                        </div>
                                        <div
                                            className='d-flex filter_part'
                                            style={{ maxWidth: 500 }}
                                        >
                                            <div
                                                style={{ float: 'left' }}
                                                className='filter normal_view_filter'
                                            >
                                                <SearchFilter
                                                    inputProps={{
                                                        maxLength: 50,
                                                        disabled:
                                                            this.state
                                                                .row_transition,

                                                        style: this.state
                                                            .row_transition
                                                            ? {
                                                                  cursor: 'not-allowed',
                                                              }
                                                            : {
                                                                  paddingRight:
                                                                      '34px',
                                                              },
                                                    }}
                                                    disabled={
                                                        this.state
                                                            .row_transition
                                                    }
                                                    value={this.state.filterTxt}
                                                    callBack={(text) =>
                                                        this.restrictFilterDataGrid(
                                                            text
                                                        )
                                                    }
                                                />
                                            </div>
                                            {/* old filter  */}
                                            {/* <div
                                                style={{ float: 'left' }}
                                                className='filter normal_view_filter'
                                            >
                                                <input
                                                    type='text'
                                                    placeholder=''
                                                    value={this.state.filterTxt}
                                                    onChange={(event) =>
                                                        this.restrictFilterDataGrid(
                                                            event.target.value
                                                        )
                                                    }
                                                    maxLength={50}
                                                    disabled={
                                                        this.state
                                                            .row_transition
                                                    }
                                                    style={
                                                        this.state
                                                            .row_transition
                                                            ? {
                                                                  cursor: 'not-allowed',
                                                              }
                                                            : {
                                                                  paddingRight:
                                                                      '34px',
                                                              }
                                                    }
                                                />
                                                <i className='icon-md fab fa-sistrix' />
                                            </div> */}

                                            <span
                                                //className='align-self-center reload'
                                                className='reload'
                                                onClick={() =>
                                                    this.refreshTable()
                                                }
                                            >
                                                {' '}
                                                <i
                                                    title='Refresh'
                                                    className={
                                                        this.state
                                                            .displayAnimation
                                                            ? 'fas fa-sync-alt refreshing'
                                                            : 'fas fa-sync-alt'
                                                    }
                                                />{' '}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mobile_filter pl-7 pr-7 mb-3'>
                                        <div className='filter'>
                                            <input
                                                type='text'
                                                placeholder=''
                                                value={this.state.filterTxt}
                                                onChange={(event) =>
                                                    this.setState({
                                                        filterTxt:
                                                            event.target.value,
                                                    })
                                                }
                                                maxLength={20}
                                                disabled={
                                                    this.state.row_transition
                                                }
                                                style={
                                                    this.state.row_transition
                                                        ? {
                                                              cursor: 'not-allowed',
                                                          }
                                                        : {
                                                              paddingRight:
                                                                  '34px',
                                                          }
                                                }
                                            />
                                            <i className='icon-md fab fa-sistrix' />
                                        </div>
                                    </div>
                                    {this.state.printLoader ? (
                                        <div className='grouping-loader'>
                                            {' '}
                                            <Spinner
                                                show={this.state.printLoader}
                                            />{' '}
                                        </div>
                                    ) : null}
                                    <DataGridComponent
                                        detailBigViewExpandObj={{
                                            data: this.state
                                                .detailBigViewExpand,
                                            setData: (value) => {
                                                this.setState({
                                                    detailBigViewExpand: value,
                                                });
                                            },
                                        }}
                                        {...this.state}
                                        rowData={this.state.rowData}
                                        owenerRow={this.state.owenerRow}
                                        userPermission={
                                            this.state.userPermission
                                        }
                                        pdfPrintRef={this.pdfPrintRef}
                                        filterTxt={this.state.filterTxt}
                                        tableDesign={this.state.tableDesign}
                                        currentPage={this.state.currentPage}
                                        perPage={this.state.perPage}
                                        handleMultiSelectClose={this.handleMultiSelectClose.bind(
                                            this
                                        )}
                                        rowHeightfn={rowHeightDataGrid}
                                        onRowClick={this.onRowClick.bind(this)}
                                        onSelectedRowsChange={this.onSelectedRowsChange.bind(
                                            this
                                        )}
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                        }}
                                        calingFrom={this.props.calingFrom}
                                        refreshTable={this.refreshTable}
                                        allActions={
                                            this.state.NewPermission !==
                                                undefined &&
                                            this.state.NewPermission
                                                .allActions !== undefined
                                                ? this.state.NewPermission
                                                      .allActions
                                                : this.state.userPermission !==
                                                      undefined &&
                                                  this.state.userPermission
                                                      .allActions !== undefined
                                                ? this.state.userPermission
                                                      .allActions
                                                : undefined
                                        }
                                        tagRefresh={this.state.tagRefresh}
                                        tagPopup={this.state.tagPopup}
                                        tagDropDownData={
                                            this.state.tagDropDownData
                                        }
                                        selected_tag={this.state.selected_tag}
                                        selectedTagDocId={
                                            this.state.selectedTagDocId
                                        }
                                        count={this.props.count}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {this.state.openDelete ? (
                            <DeleteModal
                                openDelete={this.state.openDelete}
                                rowsToDelete={this.state.rowsToDelete}
                                /* closeDeletePopUp={() => {
                                    this.closeDeletePopUp();
                                }} */
                                closeDeletePopUp={this.closeDeletePopUp.bind(
                                    this
                                )}
                                processId={this.state.processId}
                                statusId={this.state.statusId}
                                deleteActionId={this.state.deleteActionId}
                                branchData={this.state.branches}
                                refreshTable={this.resetBigView}
                                calingFrom='inbox'
                                selectedRow={this.state.selectedRows}
                            />
                        ) : null}
                        {this.state.row_transition === true ? (
                            <div
                                id='left-aside'
                                //style={{ height: window.innerHeight - 158 }}
                                className={
                                    this.state.detailBigView &&
                                    this.state.is_locked === false
                                        ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                        : this.state.is_locked &&
                                          this.state.detailBigView
                                        ? 'left-aside py-9 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                        : this.state.is_locked
                                        ? 'left-aside py-9 rounded-lg aside-fixed flex-row-auto '
                                        : 'left-aside py-5 rounded-lg aside-fixed flex-row-auto '
                                }
                                // style={{height:(window.innerHeight- window.innerHeight / 7)}}
                                // ref={(el) => {
                                //     if (el) {
                                //         el.style.setProperty('overflow','auto','important');
                                //     }
                                //    }}
                            >
                                <>
                                    <DocumentDetail
                                        pdfPrintRef={this.pdfPrintRef}
                                        nextDocument={this.state.nextDocument}
                                        resetNextDoc={() => {
                                            this.setState({
                                                nextDocument: false,
                                            });
                                        }}
                                        selectedDocId={this.state.selectedDocId}
                                        username={username}
                                        dispname={dispname}
                                        notesVal={this.state.notesVal}
                                        openDeletePopUp={() => {
                                            this.openDeletePopUp.call(this);
                                        }}
                                        statusArray={statusArray}
                                        branches={this.state.branches}
                                        download={() => {
                                            download(this.state.onRowClickId);
                                        }}
                                        rowTransition={() => {
                                            this.rowTransition();
                                        }}
                                        selectedGroup={this.set_group}
                                        setBranch={this.setBranch}
                                        setInbox={this.set_inbox}
                                        groups={this.state.groups}
                                        saveTransition={() => {
                                            this.save_transition(true);
                                        }}
                                        openBigView={() => {
                                            this.openBigView();
                                        }}
                                        notesDDL={JSON.parse(
                                            localStorage.getItem('noteTypes')
                                        )}
                                        isLocked={this.state.is_locked}
                                        lockedBy={this.state.is_locked_by}
                                        closeBigView={() => {
                                            this.closeBigView();
                                        }}
                                        menuGroup={this.state.menu_group}
                                        menuBranch={this.state.menu_branch}
                                        menuInbox={this.state.menu_inbox}
                                        rowData={this.state.rowData}
                                        rowDetails={this.state.rowDetails}
                                        ownerlist={this.state.owners}
                                        menuTags={this.state.menu_tags}
                                        menuSelectedTags={
                                            this.state.menu_selected_tag
                                        }
                                        tagColorArray={
                                            this.state.newTagColorArray
                                        }
                                        editDetails={this.editDetails}
                                        showBranch={this.state.show_branch}
                                        showDelete={this.state.show_delete}
                                        showDownload={this.state.show_download}
                                        showGroup={this.state.show_group}
                                        showInbox={this.state.show_inbox}
                                        showNotes={this.state.show_notes}
                                        showOwner={this.state.show_owner}
                                        showPdf={this.state.show_pdf}
                                        showPrint={this.state.show_print}
                                        showTag={this.state.show_tag}
                                        noteCount={this.state.noteCount}
                                        pageCount={this.state.pageCount}
                                        refreshTag={() => {
                                            this.refreshTag();
                                        }}
                                        enableLoader={this.enableLoader.bind(
                                            this
                                        )}
                                        menu_name={this.state.menu_name}
                                        processId={this.state.processId}
                                        statusId={this.state.statusId}
                                        branchActionId={
                                            this.state.branchActionId
                                        }
                                        groupActionId={this.state.groupActionId}
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                            this.props.inboxPage({ ...state });
                                        }}
                                        pdf_preview={this.state.pdf_preview}
                                        calingFrom={this.props.calingFrom}
                                        allActions={
                                            this.state.NewPermission !==
                                                undefined &&
                                            this.state.NewPermission
                                                .allActions !== undefined
                                                ? this.state.NewPermission
                                                      .allActions
                                                : this.state.userPermission !==
                                                      undefined &&
                                                  this.state.userPermission
                                                      .allActions !== undefined
                                                ? this.state.userPermission
                                                      .allActions
                                                : undefined
                                        }
                                        count={this.props.count}
                                        refreshTable={() => this.refreshTable()}
                                        nextDocFromAttach={
                                            this.state.nextDocFromAttach
                                        }
                                    />
                                    {this.state.pdf_preview ? (
                                        <PdfPreview
                                            ref={this.pdfPrintRef}
                                            docId={this.state.selectedDocId}
                                            loader={(loader) => {
                                                this.setState({
                                                    blur_spinner: loader,
                                                });
                                            }}
                                            //need to check below line is comment bcoz not showing no of pages
                                            // detailBigView={
                                            //     this.state.detailBigView
                                            // }
                                            isLocked={this.state.is_locked}
                                        />
                                    ) : null}
                                </>
                            </div>
                        ) : this.state.row_transition === '' ? (
                            <div
                                id='left-aside'
                                className={
                                    this.state.detailBigView
                                        ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                        : 'left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                                }
                                style={{
                                    height:
                                        window.innerHeight -
                                        window.innerHeight / 7,
                                }}
                            >
                                <SpinnerThree show />
                            </div>
                        ) : null}

                        {this.state.detailBigView === true ? (
                            <div
                                id='left-last-aside'
                                className={
                                    this.state.detailBigView
                                        ? ' expand-mode left-aside py-5 rounded-lg aside-fixed flex-row-auto'
                                        : null
                                }
                                //     style={{height:(window.innerHeight- window.innerHeight / 7)
                                //            }}
                                //     ref={(el) => {
                                //     if (el) {
                                //         el.style.setProperty('overflow','auto','important');
                                //     }
                                //    }}
                            >
                                <DetailBigView
                                    pdfPrintRef={this.pdfPrintRef}
                                    refreshForNextDocument={(docid) => {
                                        this.refreshForNextDocument(docid);
                                    }}
                                    refreshTable={this.resetBigView}
                                    selectedDocId={this.state.selectedDocId}
                                    username={username}
                                    dispname={dispname}
                                    notesVal={this.state.notesVal}
                                    openDeletePopUp={() => {
                                        this.openDeletePopUp.call(this);
                                    }}
                                    statusArray={this.state.statusArray}
                                    branches={this.state.branches}
                                    download={() => {
                                        download(this.state.onRowClickId);
                                    }}
                                    rowTransition={() => {
                                        this.rowTransition();
                                    }}
                                    selectedGroup={this.set_group}
                                    setBranch={this.setBranch}
                                    setInbox={this.set_inbox}
                                    groups={this.state.groups}
                                    saveTransition={() => {
                                        this.save_transition();
                                    }}
                                    openBigView={() => {
                                        this.openBigView();
                                    }}
                                    notesDDL={JSON.parse(
                                        localStorage.getItem('noteTypes')
                                    )}
                                    isLocked={this.state.is_locked}
                                    lockedBy={this.state.is_locked_by}
                                    menuGroup={this.state.menu_group}
                                    menuBranch={this.state.menu_branch}
                                    menuInbox={this.state.menu_inbox}
                                    editDetails={this.state.edit_details}
                                    showBranch={this.state.show_branch}
                                    showDelete={this.state.show_delete}
                                    showDownload={this.state.show_download}
                                    showGroup={this.state.show_group}
                                    showInbox={this.state.show_inbox}
                                    showNotes={this.state.show_notes}
                                    showOwner={this.state.show_owner}
                                    showPdf={this.state.show_pdf}
                                    showPrint={this.state.show_print}
                                    showTag={this.state.show_tag}
                                    noteCount={this.state.noteCount}
                                    pageCount={this.state.pageCount}
                                    menu_name={this.state.menu_name}
                                    processId={this.state.processId}
                                    statusId={this.state.statusId}
                                    branchActionId={this.state.branchActionId}
                                    groupActionId={this.state.groupActionId}
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                        this.props.inboxPage({ ...state });
                                    }}
                                    calingFrom={this.props.calingFrom}
                                    selectedDetailsOfRow={
                                        this.state.selectedDetailsOfRow
                                    }
                                    statusCheck='inbox'
                                    userPermission={this.state.userPermission}
                                    count={this.props.count}
                                    refreshTableData={() => this.refreshTable()}
                                />
                            </div>
                        ) : null}
                    </div>
                    {/* :null} */}

                    <SpinnerTwo show={this.state.main_spinner} />

                    <SpinnerBlur show={this.state.blur_spinner} />

                    {this.props.loading ? (
                        <SpinnerBlur show={this.props.loading} />
                    ) : null}

                    {this.state.single_edit === true ? (
                        <div
                            style={{
                                height: '950px',
                                //paddingTop: '30px',
                                // position: 'relative',
                            }}
                        >
                            <EditWorkPage
                                style={{ position: 'sticky' }}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                    this.props.inboxPage({ ...state });
                                }}
                            />
                        </div>
                    ) : null}
                </ThemeContext.Provider>

                {this.props.related_doc_detail ? (
                    <DocumentDetailViewComponent callingFrom='inboxPage' />
                ) : null}

                {this.props.multiple_edit_page ? (
                    <MultipleEditPage
                        refreshTable={this.refreshTable}
                        callingFrom='inboxPage'
                    />
                ) : null}
            </>
        );
    }
}
const mapDispatchToProps = {
    getOwnerForAuth: getOwnerForAuth,
    getListaction: getListaction,
    setSelectedRow: setSelectedRow,
    getRulesJson: getRulesJson,
    setRowData: (param) => setRowData(param),
    getCountData: getCountData,
    showLoader: (canShow) => showLoader(canShow),
    reRenderInbox: (canrender) => reRenderInbox(canrender),
    hideBigView: (hide) => hideBigView(hide),
    setMenuName: (name) => setMenuName(name),
    setPageData: (data) => setPageData(data),
    setDocOwner,
    setProcessId,
    setStatusId, //kalpana added
    setCallingPage,
    getOwners,
    updateList,
    updateInboxState,
    getDDLlist: getDDLlist,
    getConfigData: getConfigData,
    getSelectedRowDetails: getSelectedRowDetails,
    inboxPage,
    editDetailPage,
    logOut,
    clearInboxState,
};
const mapStateToProps = (state) => {
    return {
        customSearchQueries:
            state.configReducerPersist.configData
                .customSearchNamedIdsNeedingConfiguration,
        loading: state.inbox.loading,
        rowData: state.inbox.rowData,
        mainSpinner: state.persist.loader,
        rerenderInbox: state.persist.rerenderInbox,
        hidebigview: state.persist.hidebigview,
        count: state.persist.count,
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        currentMenuName: state.persist.currentMenuName,
        stateToken: state.inbox.stateToken,
        stateData: stringEmptyOrUndefined(state.inbox)
            ? state.inbox.stateData
            : {},
        inbox: state.inbox,
        ddl: state.persistDDL.DDL,
        related_doc_detail: state.inbox.inboxPage.related_doc_detail,
        multiple_edit_page: state.inbox.inboxPage.multiple_edit_page,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(InboxPage))
);
