/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import ByPhysician from '../../../components/physician/ByPhysician';
import ViewEditPhysician from '../../../components/physician/ViewEditPhysician';
import DeletedDocumentPage from '../../deletedDocument/DeletedDocumentPage';
import PatientListingDataGrid from '../../../components/datagrid/PatientListingDataGrid';
import LocationListingDataGrid from '../../../components/datagrid/LocationListingDataGrid';
import NoPhysicianModal from '../../search/components/NoPhysicianModal';
import { getDDLlist, getConfigData } from '../../_redux/actions/configActions';
import { deletedPage } from '../../_redux/actions/searchActions';
import { inboxPage } from '../../_redux/actions/inboxActions';
import {
    PatientWithUnderScore,
    formatHeaderNameWithOutSpace,
    getDropDownDataFromApi,
    GetFieldData,
    showErrorToast,
} from '../../../components/commonfunction';
import {
    recordSetSearch,
    configurationCustomeSearchId,
    configurationRecordSetId,
} from '../../api/api';

export class PhysicianSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLookup: 'byPhysician',
            edit_or_create: 'edit',
            edit_physician: '',
            tab_name: 'tab_inbox',
            show_tabs: true,
            //physician_filter: '',
            refresh: false,
            toggleRecentDoc: false,
            togglePhysician: true,
            check: false,
            selectedPhysician: '',
            error: false,
            result_refresh: true,
            deleteQuery: false,
            create_button: false,
            datagridLoad: true,
            addOperation: true,
            mobileHide: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // if (
        //     (this.state.physician_filter === '' ||
        //         this.state.physician_filter === null) &&
        //     this.state.selectedPhysician !== undefined &&
        //     this.state.selectedPhysician.physician_last_name !== undefined &&
        //     this.state.toggleRecentDoc === false
        // ) {
        //     this.setState({
        //         physician_filter:
        //             this.state.selectedPhysician.physician_last_name +
        //             ', ' +
        //             this.state.selectedPhysician.physician_first_name,
        //     });
        // }
        if (this.props.location.pathname !== this.state.pathName) {
            this.setState({
                pathName: this.props.location.pathname,
            });
        }
        if (String(prevState.pathName).includes('edit')) {
            var myEle = document.getElementById('documents_count');
            var myTrack = document.getElementById('tracking_count');
            if (
                myEle &&
                this.props.inbox.inboxPage.documents_count !== 0 &&
                this.props.inbox.inboxPage.documents_count !== undefined
            ) {
                document.getElementById('documents_count').innerHTML =
                    this.props.inbox.inboxPage.documents_count;
                document.getElementById('documents_count').style.display =
                    'inherit';
            }

            if (
                myTrack &&
                this.props.inbox.inboxPage.tracking_count !== 0 &&
                this.props.inbox.inboxPage.tracking_count !== undefined
            ) {
                document.getElementById('tracking_count').innerHTML =
                    this.props.inbox.inboxPage.tracking_count;
                document.getElementById('tracking_count').style.display =
                    'inherit';
            }
        }
        if (prevState.tab_name !== this.state.tab_name) {
            this.setState({
                result_refresh: false,
            });
            if (
                this.state.physician_filter === '' ||
                this.state.physician_filter === null
            ) {
                this.setState({
                    physician_filter:
                        this.state.selectedPhysician.physician_last_name +
                        ', ' +
                        this.state.selectedPhysician.physician_first_name,
                });
            }
            setTimeout(() => {
                this.setState({ result_refresh: true });
            }, 100);
            document.body.classList.remove('doc-detail-view');
            document.body.classList.remove('aside-minimize');
        }
        const current_path = this.props.location.pathname;
        const path_arr = current_path.split('/');
        // var current_menu = path_arr[1];
        if (
            this.props.location.search.includes('?operation=add') &&
            this.state.addOperation === true
        ) {
            this.setState({
                edit_or_create: 'create',
                view_physician: true,
                addOperation: false,
                show_tabs: true,
                create_physician: true,
                edit_physician: true,
                create_button: true,
                selectedPhysician: undefined,
            });
        }
        if (path_arr.length === 2) {
            if (this.state.show_tabs === false) {
                this.setState({ show_tabs: true });
            }
        } else if (path_arr.length > 2) {
            if (path_arr[1] === 'search' && path_arr[3] !== 'edit') {
                if (this.state.show_tabs === false) {
                    this.setState({ show_tabs: true });
                }
            }
            if (path_arr[1] === 'search' && path_arr[3] === 'edit') {
                if (this.state.show_tabs === true) {
                    this.setState({ show_tabs: false });
                }
            }
        }

        if (
            (this.state.selectedPhysician !== undefined &&
                prevState.selectedPhysician !== undefined &&
                this.state.selectedPhysician.npi !==
                    prevState.selectedPhysician.npi) ||
            (prevState.edit_or_create === 'some' &&
                this.state.edit_or_create === 'edit') ||
            (prevState.selectedPhysician === undefined &&
                this.state.edit_or_create === 'edit' &&
                this.state.selectedPhysician !== undefined &&
                this.state.selectedPhysician.npi !== undefined)
        ) {
            // last or "||" condition handled for if we click  create physician and without cancel we select physician autocomplete then error arrise so added
            if (this.state.edit_or_create === 'edit') {
                this.setState({
                    result_refresh: false,
                    error: false,
                    deleteQuery: false,
                    datagridLoad: true,
                });
            }
            var customSearchQueries = [];
            customSearchQueries = this.props.customSearchQueries;
            var searchText = '';
            var pathname = '';
            var trackingSearchText = '';
            var trackingPathname = '';

            var concat = '?';
            customSearchQueries.map((data) => {
                if (
                    String('physicianinbox') ===
                    formatHeaderNameWithOutSpace(data.name)
                ) {
                    pathname = data.searchId;
                    if (
                        data.inputSearchFieldIdsAndValues[0] !== undefined &&
                        data.inputSearchFieldIdsAndValues[0] !== ''
                    ) {
                        searchText +=
                            concat +
                            'fieldSearch=' +
                            data.inputSearchFieldIdsAndValues[0].fieldId +
                            '%3D' +
                            this.state.selectedPhysician.npi;
                    }
                }
                if (
                    String('physicianoutbox') ===
                    formatHeaderNameWithOutSpace(data.name)
                ) {
                    trackingPathname = data.searchId;
                    if (
                        data.inputSearchFieldIdsAndValues[0] !== undefined &&
                        data.inputSearchFieldIdsAndValues[0] !== ''
                    ) {
                        trackingSearchText +=
                            concat +
                            'fieldSearch=' +
                            data.inputSearchFieldIdsAndValues[0].fieldId +
                            '%3D' +
                            this.state.selectedPhysician.npi;
                    }
                }
            });
            searchText = pathname + searchText;

            trackingSearchText = trackingPathname + trackingSearchText;
            this.setState({ physician_inbox_query: searchText });
            this.setState({ physician_outbox_query: trackingSearchText });
            this.openTab('tab_inbox');
            var eleDiv = document.getElementById('documents_count');
            if (eleDiv) {
                document.getElementById('documents_count').style.display =
                    'none';
                document.getElementById('tracking_count').style.display =
                    'none';
                document.getElementById('patients_count').style.display =
                    'none';
                document.getElementById('location_count').style.display =
                    'none';
            }
            this.props.inboxPage({
                tracking_count: 0,
                documents_count: 0,
            });
            if (this.state.edit_or_create === 'edit') {
                setTimeout(() => {
                    this.setState({ result_refresh: true });
                }, 100);
            }
        }
    }
    getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (!this.props.ddl.hasOwnProperty('state')) {
            getDropDownDataFromApi(fieldData.state).then((data) => {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }
        if (!this.props.ddl.hasOwnProperty('deliverymethod')) {
            getDropDownDataFromApi(fieldData.delivery_method).then((data) => {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }
    }
    async apiFunction(idx) {
        configurationCustomeSearchId(idx)
            .then(async (data) => {
                localStorage.setItem('configData', JSON.stringify(data));
                if (this.props.getConfigData !== undefined) {
                    await this.props.getConfigData(data);
                    // this.props.getConfigData()
                }
            })
            .catch((error) => {
                // Handle any errors
                console.error(error);
            });
    }
    async recorsetFunction(idx) {
        configurationRecordSetId(idx)
            .then(async (data) => {
                localStorage.setItem('configData', JSON.stringify(data));
                if (this.props.getConfigData !== undefined) {
                    await this.props.getConfigData(data);
                    // this.props.getConfigData()
                }
            })
            .catch((error) => {
                // Handle any errors
                console.error(error);
            });
    }
    async componentDidMount() {
        if (
            this.props.configData.customSearchNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.customSearchNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (data.name === 'Physician Inbox') {
                        await this.apiFunction(data.id);
                    }
                    if (data.name === 'Physician Outbox') {
                        await this.apiFunction(data.id);
                    }
                    if (data.name === 'Physician Patients') {
                        await this.apiFunction(data.id);
                    }
                }
            );
        }
        if (
            this.props.configData.recordsetNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (data.name === 'Locations By Physician') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Admission Coordinator') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Locations By Physician') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physician Directory Standard') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physician Directory Locations') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physicians By Location') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Locations By Physician Standard') {
                        await this.recorsetFunction(data.id);
                    }
                    if (data.name === 'Physician Locations') {
                        await this.recorsetFunction(data.id);
                    }
                }
            );
        }
        this.getTransitonDropDownData();
        this.setState({ show_tabs: true });
        const current_path = this.props.location.pathname;

        const path_arr = current_path.split('/');
        // var current_menu = path_arr[1];

        if (path_arr.length === 2) {
            if (this.state.show_tabs === false) {
                this.setState({ show_tabs: true });
            }
        }
        if (path_arr.length > 2) {
            if (path_arr[1] === 'search' && path_arr[3] !== 'edit') {
                if (this.state.show_tabs === false) {
                    this.setState({ show_tabs: true });
                }
            }
            if (path_arr[1] === 'search' && path_arr[3] === 'edit') {
                if (this.state.show_tabs === true) {
                    this.setState({ show_tabs: false });
                }
            }
        }

        // in order to get details of Physician from url
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        let npi = '';

        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            // [ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
            /*Array [ "NPI", "1234567895" ]
			Array [ "alldata", "" ]
			Array [ "orderingphysician", "ERA,%20PHYSICIAN1," ]
			Array [ "faxnumber", "4027075916" ]
			Array [ "npi", "1234567895" ]
			*/
            if (pair[0] === 'NPI') {
                npi = pair[1];
            }
        }

        //const query = new URLSearchParams(this.props.location.search);
        //const npi = query.get('NPI')

        this.performPhysicianSearch(npi);
    }

    performPhysicianSearch = async (npi) => {
        if (npi !== '' && npi !== null) {
            this.setState({ refresh: false });
            this.setState({ npi: npi });
            let configuredRecordsets = [];
            let physician_FieldIdArray;
            let physician_RecordsetId;
            let physician_ModifiableRecordsetId;

            configuredRecordsets = this.props.configData.configuredRecordsets;
            if (configuredRecordsets !== undefined) {
                configuredRecordsets.map((data) => {
                    if (
                        formatHeaderNameWithOutSpace(data.name) ===
                        'physiciandirectory'
                    ) {
                        physician_FieldIdArray = data.fields;
                        physician_RecordsetId = data.recordsetId;
                        physician_ModifiableRecordsetId =
                            data.modifiableRecordsetId;
                    }
                    return data;
                });
            }

            this.setState({
                PhysicianFieldIdArray: physician_FieldIdArray,
                PhysicianRecordsetId: physician_RecordsetId,
                PhysicianModifiableRecordsetId: physician_ModifiableRecordsetId,
            });

            if (physician_RecordsetId === undefined) {
                showErrorToast(
                    'Access Restricted : You dont have access to view this physician'
                );
            }

            let searchText = '';
            physician_FieldIdArray.map((fieldData) => {
                if (fieldData.name === 'NPI') {
                    searchText += `fieldSearch=${fieldData.fieldId}%3D${npi}%2A`;
                    // encodeURI(fieldData.fieldId + '=' + e.target.value);
                }
                //  return fieldData;
            });
            let status;
            recordSetSearch(physician_RecordsetId, searchText)
                .then((response) => {
                    status = response.status;
                    return response.json();
                })
                .then((data) => {
                    if (status === 200) {
                        // var searchResult = PatientWithUnderScore(data);
                        const singlePhysicain = PatientWithUnderScore(data);
                        let search = {};
                        if (singlePhysicain.length > 0) {
                            search = singlePhysicain[0];
                            this.props.updateState({
                                selectedPhysician: search,
                            });
                            this.props.updateState({
                                physician_filter: search.ordering_physician,
                            });
                            this.setState({
                                selectedPhysician: search,
                                edit_physician: false,
                                view_physician: true,
                                physician_filter: search.ordering_physician,
                                refresh: true,
                                tab_name: 'tab_inbox',
                                error: false,
                                deleteQuery: false,
                                datagridLoad: true,
                                // show_tabs:false
                            });
                            this.openTab('tab_inbox');
                        } else {
                            this.setState({
                                selectedPhysician: '',
                                edit_physician: false,
                                view_physician: false,
                                refresh: true,
                                error: true,
                                deleteQuery: true,
                                datagridLoad: false,
                            });
                        }
                    } else {
                        this.setState({
                            selectedPhysician: '',
                            edit_physician: false,
                            view_physician: false,
                            refresh: true,
                            error: true,
                            deleteQuery: true,
                        });
                    }
                });
        } else {
            this.setState({ refresh: true });
        }
    };
    getMenuItemActive = () => {
        const currentClass =
            document.getElementsByClassName('custom-menu-link');

        for (let i = 0; i < currentClass.length; i++) {
            currentClass[i].classList.remove('root-active');
        }

        /* return ( location.pathname ===url
			? "active current"
			: "") */
    };

    updateState = (state) => {
        this.setState({ ...state });
    };

    byPhysician = async (view) => {
        this.setState({ currentLookup: 'byPhysician' });
    };

    byNpi = async (view) => {
        this.setState({ currentLookup: 'byNpi' });
    };

    openTab = async (tab_name) => {
        this.setState({
            tab_name: tab_name,
        });
        if (tab_name === 'tab_outbox') {
            this.props.deletedPage({
                showStatistic: true,
            });
        } else {
            this.props.deletedPage({
                showStatistic: false,
            });
        }
    };

    toggleRecentDoc = () => {
        this.setState({
            toggleRecentDoc: !this.state.toggleRecentDoc,
            togglePhysician: !this.state.togglePhysician,
            check: !this.state.check,
        });
    };
    handleOpenNewPhysicain = () => {
        this.setState({
            deleteQuery: false,
            error: false,
            view_physician: true,
            togglePhysician: true,
            show_tabs: false,
            edit_or_create: 'DeletePhysician',
            edit_physician: true,
            create_button: true,
            calingFrom: 'search',
            //tab_name: 'check',
        });
    };
    render() {
        return (
            <>
                {this.state.refresh ? (
                    <div className='search-inner-right-aside'>
                        {this.state.mobileHide === false ? (
                            <div className='search-top'>
                                <div className='form-group search-block'>
                                    <div
                                        className={
                                            this.state.check === false
                                                ? 'form-group search_bypatient'
                                                : 'form-group search_bypatient close-lookup'
                                        }
                                        style={{
                                            display: this.state.show_tabs
                                                ? 'block'
                                                : 'none',
                                        }}
                                    >
                                        <div className='row'>
                                            {this.state.toggleRecentDoc ===
                                                false && (
                                                <div className='ml-4'>
                                                    <label className='pl-5'>
                                                        LOOKUP BY
                                                    </label>
                                                    <div className='tab_part'>
                                                        <div className='tabs'>
                                                            <Nav
                                                                style={{
                                                                    float: 'left',
                                                                }}
                                                                id='noanim-tab'
                                                                defaultActiveKey={
                                                                    this.state
                                                                        .currentLookup
                                                                }
                                                                variant='pills'
                                                                className='search_nav'
                                                            >
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        title='By Physician'
                                                                        key={0}
                                                                        eventKey='byPhysician'
                                                                        onClick={
                                                                            this
                                                                                .byPhysician
                                                                        }
                                                                    >
                                                                        By
                                                                        Physician
                                                                        Name
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link
                                                                        title='By NPI Number'
                                                                        key={1}
                                                                        eventKey='byNpi'
                                                                        onClick={
                                                                            this
                                                                                .byNpi
                                                                        }
                                                                    >
                                                                        By NPI
                                                                        Number
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='col'>
                                                <ByPhysician
                                                    state={{ ...this.state }}
                                                    updateState={(state) => {
                                                        this.setState({
                                                            ...state,
                                                        });
                                                    }}
                                                    resetSubmit={() => {
                                                        this.setState({
                                                            submit_edit_physician: false,
                                                        });
                                                    }}
                                                    validateEdit={
                                                        this.state.validateEdit
                                                    }
                                                    calingFrom='search'
                                                    edit_or_create={
                                                        this.state
                                                            .edit_or_create
                                                    }
                                                    selectedPhysician={
                                                        this.state
                                                            .selectedPhysician
                                                    }
                                                    physician_filter={
                                                        this.state
                                                            .physician_filter
                                                    } // added by kalpana to send physician filter
                                                    toggleRecentDoc={
                                                        this.state
                                                            .toggleRecentDoc
                                                    }
                                                    toggleRecentDocFun={() => {
                                                        this.toggleRecentDoc();
                                                    }}
                                                    tab_name={
                                                        this.state.tab_name
                                                    }
                                                    create_button={
                                                        this.state.create_button
                                                    }
                                                />
                                            </div>
                                            {this.state.view_physician &&
                                            this.state.edit_or_create ===
                                                'edit' ? (
                                                <>
                                                    <div
                                                        className='dropdown_icon mr-4'
                                                        onClick={() =>
                                                            this.toggleRecentDoc()
                                                        }
                                                    >
                                                        <i
                                                            title={
                                                                this.state
                                                                    .toggleRecentDoc
                                                                    ? 'Close'
                                                                    : 'Open'
                                                            }
                                                            className={
                                                                this.state
                                                                    .toggleRecentDoc
                                                                    ? 'fa fa-chevron-up'
                                                                    : 'fa fa-chevron-down'
                                                            }
                                                            aria-hidden='true'
                                                        />
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                    {this.state.error && (
                                        <div>
                                            <NoPhysicianModal
                                                deleteQuery={
                                                    this.state.deleteQuery
                                                }
                                                updateState={(state) => {
                                                    this.setState({ ...state });
                                                }}
                                                handleOpenNewPhysicain={() =>
                                                    this.handleOpenNewPhysicain()
                                                }
                                            />
                                            <div className='form-group row data-display'>
                                                <div className='col-lg-12'>
                                                    <div className='search_page view_patient'>
                                                        <div
                                                            className='patient_detail edit-detail-bg'
                                                            style={{
                                                                backgroundColor:
                                                                    '#ffffff',
                                                            }}
                                                        >
                                                            <div className='form-group row'>
                                                                <span
                                                                    className='col-lg-12'
                                                                    style={{
                                                                        color: 'red',
                                                                        textAlign:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    No Physician
                                                                    found
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className='form-group row data-display'
                                        style={{
                                            display:
                                                this.state.view_physician &&
                                                this.state.togglePhysician &&
                                                this.state.show_tabs
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        <div className='col-lg-12'>
                                            {this.state.view_physician &&
                                            this.state.togglePhysician &&
                                            this.state.show_tabs ? (
                                                <ViewEditPhysician
                                                    // newReferral={
                                                    //     this.state.formData.new_referral
                                                    // }

                                                    state={{ ...this.state }}
                                                    resetSubmit={() => {
                                                        this.setState({
                                                            submit_edit_physician: false,
                                                        });
                                                    }}
                                                    selectedPhysician={
                                                        this.state
                                                            .selectedPhysician
                                                    }
                                                    edit_physician={
                                                        this.state
                                                            .edit_physician
                                                    }
                                                    submitEditPhysician={
                                                        this.state
                                                            .submit_edit_physician
                                                    }
                                                    physicianFieldIdArray={
                                                        this.state
                                                            .physicianFieldIdArray
                                                    }
                                                    physicianModifiableRecordsetId={
                                                        this.state
                                                            .physicianModifiableRecordsetId
                                                    }
                                                    closeEditPhysician={(
                                                        formData
                                                    ) =>
                                                        this.closeEditPhysician(
                                                            formData
                                                        )
                                                    }
                                                    updateState={(state) => {
                                                        this.setState({
                                                            ...state,
                                                        });
                                                    }}
                                                    // byPatient={this.byPatient}
                                                    // byOrder={this.state.by_order}
                                                    allRecordSetID={
                                                        this.state
                                                            .allRecordSetID
                                                    }
                                                    updateFromCreate={
                                                        this.submitEditPhysician
                                                    }
                                                    create_physician={
                                                        this.state
                                                            .create_physician
                                                    }
                                                    edit_or_create={
                                                        this.state
                                                            .edit_or_create
                                                    }
                                                    calingFrom='search'
                                                    validateEdit={
                                                        this.state.validateEdit
                                                    }
                                                    emailValidation={
                                                        this.state
                                                            .emailValidation
                                                    }
                                                    faxValidation={
                                                        this.state.faxValidation
                                                    }
                                                    phoneValidation={
                                                        this.state
                                                            .phoneValidation
                                                    }
                                                    npiValidation={
                                                        this.state.npiValidation
                                                    }
                                                    toggleRecentDoc={
                                                        this.state
                                                            .toggleRecentDoc
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                {this.state.selectedPhysician &&
                                this.state.show_tabs &&
                                this.state.datagridLoad ? (
                                    <div className='navigation'>
                                        <div className='tab'>
                                            <ul className='nav nav-pills'>
                                                <li
                                                    className='nav-item  menu-item-rel'
                                                    // aria-haspopup='true'
                                                >
                                                    <NavLink
                                                        title='Inbox'
                                                        id='tab_inbox'
                                                        onClick={() => {
                                                            this.openTab(
                                                                'tab_inbox'
                                                            );
                                                        }}
                                                        className={
                                                            this.state
                                                                .tab_name ===
                                                            'tab_inbox'
                                                                ? 'custom-menu-link menu-active'
                                                                : 'custom-menu-link'
                                                        }
                                                        to='#'
                                                    >
                                                        <span className='nav-text'>
                                                            Inbox
                                                        </span>
                                                        <span
                                                            className='count'
                                                            id='documents_count'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            9
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li
                                                    className='nav-item  menu-item-rel'
                                                    // aria-haspopup='true'
                                                >
                                                    <NavLink
                                                        title='Outbox'
                                                        id='tab_outbox'
                                                        onClick={() => {
                                                            this.openTab(
                                                                'tab_outbox'
                                                            );
                                                        }}
                                                        className={
                                                            this.state
                                                                .tab_name ===
                                                            'tab_outbox'
                                                                ? 'custom-menu-link menu-active'
                                                                : 'custom-menu-link'
                                                        }
                                                        // title="Physician"
                                                        to='#'
                                                    >
                                                        <span className='nav-text'>
                                                            Outbox
                                                        </span>
                                                        <span
                                                            className='count'
                                                            id='tracking_count'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            98
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li
                                                    className='nav-item  menu-item-rel'
                                                    // aria-haspopup='true'
                                                >
                                                    <NavLink
                                                        title='Patients'
                                                        id='tab_patients'
                                                        onClick={() => {
                                                            this.openTab(
                                                                'tab_patients'
                                                            );
                                                        }}
                                                        className={
                                                            this.state
                                                                .tab_name ===
                                                            'tab_patients'
                                                                ? 'custom-menu-link menu-active'
                                                                : 'custom-menu-link'
                                                        }
                                                        // title="Physician"
                                                        to='#'
                                                    >
                                                        <span className='nav-text'>
                                                            Patients
                                                        </span>
                                                        <span
                                                            className='count'
                                                            id='patients_count'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            989
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li
                                                    className='nav-item  menu-item-rel'
                                                    // aria-haspopup='true'
                                                >
                                                    <NavLink
                                                        title='Locations'
                                                        id='tab_locations'
                                                        onClick={() => {
                                                            this.openTab(
                                                                'tab_locations'
                                                            );
                                                        }}
                                                        className={
                                                            this.state
                                                                .tab_name ===
                                                            'tab_locations'
                                                                ? 'custom-menu-link menu-active'
                                                                : 'custom-menu-link'
                                                        }
                                                        // title="Physician"
                                                        to='#'
                                                    >
                                                        <span className='nav-text'>
                                                            Locations
                                                        </span>
                                                        <span
                                                            className='count'
                                                            id='location_count'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            98
                                                        </span>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {this.state.selectedPhysician &&
                        this.state.tab_name === 'tab_inbox' &&
                        this.state.result_refresh &&
                        this.state.datagridLoad &&
                        this.state.edit_physician !== true ? (
                            <div>
                                {/*<InboxPage
										processId={1}
										statusId={3}
										physicianId={
											this.state.selectedPhysician.id
										}
										calingFrom='search'
										pageTitle='Inbox'
										npi={this.state.selectedPhysician.npi}
										selectedPhysician={
											this.state.selectedPhysician
										}
										updateState={(state) => {
											this.setState({ ...state });
										}}
										query={this.state.physician_inbox_query}
								/>*/}
                                {/*this.state.selectedPhysician.id}---{this.state.selectedPhysician.npi}---{this.state.physician_inbox_query*/}
                                <DeletedDocumentPage
                                    //{...this.state}
                                    calingFrom='search'
                                    pageTitle='Inbox'
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                    }}
                                    physicianId={
                                        this.state.selectedPhysician.id
                                    }
                                    npi={this.state.selectedPhysician.npi}
                                    selectedPhysician={
                                        this.state.selectedPhysician
                                    }
                                    query={this.state.physician_inbox_query}
                                    show_result={true}
                                    tab_name={this.state.tab_name}
                                    mobileHide={this.state.mobileHide}
                                />
                            </div>
                        ) : this.state.selectedPhysician &&
                          this.state.tab_name === 'tab_outbox' ? (
                            <div>
                                {/*<OutboxPage
                                    calingFrom='search'
                                    pageTitle='Outbox'
                                    processId={3}
                                    statusId={16}
                                    physicianId={
                                        this.state.selectedPhysician.id
                                    }
                                    npi={this.state.selectedPhysician.npi}
                                    selectedPhysician={
                                        this.state.selectedPhysician
                                    }
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                    }}
                                    query={this.state.physician_outbox_query}
							/>*/}

                                <DeletedDocumentPage
                                    //{...this.state}
                                    calingFrom='search'
                                    pageTitle='Outbox'
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                    }}
                                    physicianId={
                                        this.state.selectedPhysician.id
                                    }
                                    npi={this.state.selectedPhysician.npi}
                                    query={this.state.physician_outbox_query}
                                    show_result={true}
                                    tab_name={this.state.tab_name}
                                    mobileHide={this.state.mobileHide}
                                />
                            </div>
                        ) : this.state.selectedPhysician &&
                          this.state.tab_name === 'tab_patients' ? (
                            <div>
                                <PatientListingDataGrid
                                    physicianId={
                                        this.state.selectedPhysician.id
                                    }
                                    npi={this.state.selectedPhysician.npi}
                                    calingFrom='search'
                                    pageTitle='Physician Patients'
                                    selectedPhysician={
                                        this.state.selectedPhysician
                                    }
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                    }}
                                />
                            </div>
                        ) : this.state.selectedPhysician &&
                          this.state.tab_name === 'tab_locations' ? (
                            <div>
                                <LocationListingDataGrid
                                    physicianId={
                                        this.state.selectedPhysician.id
                                    }
                                    npi={this.state.selectedPhysician.npi}
                                    calingFrom='search'
                                    pageTitle='Physician Location'
                                    selectedPhysician={
                                        this.state.selectedPhysician
                                    }
                                    // updateState={(state) => {
                                    //     this.setState({ ...state });
                                    // }}
                                    toggleRecentDoc={this.state.toggleRecentDoc}
                                    toggleRecentDocFun={() => {
                                        this.toggleRecentDoc();
                                    }}
                                    updateState={(state) => {
                                        this.setState({
                                            ...state,
                                        });
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
    deletedPage,
    inboxPage,
    getConfigData: getConfigData,
};
const mapStateToProps = (state, ownProps) => {
    return {
        inbox: state.inbox,
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        rulesJson: state.persist.rulesJson,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.outbox.selectedRowDetails,
        ddl: state.persistDDL.DDL,
        currentMenuName: state.persist.currentMenuName,
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PhysicianSearch));
