/* eslint-disable array-callback-return */
import React from 'react';
// import {useSubheader} from "../../_metronic/layout";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { orderRecordSetSearch, recordSetSearch } from '../../api/api';
import { OrderWithUnderScore } from '../../../components/commonfunction';
import { debounce } from 'throttle-debounce';

const _ = require('lodash');

export class ByOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.state,
            orderAutoCompSpinner: true,
            selected_order: '',
            abortController: null,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.closeOrderDropDown);

        if (_.isEmpty(this.state.patient_filter) === false) {
            if (
                this.state.patient_filter.hasOwnProperty('order_number') &&
                !this.state.patient_filter.hasOwnProperty('fromViewDetail')
            ) {
                this.getSingleOrder(this.state.patient_filter.order_number);
            }
        }
        if (
            _.isEmpty(this.state.patient_filter) === false &&
            this.state.patient_filter.hasOwnProperty('fromViewDetail') &&
            this.state.patient_filter.hasOwnProperty('order_number')
        ) {
            this.open_order_search(this.state.patient_filter);
        }
    }

    getSingleOrder = (ordernumber) => {
        let orderSearchText;
        this.state.orderFieldIdArray.map((fieldData, index) => {
            if (fieldData.name === 'Order Number') {
                orderSearchText = `fieldSearch=${fieldData.fieldId}%3D${ordernumber}%2A`;
            }
        });
        let status;
        recordSetSearch(this.state.orderRecordsetId, orderSearchText)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then(async (data) => {
                if (status === 200) {
                    const searchResult = OrderWithUnderScore(data);

                    this.open_order_search(searchResult[0]);
                }
            });
    };

    componentDidUpdate(prevProps) {
        if (this.props.state !== prevProps.state) {
            this.setState({ ...this.props.state });
        }
    }

    closeOrderDropDown = (event) => {
        const popElement = document.getElementsByClassName('patient_dropdown');
        let popEl;
        for (let i = 0; i < popElement.length; i++) {
            popEl = popElement[i];
            const isClickInside = popEl.contains(event.target);
            if (!isClickInside) {
                this.setState({
                    open_patient_dropdown: false,
                    patient_filter: '',
                });
                this.setState({ open_order_dropdown: false, order_filter: '' });
                return;
            }
        }
    };

    byOrderOnChangeHandler(e) {
        this.setState({
            selected_order: e.target.value,
            orderAutoCompSpinner: true,
            order_list: [],
        });
        this.debounceFunc(e.target.value);
    }

    debounceFunc = debounce(500, (num) => {
        this.orderApiCall(num);
    }); // Bug 9217 : by order autocomplete

    async orderApiCall(text) {
        if (this.state.abortController) {
            this.state.abortController.abort();
        }

        const abortController = new AbortController();
        const { signal } = abortController;
        this.setState({ abortController });

        const currentValue = text.split('');

        if (currentValue.length <= 1) {
            await this.setState({
                selected_order: text,
                order_filter: text,
                open_order_dropdown: false,
            });
            return true;
        }
        await this.setState({
            selected_order: text,
            create_order: false,
            view_order: false,
            open_order_dropdown: false,
            order_filter: text,
            orderAutoCompSpinner: true,
        });
        let orderSearchText = '';
        let patientSearchText = '';
        if (this.state.orderFieldIdArray !== '') {
            await this.state.orderFieldIdArray.map((fieldData, index) => {
                if (fieldData.name === 'Order Number') {
                    orderSearchText += `fieldSearch=${fieldData.fieldId}%3D${text}%2A`;
                } else if (fieldData.name === 'Patient') {
                    patientSearchText += `&fieldSearch=${fieldData.fieldId}%3D${text}%2A`;
                }
                return fieldData;
            });
        }

        await this.setState({ order_list: [] }, async () => {});
        await this.setState({ open_order_dropdown: true });

        try {
            const response = [];
            await response.push(
                orderRecordSetSearch(
                    this.state.orderRecordsetId,
                    patientSearchText,
                    { signal }
                )
            );
            await response.push(
                orderRecordSetSearch(
                    this.state.orderRecordsetId,
                    orderSearchText,
                    { signal }
                )
            );

            const tempOrderList = [];
            await Promise.all(response).then(async (data) => {
                await data.map(async (result) => {
                    tempOrderList.push(...OrderWithUnderScore(result));
                });
            });
            this.setState({
                order_list: tempOrderList,
                orderAutoCompSpinner: false,
                abortController: null,
            });
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Previous request aborted');
            }
        }
    }

    order_list = () => {
        if (this.state.order_list.length > 0) {
            return this.state.order_list.map((search, index) => {
                const req = `${this.state.selected_order} `; // autocomplete value

                let res = `${search.order_number}`;

                if (req) {
                    const normReq = req
                        .toLowerCase()
                        .replace(/\s+/g, ' ')
                        .trim()
                        .split(' ')
                        .sort((a, b) => b.length - a.length);
                    res = res.replace(
                        new RegExp(`(${normReq.join('|')})`, 'gi'),
                        (match) => `<b className='highlight'>${match}</b>`
                    );
                }
                const custom_order = res;

                res = `${search.patient}`;
                if (req) {
                    const normReq = req
                        .toLowerCase()
                        .replace(/\s+/g, ' ')
                        .trim()
                        .split(' ')
                        .sort((a, b) => b.length - a.length);
                    res = res.replace(
                        new RegExp(`(${normReq.join('|')})`, 'gi'),
                        (match) => `<b className='highlight'>${match}</b>`
                    );
                }
                const patient_name = res;

                return (
                    <li
                        key={index}
                        onClick={() => {
                            this.open_order_search(search, 'Autocomplete');
                        }}
                    >
                        <div className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <div className='patient_no_name'>
                                    <span
                                        className='first_name'
                                        dangerouslySetInnerHTML={{
                                            __html: custom_order,
                                        }}
                                    />
                                    <span>{search.branch}</span>
                                    <span
                                        className='first_name'
                                        dangerouslySetInnerHTML={{
                                            __html: patient_name,
                                        }}
                                    />
                                </div>

                                <div className='patient_status'>
                                    <span>Episode Status :</span>
                                    <span className='status'>
                                        {search.episode_status}
                                    </span>
                                </div>
                            </div>
                            <div className='filter-date'>
                                <span>{search.formattedorderdate}</span>
                                <div className='patient_status'>
                                    <span className='status'>
                                        {search.attachmenttype !== '0'
                                            ? search.attachment_type
                                            : ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            });
        } else {
            return <div style={{ textAlign: 'center' }}>No Records Found</div>;
        }
    };

    open_order_search = async (search, name) => {
        if (search.id > 0 || search.id === 0) {
            this.setState({
                orderIsNegative: false,
            });
        } else {
            this.setState({
                orderIsNegative: true,
            });
        }
        const form_data = {
            ...this.state.formData,
            ...search,
            /* ...{
                attachment_location: search.attachment_location
                    ? search.attachment_location
                    : search.attachmentlocation
                    ? search.attachmentlocation
                    : '',
                attachment_type: search.attachment_type
                    ? search.attachment_type
                    : '',
                episodeid: search.episodeid ? search.episodeid : '',
            }, */
        };

        if (name === 'Autocomplete') {
            await this.props.attachmentLocationApi(search.attachment_type);
        }
        search = {
            ...search,
            attachment_location: search.attachment_location
                ? search.attachment_location
                : search.attachmentlocation
                ? search.attachmentlocation // this attachmentlocation is temporarily using attachment location is crt one
                : '',
        };
        const state = {
            open_order_dropdown: false,
            patient_filter: search,
            open_patient_dropdown: false,
            view_patient: false,
            selected_patient_id: search.patientid,
            create_patient: false,
            edit_patient: false,
            formData: form_data,
        };
        await this.setState(state, () => {
            this.props.updateState(state);
        });
        if (
            this.props.uploadPatientLink === true &&
            this.props.newStateCalling === 'upload'
        ) {
            this.props.PatientLink_Function(false);
        }
        if (this.props.patientLink === true) {
            this.props.updateState({
                patientLink: false,
            });
        }
        await this.setState({ view_patient: true }, () => {
            this.props.updateState({ view_patient: true });
        });
        let selected_order = '';
        if (
            this.state.patient_filter.patient !== undefined &&
            this.state.patient_filter.patient !== '' &&
            this.state.patient_filter.order_number !== '' &&
            this.state.patient_filter.order_number !== undefined
        ) {
            selected_order = `${this.state.patient_filter.patient}.(#${this.state.patient_filter.order_number})`;
        }
        this.setState({ selected_order: selected_order });
    };

    edit_order = () => {
        this.setState({ edit_patient: true }, () => {
            this.props.updateState({ edit_patient: true });
        });
    };

    openCreateNewPatient = () => {
        this.props.updateState({ openCreatePopup: false });
        this.props.updateState({ formFor: 'order' });
        const state = {
            open_create_patient: true,
            edit_patient: false,
            view_patient: false,
            open_patient_dropdown: false,
            open_order_dropdown: false,
            // create_patient: true,
            openCreatePopup: true,
            formFor: 'order',
        };
        this.setState(state, () => {
            this.props.updateState(state);
        });
    };

    createPatient = () => {
        const state = {
            currentNewReferrel: 'Yes',
        };
        this.setState(state, () => {
            this.props.updateState(state);
        });
        this.props.openNewPatient();
    };

    submitEditOrder = () => {
        this.props.submitEditPatient();
        this.setState({ submitEditPatient: true }, () => {
            this.props.updateState({ submitEditPatient: true });
        });
    };

    clearInputBox = () => {
        this.setState({
            selected_order: '',
        });
    };

    render() {
        return (
            <>
                <div className='col-lg-8 detail_search'>
                    <label className='pl-5'>LOOKUP</label>
                    <div className='d-flex'>
                        <div className='filter'>
                            <input
                                className={
                                    this.props.uploadPatientLink ||
                                    this.props.patientLink
                                        ? 'form-control error-field'
                                        : 'form-control'
                                }
                                value={this.state.selected_order}
                                placeholder='Type to search by order'
                                // type='number'
                                type='text'
                                // onKeyDown={(evt) =>
                                //     evt.key === 'e' && evt.preventDefault()
                                // }
                                onChange={this.byOrderOnChangeHandler.bind(
                                    this
                                )}
                                // style={{fontWeight:"bold"}}
                            />
                            {this.state.selected_order !== '' &&
                            this.state.selected_order.length > 0 ? (
                                <i
                                    className='fa-regular fa-xmark'
                                    onClick={this.clearInputBox}
                                />
                            ) : (
                                <i className='fa-regular fa-magnifying-glass' />
                            )}
                            {this.state.open_order_dropdown ? (
                                <div
                                    className='patient_dropdown'
                                    style={{
                                        height:
                                            this.state.order_list.length > 0
                                                ? '205px'
                                                : '80px',
                                    }}
                                >
                                    <ul
                                        style={{
                                            listStyleType: 'none',
                                            height:
                                                this.state.order_list.length > 0
                                                    ? '200px'
                                                    : '',
                                            overflowY: 'scroll',
                                        }}
                                    >
                                        {this.state.orderAutoCompSpinner ? (
                                            <div
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Spinner
                                                    animation='grow'
                                                    variant='primary'
                                                />
                                            </div>
                                        ) : (
                                            this.order_list()
                                        )}
                                    </ul>
                                    {/*(this.props.rulesJson.hasOwnProperty(
                                        'IndexingSettings'
                                    ) &&
                                        this.props.rulesJson.IndexingSettings
                                            .standaloneused) ||
                                    (this.state.isUploadPage &&
                                        this.props.rulesJson.hasOwnProperty(
                                            'IndexingSettings'
                                        ) &&
                                        this.props.rulesJson.IndexingSettings
                                            .standaloneused) ? (
                                        <div
                                            className='adding_patient'
                                            onClick={() => {
                                                this.openCreateNewPatient();
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className='plus'>
                                                <span>
                                                    <i
                                                        className='fa-regular fa-plus'
                                                        aria-hidden='true'
                                                    />
                                                </span>
                                            </div>
                                            Create New Patient
                                        </div>
                                        ) : null*/}
                                </div>
                            ) : null}
                        </div>

                        {this.state.view_patient &&
                        this.state.edit_patient === false &&
                        this.state.orderIsNegative === false &&
                        this.state.modifyPatientModifiableRecordsetId !==
                            null ? (
                            //||need to check why patient id alone check and showing edit icon visble ,this codition show for al order visible
                            //this.props.selectedPatient.patient_id !== undefined
                            <div className='plus'>
                                <span
                                    className='edit_btn'
                                    onClick={this.edit_order}
                                >
                                    <i className='fas fa-pencil-alt' />
                                </span>
                            </div>
                        ) : null}
                        {this.state.edit_patient === true ? (
                            <div
                                className='plus'
                                id='submitEditPatient'
                                onClick={this.submitEditOrder}
                            >
                                <span>
                                    <i className='fas fa-check-square' />
                                </span>
                            </div>
                        ) : null}

                        {/* this.state.view_patient &&
                        this.state.edit_patient === false &&
                        this.state.orderIsNegative === false &&
                        this.state.modifyPatientModifiableRecordsetId !== null

                             ? (
                            <div className='plus'>
                                <span
                                    className='edit_btn'
                                    onClick={this.edit_order}
                                >
                                    <i className='fas fa-pencil-alt'></i>
                                </span>
                            </div>
                             ) : null}

                        {this.state.edit_patient === true ? (
                            <div
                                className='plus'
                                id='submitEditPatient'
                                onClick={this.submitEditOrder}
                            >
                                <span>
                                    <i className='fas fa-check-square'></i>
                                </span>
                            </div>
                        ) : null */}

                        {this.state.edit_order === true ? (
                            <div
                                className='plus'
                                id='submitEditOrder'
                                onClick={() => {
                                    this.setState(
                                        { submitEditOrder: true },
                                        () => {
                                            this.props.updateState({
                                                submitEditOrder: true,
                                            });
                                        }
                                    );
                                }}
                            >
                                <span>
                                    <i className='fas fa-check-square' />
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ByOrder));
