import { FC, useState } from 'react';
import DataGrid from 'react-data-grid';
import { NoRecordFound, showErrorToast, showSuccessToast } from '../../../components/commonfunction';
import AddReviewersModal from './AddReviewersModal';
import { assignSignature, unassignSignature } from '../../api/api';
import DeleteModal from './DeleteModal';

interface SignatureSectionProps {
    entityId: string;
    columnsArray: any[];
    rows: any[];
    title: string;
    onAdd: (assignedId: string, objectId: string) => void;
    onUnassign: (assignedId: string) => void;
    loading: boolean;
    availableItems: any[];
    reviewersConfig: any;
}

const SignatureSection: FC<SignatureSectionProps> = ({ entityId, columnsArray, rows, title, onAdd, onUnassign, loading, reviewersConfig, availableItems = [] }) => {
    const [toggleRecentSignature, setToggleRecentSignature] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState<Set<any>>(new Set());
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const onAssignReviewers = async (assigned: any) => {
        const sourceEntityId = reviewersConfig?.headerColumns?.find((column: any) => (column.name === 'Source Entity ID' || column.name === "Source Post Signature Entity ID"))?.fieldId;
        const linkedEntityId = reviewersConfig?.headerColumns?.find((column: any) => (column.name === 'Linked Entity ID' || column.name === "Linked Post Signature Entity ID"))?.fieldId;
        const recordSetId = reviewersConfig?.recordSetId;
        const assignedId = assigned[0];

        if (!sourceEntityId || !linkedEntityId || !recordSetId) {
            showErrorToast('Invalid reviewers config');
            return;
        }

        const params = [{ fieldId: sourceEntityId, value: entityId }, { fieldId: linkedEntityId, value: assignedId }];
        const response = await assignSignature(params, reviewersConfig?.recordSetId);
        if (!!response?.uniqueId) {
            showSuccessToast('Reviewer assigned successfully');
            handleCloseModal();
            onAdd(assignedId, response.uniqueId);
        } else {
            showErrorToast('Failed to assign reviewer');
        }
    };

    const onUnassignReviewer = async () => {
        if (selectedRows.size === 0) {
            showErrorToast('Please select a reviewer to unassign');
            return;
        }
        const sourceEntityId = reviewersConfig?.headerColumns?.find((column: any) => (column.name === 'Source Entity ID' || column.name === "Source Post Signature Entity ID"))?.fieldId;
        const linkedEntityId = reviewersConfig?.headerColumns?.find((column: any) => (column.name === 'Linked Entity ID' || column.name === "Linked Post Signature Entity ID"))?.fieldId;
        const recordSetId = reviewersConfig?.recordSetId;

        if (!sourceEntityId || !linkedEntityId || !recordSetId) {
            showErrorToast('Invalid reviewers config');
            return;
        }

        const assignedId = Array.from(selectedRows)[0];

        const response = await unassignSignature(reviewersConfig?.recordSetId, assignedId);

        if (response?.ok) {
            showSuccessToast('Reviewer unassigned successfully');
            handleCloseModal();
            onUnassign(assignedId);
        } else {
            showErrorToast('Failed to unassign reviewer');
        }
    };

    const handleRowClick = (row: any) => {
        setSelectedRows(new Set([row.id]));
    };

    const rowClass = (row: any) => {
        return selectedRows.has(row.id) ? 'row-selected-once' : '';
    };

    return (
        <div className='realted_document'>
            <div className={'with-bg-recent header_row d-flex align-items-stretch justify-content-between'}>
                <div className='header_title'>
                    <i className='fa-regular' aria-hidden='true' />
                    {title}
                </div>
                <div className='dropdown_icon' onClick={() => setToggleRecentSignature(!toggleRecentSignature)}>
                    <i title={'Delete'} className={'fas fa-trash-alt mr-3'} aria-hidden='true' onClick={() => setShowDeleteModal(true)} />
                    <i title={'Add'} className={'fa-regular fa-circle-plus'} aria-hidden='true' onClick={() => setShowModal(true)} />
                </div>
            </div>

            {loading ? (
                <div className={'open-recent-doc'}>
                    <div className='d-flex justify-content-center'>
                        <div className='spinner-border' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='no-first-col'>
                    <div className='grid_table' style={{ borderRight: '50px', minHeight: '100px', height: "150px" }}>
                        <DataGrid
                            columns={columnsArray}
                            rows={rows}
                            emptyRowsRenderer={NoRecordFound}
                            style={{ borderRight: '50px', height: '100%' }}
                            headerRowHeight={40}
                            rowHeight={40}
                            className='related-doc-grid'
                            rowKeyGetter={(row) => row.id}
                            onRowClick={handleRowClick}
                            rowClass={rowClass}
                        />
                    </div>
                </div>
            )}

            {showModal && (
                <AddReviewersModal
                    columnsArray={columnsArray}
                    rows={availableItems}
                    title={title}
                    onClose={handleCloseModal}
                    onAssignReviewers={onAssignReviewers}
                />
            )}

            <DeleteModal
                deleteModel={showDeleteModal}
                setDeleteModel={setShowDeleteModal}
                onDelete={onUnassignReviewer}
                title='Delete a Signature'
                warningText='You are deleting reviewers link. Are you sure
                        you want to proceed?'
            />
        </div>
    );
};

export default SignatureSection;
