import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    CONFIG_DATA,
    ACTION_ARRAY,
    GET_DDL_LIST,
} from '../actionTypes/configTypes';

const initialState = {
    configData: 'Abu',
    finalDDL: '',
    actionArray: [],
};

export const configReducer = (state = initialState, action) => {
    switch (action.type) {
        // MENU COUNT
        case CONFIG_DATA: {
            const { configData } = action;
            return { configData, testing: 'Abu' };
        }

        case ACTION_ARRAY: {
            const { actionArray } = action;
            return { actionArray };
        }

        default:
            return state;
    }
};

const initialStateDDL = {
    finalDDL: '',
    DDL: {},
};
var DDL = {};
export const persistDDL = (state = initialStateDDL, action) => {
    switch (action.type) {
        case GET_DDL_LIST: {
            const drop = action.DDL;
            DDL = { ...state.DDL, ...drop };
            return { DDL };
        }

        default:
            return state;
    }
};
