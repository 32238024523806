/* eslint-disable array-callback-return */
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import DataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { saveAs } from 'save-as';
import TablePagination from '@material-ui/core/TablePagination';
import { MultipleSelection } from './MultipleSelection';
import { ImagePreviewTooltip } from './ImagePreviewTooltip';
import {
    getRelatedDocumentList,
    downloadDoc,
    configurationCustomeSearchId,
} from '../../api/api';
import { Notes } from './Notes';
import { SelectColumn } from './RelatedDocColumn';
import {
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    GetFieldData,
    NoRecordFound,
    getNotes,
} from '../../../components/commonfunction';
import { outboxPage, showLoader } from '../../_redux/actions/outboxActions';
import { deletedPage } from '../../_redux/actions/searchActions';
import Office365AuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
import GmailAuthenticationPage from '../../../components/composeDraftMail/GmailAuthenticationPage';
import { DocumentIdToPrint } from '../../../../utils/printPdf/printPdf';
const _ = require('lodash');

class RelatedDocument extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            toggleRecentDoc: false,
            selectedDoc: [],
            openNote: false,
            docIds: [],
            relatedDocColumns: [],
            relatedDocRows: [],
            selectedRows: new Set(),
            selectedDocIds: [],
            printDoc: [],
            currentPage: 0,
            perPage: 10,
            byOrderRowId: '',
            byBatchRowId: '',
            activeTab: '',
            filterTxt: '',
            refreshDataGrid: '',
            filteredData: [],
            openPopover: false,
            noteTypes: [],
            onHide: true,
            refreshTab: true,
            onRowClickId: this.props.selectedRowDetails.hasOwnProperty(
                'miscPproperties'
            )
                ? this.props.selectedRowDetails.miscPproperties.docId
                : '',
            serviceType: false,
        };
    }
    configurationCustomeSearchId = (id) => {
        configurationCustomeSearchId(id)
            .then(async (data) => {
                localStorage.setItem('configData', JSON.stringify(data));
                if (this.props.getConfigData !== undefined) {
                    await this.props.getConfigData(data);
                    // this.props.getConfigData()
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    };
    componentWillUnmount() {
        // this.props.deletedPage({
        //     onlySearch_outbox: false,
        // });
        this._isMounted = false;
    }
    async componentDidMount() {
        const customSearchNamedIdsNeedingConfiguration = this.props
            .configReducerPersist.customSearchNamedIdsNeedingConfiguration
            ? this.props.configReducerPersist
                  .customSearchNamedIdsNeedingConfiguration
            : [];
        if (customSearchNamedIdsNeedingConfiguration.length !== undefined) {
            await customSearchNamedIdsNeedingConfiguration.map(async (item) => {
                if (item.name === 'By Order' || item.name === 'By Batch') {
                    await this.configurationCustomeSearchId(item.id);
                }
            });
        }
        const serviceType =
            this.props.configData?.emailIntegration?.serviceType ===
                'Office 365' ||
            this.props.configData?.emailIntegration?.serviceType === 'Google'
                ? true
                : false;
        this.setState({ serviceType }); // if service type is "Exchange" will implement later
        this._isMounted = true;
        if (this.props.selectedRowDetails.hasOwnProperty('fields')) {
            const rowDetails = this.props.selectedRowDetails.fields;
            if (this._isMounted) {
                const fieldDataObj = GetFieldData({
                    configData: this.props.configData,
                });
                // var onRowClickId = this.props.selectedRowDetails.docId;
                // this.setState({ onRowClickId: onRowClickId });
                rowDetails.map((data) => {
                    if (data.fieldId === fieldDataObj.batch) {
                        this.setState({ byBatchRowId: data.value });
                    }
                    if (data.fieldId === fieldDataObj.order_number) {
                        this.setState({ byOrderRowId: data.value });
                    }
                });
            }
        }
        let customSearchQueries = [];
        customSearchQueries = this.props.customSearchQueries;
        await customSearchQueries.map((data) => {
            if (this._isMounted) {
                if (formatNameWithSpaceToUnderscore(data.name) === 'by_batch') {
                    this.setState({ byBatchSearchId: data.searchId });
                }
                if (formatNameWithSpaceToUnderscore(data.name) === 'by_order') {
                    this.setState({ byOrderSearchId: data.searchId });
                }
            }
        });
        // if (this.state.byOrderRowId !== '') {
        //     this.getByOrderData();
        // } else if (this.state.byBatchRowId !== '') {
        //     this.getByBatchData(true);
        // }
        // if (this.state.byOrderRowId !== '' && this.state.byBatchRowId !== '') {
        //     this.getByBatchData('');
        // }
        let processId;
        let statusId;
        if (
            this.props.deleted_Page.processId !== undefined &&
            String(this.props.location.pathname).includes('search') === true
        ) {
            processId = this.props.deleted_Page.processId;
            statusId = this.props.deleted_Page.statusId;
        } else {
            processId = this.props.outbox.processId;
            statusId = this.props.outbox.statusId;
        }
        let noteTypes = getNotes(this.props.configData, processId, statusId);
        this.setState({
            noteTypes: noteTypes,
        });
        if (this.state.byBatchRowId !== '' && this.state.byOrderRowId !== '') {
            await this.getByOrderData(true);
            await this.getByBatchData(true);
            await this.getByBatchData(true);
            await this.setState({
                defaultByorder: 1,
                activeTab: 'batch',
                refreshDataGrid: false,
            });
            setTimeout(() => {
                this.setState({
                    onHide: false,
                    activeTab: 'batch',
                });
            }, 3000);
        } else if (
            this.state.byBatchRowId !== '' &&
            this.state.byOrderRowId === ''
        ) {
            await this.getByBatchData(true);
            this.setState({
                defaultByorder: 1,
                activeTab: 'batch',
                onHide: false,
            });
        } else if (
            this.state.byBatchRowId === '' &&
            this.state.byOrderRowId !== ''
        ) {
            await this.getByOrderData(true);
            this.setState({
                defaultByorder: 0,
                activeTab: 'order',
                onHide: false,
            });
        } else {
            this.setState({
                refreshDataGrid: '',
                onHide: false,
            });
        }
    }

    getByOrderData = () => {
        this.setState({
            refreshDataGrid: false,
            defaultByorder: 2,
        });
        this.resetDataGrid();
        const fieldId = GetFieldData({
            configData: this.props.configData,
        });
        let byOrderSearchText = '';
        // byOrderSearchText ='fieldSearch=' + fieldId.order_number + '%3D340129';
        byOrderSearchText = `fieldSearch=${fieldId.order_number}%3D${this.state.byOrderRowId}`;
        getRelatedDocumentList(this.state.byOrderSearchId, byOrderSearchText)
            .then((response) => {
                if (this._isMounted) {
                    return response.json();
                }
            })
            .then((data) => {
                if (
                    data !== undefined &&
                    data.dataTable !== '' &&
                    data.dataTable !== undefined &&
                    this._isMounted
                ) {
                    const length = data.dataTable.dataRows.length;
                    this.setState({ activeTab: 'order' });
                    this.setState({
                        byOrderCount: length,
                        totalCount: length,
                        //refreshTab: false,
                    });
                    setTimeout(() => {
                        //this.setState({ refreshTab: true });
                    }, 1000);
                    this.getRelatedDocData(data.dataTable, 'byorder');
                }
            });
    };

    resetDataGrid = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    getByBatchData = (change_data) => {
        this.setState({
            refreshDataGrid: false,
            defaultByorder: 2,
        });
        this.resetDataGrid();
        const fieldId = GetFieldData({
            configData: this.props.configData,
        });
        //  byBatchSearchText = 'fieldSearch=' + fieldId.batch + '%3D150';
        const byBatchSearchText = `fieldSearch=${fieldId.batch}%3D${this.state.byBatchRowId}`;
        getRelatedDocumentList(this.state.byBatchSearchId, byBatchSearchText)
            .then((response) => {
                if (this._isMounted) {
                    return response.json();
                }
            })
            .then((data) => {
                if (
                    data !== undefined &&
                    data.dataTable !== undefined &&
                    this._isMounted
                ) {
                    let length = 0;
                    if ('dataRows' in data.dataTable) {
                        length = data.dataTable.dataRows.length;
                    }
                    this.setState({ byBatchCount: length });
                    if (change_data === true) {
                        this.setState({ activeTab: 'batch' });
                        this.setState({
                            totalCount: length,
                            // refreshTab: false,
                        });
                        setTimeout(() => {
                            //this.setState({ refreshTab: true });
                        }, 1000);
                        this.getRelatedDocData(data.dataTable, 'bybatch');
                    }
                }
            });
    };

    handleClick = async () => {
        await this.setState({ openPopover: true });
    };

    actionMenu = (id) => {
        // const id = this.state.openPopover ? 'simple-popover-' : undefined;
        return (
            <div className='d-flex related_detail_icon align-items-center rel_action_menu'>
                {this.state.noteTypes.length !== 0 && (
                    <span
                        title='Note'
                        onClick={() => {
                            this.openNotes(id);
                        }}
                        // style={
                        //     this.props.callingFrom === 'related_document'
                        //         ? { cursor: 'not-allowed' }
                        //         : {}
                        // }
                    >
                        <i className='fa-solid fa-sticky-note' />
                    </span>
                )}

                <span
                    onClick={this.handleClick}
                    // style={
                    //     this.props.callingFrom === 'related_document'
                    //         ? { cursor: 'not-allowed' }
                    //         : {}
                    // }
                    className={`settingsmenu${id}`}
                    variant='success'
                    //  id={'settingsmenu' + id}
                    title='Settings'
                >
                    <i className='fa fa-ellipsis-v' aria-hidden='true' />
                </span>
            </div>
        );
    };

    imagePreview = (id, displayText) => {
        return <ImagePreviewTooltip docId={id} displayText={displayText} />;
    };

    getRelatedDocData = async (data, tab) => {
        this.setState({ relDocLoading: true });
        const rows = [];
        const lowerCase = [];
        // let uniqueData = {};
        let header = [];
        if (data !== undefined) {
            const { dataRows, headerColumns } = data;
            const actionArray = {
                name: 'Action',
                fieldId: '',
                datatype: 'AlphaNumeric',
            };
            await headerColumns.push(actionArray);
            await headerColumns.forEach((data, index) => {
                const headerObj = {
                    key: formatHeaderNameWithOutSpace(data.name),
                    name: data.name,
                    datatype: data.datatype,
                    fieldId: data.fieldId,
                    resizable: false,
                    sortable: false,
                    // width: 123,
                };
                header.push(headerObj);
            });
            header = [SelectColumn].concat(header);
            await dataRows.forEach((data, index) => {
                const lowercaseObj = {};
                const obj = {};
                obj.id = data.id;
                lowercaseObj.id = data.id;
                const array = data.values;
                array.splice(0, 0, '');
                array.push('');
                array.forEach((row, i) => {
                    if (header[i] !== undefined) {
                        obj[header[i].key] = row.trim();
                        lowercaseObj[header[i].key] = row.trim().toLowerCase();
                        switch (header[i].key) {
                            case 'action':
                                obj[header[i].key] = this.actionMenu(data.id);
                                break;
                            case 'emrordertype':
                                process.env.REACT_APP_ENV === 'dev'
                                    ? (obj[header[i].key] = `#${data.id}`)
                                    : row.trim(); // kalpana
                                break;
                            case 'documenttype':
                                obj[header[i].key] = this.imagePreview(
                                    data.id,
                                    row.trim()
                                );
                                lowercaseObj[header[i].key] = row
                                    .trim()
                                    .toLowerCase();
                                break;
                            default:
                                break;
                        }
                    }
                });
                lowerCase.push(lowercaseObj);

                if (data.id !== this.props.selectedDocId) {
                    // kalpana worked to suppress parent doc id from RD listing
                    rows.push(obj);
                }
                if (tab === 'byorder') {
                    this.setState({ byOrderCount: rows.length });
                } else {
                    this.setState({ byBatchCount: rows.length });
                }
                this.setState({ totalCount: rows.length });
            });
        }

        this.setState(
            {
                relDocLoading: false,
                relatedDocColumns: header,
                relatedDocRows: rows,
                allRows: rows,
                allRowsLoweCase: lowerCase,
            },
            () => {
                let paginatedRowData = this.state.relatedDocRows;
                paginatedRowData = _.slice(paginatedRowData, 0, 10);

                this.setState({
                    refreshDataGrid: true,
                    relatedDocRows: paginatedRowData,
                });
            }
        );
    };

    openNotes = (docId) => {
        this.setState({
            openNote: true,
            docIds: [docId],
        });
    };
    //(prevState, nextState)
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedDocId !== this.props.selectedDocId) {
            this.setState({
                printSelectedDocId: [],
                toggleRecentDoc: false,
                selectedDoc: [],
                openNote: false,
                docIds: [],
                notesSubmitted: 0,
            });
        }
        if (
            'printDoc' in prevState &&
            prevState.printDoc !== this.state.printDoc
        ) {
            if (this.state.printDoc.length > 0) {
                const dp = DocumentIdToPrint(this.state.printDoc);
                dp.then(() => {
                    this.props.showLoader(false);
                }).catch((error) => {
                    this.props.showLoader(false);
                });
            }
        }
    }

    closeMultiSelect = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    onRowClick = (rowDetails, rowHeader) => {
        if (String(rowHeader.key) === 'action') {
            this.setState(
                {
                    selectedDocIds: rowDetails.id,
                },
                async () => {
                    const actionButton = document.querySelectorAll(
                        `.settingsmenu${this.state.selectedDocIds}`
                    );

                    const elementPosition =
                        actionButton[0].getBoundingClientRect(); // Get PopOver Position
                    const position = {
                        top: parseInt(elementPosition.top + 30, 10),
                        left: parseInt(elementPosition.x, 10),
                    };
                    await this.setState({ popOverPosition: position });
                }
            );
        }
        if (this.props.callingFrom === 'related_document') {
            return true;
        }
        if (
            String(rowHeader.key) === 'documenttype' ||
            String(rowHeader.key) === 'branch' ||
            String(rowHeader.key) === 'documentname'
        ) {
            this.setState(
                {
                    selectedDocIds: rowDetails.id,
                },
                () => {
                    if (this.props.myParent === 'bigview') {
                        this.props.outboxPage({ related_doc_from: 'bigview' });
                    }

                    var currentPath = window.location.pathname.split('/');
                    if (currentPath[1] !== 'search') {
                        this.props.outboxPage({ related_doc_detail: true });
                        this.props.history.push({
                            pathname: '/outbox/detail',
                            // window.btoa(String(this.state.selectedDocIds)),
                            search: `?detail=${window.btoa(
                                String(this.state.selectedDocIds)
                            )}`,
                            state: {
                                selectedDocID: window.btoa(
                                    String(this.state.selectedDocIds)
                                ),
                                processId: this.props.outbox.processId,
                                statusId: this.props.outbox.processId,
                            },
                        });
                    } else {
                        this.props.history.push({
                            pathname: '/' + currentPath[1] + '/detail',
                            // '/detail/' +
                            // window.btoa(String(this.state.selectedDocIds)),
                            search:
                                '?detail=' +
                                window.btoa(String(this.state.selectedDocIds)),
                            state: {
                                selectedDocID: window.btoa(
                                    String(this.state.selectedDocIds)
                                ),
                                processId: this.props.deleted_Page.processId,
                                statusId: this.props.deleted_Page.statusId,
                            },
                        });
                        this.props.deletedPage({
                            onlySearch_outbox: true,
                        });
                    }
                }
            );
        }
    };

    popoverHandleClose = () => {
        this.setState({
            openPopover: false,
        });
    };

    print = () => {
        const array = [];
        array.push(this.state.selectedDocIds);

        this.props.showLoader(true);
        this.setState({
            printDoc: array,
        });
    };

    download = () => {
        if (this.state.selectedDocIds === '') {
            return false;
        }
        downloadDoc(this.state.selectedDocIds)
            .then((response) => {
                return response.blob();
            })
            .then((data) => {
                saveAs(data, 'Document.pdf');
            });
    };

    nextPage(event, newPage) {
        const startIndex =
            parseInt(newPage, 10) * parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        let paginatedRowData;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }

        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            relatedDocRows: paginatedRowData,
            currentPage: newPage,
        });
    }

    handleChangeRowsPerPage(event) {
        const startIndex = 0;
        const endIndex = parseInt(event.target.value, 10);
        let paginatedRowData = this.state.allRows;

        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            relatedDocRows: paginatedRowData,
            perPage: endIndex,
            currentPage: 0,
        });
    }

    sendDraft = async () => {
        let serviceType = this.props.configData?.emailIntegration?.serviceType;

        if (serviceType === 'Office 365') {
            // check to authenticate draft mail
            Office365AuthenticationPage({
                selectedDocID: this.state.selectedDocIds,
                configData: this.props.configData,
            });
        } else {
            GmailAuthenticationPage({
                selectedDocID: this.state.selectedDocIds,
                configData: this.props.configData,
            });
        }
    };

    render() {
        const id = this.state.openPopover ? 'simple-popover-' : undefined;
        const toggleRecentDoc = () => {
            this.setState({
                toggleRecentDoc: !this.state.toggleRecentDoc,
            });
        };

        const handleReset = () => {
            this.setState({
                openNote: false,
            });
        };

        const temps = [
            this.state.onRowClickId,
            ...Array.from(this.state.selectedRows),
        ];
        const multiSelectId = [...new Set(temps)];
        let activeTab = this.state.activeTab;
        setTimeout(() => {
            activeTab = this.state.activeTab;
        }, 1000);

        return (
            <>
                <div
                    className={
                        this.props.isLocked
                            ? 'realted_document locked-screen'
                            : 'realted_document'
                    }
                >
                    <div
                        className={
                            this.state.toggleRecentDoc
                                ? 'with-bg-recent header_row d-flex align-items-stretch justify-content-between'
                                : 'header_row d-flex align-items-stretch justify-content-between no-bg-recent'
                        }
                        onClick={() =>
                            this.props.isLocked === true
                                ? null
                                : toggleRecentDoc()
                        }
                    >
                        <div className='header_title'>
                            <i className='fa fa-link' aria-hidden='true' />
                            Related Documents
                        </div>
                        <div
                            className='dropdown_icon'
                            onClick={() =>
                                this.props.isLocked === true
                                    ? null
                                    : toggleRecentDoc()
                            }
                        >
                            <i
                                title={
                                    this.state.toggleRecentDoc
                                        ? 'Close'
                                        : 'Open'
                                }
                                className={
                                    this.state.toggleRecentDoc
                                        ? 'fa fa-chevron-up'
                                        : 'fa fa-chevron-down'
                                }
                                aria-hidden='true'
                            />
                        </div>
                    </div>
                    {this.state.onHide === false ? (
                        <>
                            {this.props.selectedDocId !== undefined && (
                                <div
                                    className={
                                        this.state.toggleRecentDoc
                                            ? 'open-recent-doc'
                                            : 'close-recent-doc'
                                    }
                                >
                                    <div className='grid_table'>
                                        {this.state.refreshDataGrid === '' ? (
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                No Documents Found
                                            </div>
                                        ) : null}
                                        <div className='realted_content d-flex align-items-stretch justify-content-between'>
                                            <div className='tab'>
                                                {this.state.refreshTab ? (
                                                    <Nav
                                                        variant='pills'
                                                        defaultActiveKey={
                                                            activeTab
                                                        }
                                                    >
                                                        {this.state
                                                            .byBatchRowId !==
                                                        '' ? (
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    title='Workflow Queues'
                                                                    eventKey='batch'
                                                                    onClick={
                                                                        this
                                                                            .state
                                                                            .byOrderRowId !==
                                                                        ''
                                                                            ? () => {
                                                                                  this.getByBatchData(
                                                                                      true
                                                                                  );
                                                                              }
                                                                            : null
                                                                    }
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .defaultByorder ===
                                                                        1
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                >
                                                                    By Batch{' '}
                                                                    <span className='count'>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .byBatchCount
                                                                        }
                                                                    </span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ) : null}
                                                        {this.state
                                                            .byOrderRowId !==
                                                        '' ? (
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    title='Document'
                                                                    eventKey='order'
                                                                    onClick={() =>
                                                                        this
                                                                            .state
                                                                            .byBatchRowId !==
                                                                        ''
                                                                            ? this.getByOrderData(
                                                                                  true
                                                                              )
                                                                            : null
                                                                    }
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .defaultByorder ===
                                                                        0
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                >
                                                                    By Order{' '}
                                                                    <span className='count'>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .byOrderCount
                                                                        }
                                                                    </span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ) : null}
                                                    </Nav>
                                                ) : null}
                                            </div>
                                            {this.state.byOrderRowId !== '' &&
                                            this.state.activeTab === 'order' ? (
                                                <div className='d-flex align-items-center'>
                                                    <span>
                                                        Order #
                                                        {
                                                            this.state
                                                                .byOrderRowId
                                                        }
                                                    </span>
                                                </div>
                                            ) : null}
                                            {this.state.byBatchRowId !== '' &&
                                            this.state.activeTab === 'batch' ? (
                                                <div className='d-flex align-items-center'>
                                                    <span>
                                                        Batch #
                                                        {
                                                            this.state
                                                                .byBatchRowId
                                                        }
                                                    </span>
                                                </div>
                                            ) : null}
                                            {this.state.refreshDataGrid ===
                                                false && (
                                                <div className='d-flex justify-content-center'>
                                                    <div
                                                        className='spinner-border'
                                                        role='status'
                                                    >
                                                        <span className='sr-only'>
                                                            Loading...
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {this.state.refreshDataGrid ? (
                                            <>
                                                <DataGrid
                                                    columns={
                                                        this.state
                                                            .relatedDocColumns
                                                    }
                                                    rows={
                                                        this.state
                                                            .relatedDocRows
                                                    }
                                                    selectedRows={
                                                        this.state.selectedRows
                                                    }
                                                    onSelectedRowsChange={(
                                                        rows
                                                    ) => {
                                                        const selectedRows =
                                                            rows;
                                                        this.setState({
                                                            selectedRows:
                                                                selectedRows,
                                                            // selectedDocIds: selectedRows
                                                        });
                                                    }}
                                                    headerRowHeight={0}
                                                    rowHeight={52}
                                                    summaryRowHeight={20}
                                                    rowKeyGetter={(row) => {
                                                        return row.id;
                                                    }}
                                                    onRowClick={(
                                                        rowDetails,
                                                        rowHeader
                                                    ) => {
                                                        this.onRowClick(
                                                            rowDetails,
                                                            rowHeader
                                                        );
                                                    }}
                                                    emptyRowsRenderer={NoRecordFound.bind(
                                                        this,
                                                        this.state.relDocLoading
                                                    )}
                                                    className='related-doc-grid'
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            )}

                            {this.state.refreshDataGrid ? (
                                <div
                                    style={{
                                        display: this.state.toggleRecentDoc
                                            ? 'block'
                                            : 'none',
                                    }}
                                >
                                    <TablePagination
                                        component='div'
                                        count={this.state.totalCount}
                                        page={parseInt(
                                            this.state.currentPage,
                                            10
                                        )}
                                        rowsPerPage={parseInt(
                                            this.state.perPage,
                                            10
                                        )}
                                        onRowsPerPageChange={(event) =>
                                            this.handleChangeRowsPerPage(event)
                                        }
                                        onPageChange={this.nextPage.bind(this)}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <div
                                className={
                                    this.state.toggleRecentDoc
                                        ? 'open-recent-doc'
                                        : 'close-recent-doc'
                                }
                            >
                                <div className='d-flex justify-content-center'>
                                    <div
                                        className='spinner-border'
                                        role='status'
                                    >
                                        <span className='sr-only'>
                                            Loading...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <Notes
                    openNote={this.state.openNote}
                    docId={this.state.selectedDocIds}
                    handleReset={handleReset}
                    relatedDocuments={true}
                />

                {Array.from(this.state.selectedRows).length > 0 ? (
                    <MultipleSelection
                        onRowClickId={this.state.onRowClickId}
                        selectedDoc={Array.from(this.state.selectedRows)}
                        documentCount={`Current file + ${
                            Array.from(this.state.selectedRows).length
                        } related file selected`}
                        docIds={multiSelectId}
                        closefn={this.closeMultiSelect}
                        allActions={this.props.allActions}
                        propsCalingFrom={this.props.propsCalingFrom}
                        noteTypes={this.state.noteTypes}
                    />
                ) : null}
                <Popover
                    id={id}
                    open={this.state.openPopover}
                    anchorReference='anchorPosition'
                    anchorPosition={this.state.popOverPosition}
                    // anchorEl={anchorEl}
                    onClose={this.popoverHandleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }} component='div'>
                        <div className='rel-dropdown-menu'>
                            <div
                                title='Print'
                                onClick={() => {
                                    this.print();
                                    this.popoverHandleClose();
                                }}
                                className='rel-dropdown-item  print-dropdown'
                            >
                                <i className='fas fa-print' />
                                Print
                            </div>
                            <div
                                title='Download'
                                onClick={() => this.download()}
                                className='rel-dropdown-item'
                            >
                                <i className='fas fa-download' />
                                Download
                            </div>
                            {this.state.serviceType ? (
                                <div
                                    title='send via email'
                                    onClick={() => this.sendDraft()}
                                    className='rel-dropdown-item'
                                >
                                    <i className='fa-solid fa-envelope' />
                                    Send via email
                                </div>
                            ) : null}
                        </div>
                    </Typography>
                </Popover>
                {/* {this.state.printDoc.length > 0 ? (
                    <PrintPdfDocument docId={this.state.printDoc} />
                ) : null} */}
            </>
        );
    }
}

const mapDispatchToProps = { outboxPage, deletedPage, showLoader };
const mapStateToProps = (state, ownProps) => {
    return {
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        configData: state.configReducerPersist.configData,
        configReducerPersist: state.configReducerPersist,
        inbox: state.inbox,
        outbox: state.outbox,
        selectedRowDetails: state.inbox.selectedRowDetails,
        deleted_Page: state.search.deletedPage,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RelatedDocument));
