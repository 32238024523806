import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import Loader from 'react-loader-spinner';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { printPlugin } from '@react-pdf-viewer/print';
// Import styles
import '@react-pdf-viewer/print/lib/styles/index.css';
import { isMobile } from 'react-device-detect';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { toast } from 'react-toastify';
import { unlockDocument, downloadDoc } from '../../api/api';
// import { RenderSearchProps, Search } from '@react-pdf-viewer/search';
import { formatPathName } from '../../../components/commonfunction';
import print from 'print-js';
toast.configure();

const PdfPreview = forwardRef(function PdfPreview(props, ref) {
    // Create new plugin instance
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    const printPluginInstance = printPlugin();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [apiError, setApiError] = useState(false);
    const location = useLocation();

    useImperativeHandle(
        ref,
        () => {
            return {
                printPdf: () => {
                    props.loader(true);
                    if (pdfUrl == null) {
                        let documentPromise;
                        if (props.docId !== undefined) {
                            const docId = props.docId;
                            documentPromise = downloadDoc(docId);
                            if (documentPromise !== undefined) {
                                documentPromise
                                    .then((response) => {
                                        if (response.status === 400) {
                                            props.loader(false);
                                            return new Error('error');
                                        }
                                        if (response.status === 204) {
                                        }
                                        return response.blob();
                                    })
                                    .then((data) => {
                                        const url = URL.createObjectURL(data);
                                        print({
                                            onError: (e) => {
                                                console.log(e);
                                            },
                                            type: 'pdf',
                                            printable: url,
                                            onLoadingEnd: () => {
                                                props.loader(false);
                                            },
                                            onPrintDialogClose: () => {
                                                props.loader(false);
                                            },
                                        });
                                    })
                                    .catch((error) => {});
                            } else {
                                props.loader(false);
                            }
                        } else {
                            props.loader(false);
                        }
                    } else {
                        print({
                            onError: (e) => {
                                console.log(e);
                            },
                            type: 'pdf',
                            printable: pdfUrl,
                            onLoadingEnd: () => {
                                props.loader(false);
                            },
                            onPrintDialogClose: () => {
                                props.loader(false);
                            },
                        });
                    }
                },
            };
        },
        [pdfUrl, props.loader]
    );

    useEffect(() => {
        setPdfUrl(null);
        let documentPromise;
        if (props.docId !== undefined) {
            let pathname = location.pathname;
            pathname = formatPathName(pathname);
            localStorage.setItem(`locked-document-id-${pathname}`, props.docId);
            const docId = props.docId;
            documentPromise = downloadDoc(docId);
        }
        if (documentPromise !== undefined) {
            documentPromise
                .then((response) => {
                    if (response.status === 400) {
                        setApiError(true);
                        toast.error('No pdf to load', {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            showTransitionSuccess: false,
                        });
                        return new Error('error');
                    }
                    if (response.status === 204) {
                        setApiError(false);
                    }
                    return response.blob();
                })
                .then((data) => {
                    const url = URL.createObjectURL(data);

                    setPdfUrl(url);
                    document
                        .querySelector('.rateRecipe.btns-one-small')
                        .click();
                })
                .catch((error) => {});
        }

        return () => {
            if (props.docId !== undefined) {
                const docId = props.docId;

                unlockDocument(docId).then((data) => {});
            }
        };
        // changed by S1008, props added
    }, [location, props.selectedDoc, props.docId]);
    // }, [props.selectedDoc,location,props]);

    //let customZoom = 1;
    let customZoom = SpecialZoomLevel.PageFit;
    if (isMobile) {
        customZoom = SpecialZoomLevel.PageFit;
    }

    // const selectCheckBox = () => {
    //     setTimeout(() => {
    //         if (
    //             document.getElementsByClassName(
    //                 'rpv-search__popover-label-checkbox'
    //             )
    //         ) {
    //             const clist = document.getElementsByClassName(
    //                 'rpv-search__popover-label-checkbox'
    //             );
    //             for (let i = 0; i < clist.length; ++i) {
    //                 clist[i].checked = true;
    //             }
    //         }
    //     }, 1000);
    // };
    var pdfHeight = window.innerHeight - 328;
    // if (props.detailBigView === true || window.innerHeight < 700) {
    //     pdfHeight = window.innerHeight;
    // }
    pdfHeight = window.innerHeight;
    return (
        <>
            {pdfUrl !== null ? (
                <div
                    className='rpv-core__viewer'
                    style={{
                        display: 'flex',
                    }}
                >
                    <div className='toolbar-pdfpreview'>
                        <div
                            style={{
                                alignItems: 'center',
                                // backgroundColor: '#eeeeee',
                                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                padding: '4px',
                                // overflow:"scroll"
                            }}
                        >
                            <Toolbar>
                                {(props) => {
                                    const {
                                        CurrentPageInput,
                                        EnterFullScreen,
                                        GoToNextPage,
                                        GoToPreviousPage,
                                        NumberOfPages,
                                        ShowSearchPopover,
                                        Zoom,
                                        ZoomIn,
                                        ZoomOut,
                                        // Print,
                                    } = props;

                                    return (
                                        <>
                                            <div style={{ padding: '0px 2px' }}>
                                                <ShowSearchPopover />
                                            </div>

                                            <div style={{ padding: '0px 2px' }}>
                                                <GoToPreviousPage />
                                            </div>
                                            <div style={{ padding: '0px 2px' }}>
                                                <CurrentPageInput /> /{' '}
                                                <NumberOfPages />
                                            </div>
                                            <div style={{ padding: '0px 2px' }}>
                                                <GoToNextPage />
                                            </div>

                                            <div
                                                style={{
                                                    padding: '0px 2px',
                                                    marginLeft: 'auto',
                                                }}
                                            >
                                                <ZoomOut />
                                            </div>
                                            <div style={{ padding: '0px 2px' }}>
                                                <Zoom
                                                    levels={[0.4, 0.8, 1.2]}
                                                />
                                            </div>
                                            <div style={{ padding: '0px 2px' }}>
                                                <ZoomIn />
                                            </div>
                                            <div
                                                style={{
                                                    padding: '0px 2px',
                                                    marginLeft: 'auto',
                                                }}
                                            >
                                                <EnterFullScreen />
                                            </div>
                                        </>
                                    );
                                }}
                            </Toolbar>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                height: pdfHeight,
                            }}
                        >
                            <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js'>
                                <Viewer
                                    fileUrl={`${pdfUrl}`}
                                    plugins={[
                                        toolbarPluginInstance,
                                        printPluginInstance,
                                    ]}
                                    // defaultScale={customZoom} ( removed for image resize black issue )
                                />
                            </Worker>
                        </div>
                    </div>
                </div>
            ) : apiError === false ? (
                <div style={{ textAlign: 'center' }} className='pdf_loader'>
                    <Loader
                        type='Oval'
                        color='#007dbc'
                        height={40}
                        width={40}
                    />
                </div>
            ) : null}
        </>
    );
});
export default PdfPreview;
