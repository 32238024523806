/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toast } from 'react-toastify';
import {
    Dropdown,
    Modal,
    Button,
    Toast,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import * as moment from 'moment-timezone';
import {
    recordSetSearch,
    // GetDropdownData,
    // updateRecordSet,
    // updateDocument,
    getDocumentMetaWithDocId,
    // addDocumentTags,
    submitDetailApi,
    attachToChart,
    unlockDocument,
    PostMessageLogout,
    lockDocument,
} from '../../api/api';
import {
    PatientSearch,
    GetFieldData,
    // stringEmptyOrUndefined,
    OrderSearch,
    formatHeaderNameWithOutSpace,
    // getDropDownDataFromApi,
    formatDate,
    normalSuccessToast,
    userPermission,
    normalErrorToast,
    getNotes,
    sortFields,
    formatNameWithSpaceToUnderscore,
    EpisodeSearch,
    BranchIdSearch,
    checkRules,
    showErrorToast,
    RefreshCounts,
    clearUnMatchAttachmentType,
    getAttachmentData,
    encodeParameter,
} from '../../../components/commonfunction';
import EditDetails from './EditDetails';
import { SpinnerBlur } from './Spinner';
import TransitionComponent from './TransitionComponent';
import { inboxPage } from '../../_redux/actions/inboxActions';
import { deletedPage } from '../../_redux/actions/searchActions';
// import Nav from 'react-bootstrap/Nav';
import { DocumentHistroy } from './DocumentHistroy';
// import CreateNewPatient from '../../../components/patient/CreateNewPatient';
// import ViewEditPatient from './ViewEditPatient';
// import CreateNewOrder from './CreateNewOrder';
import ViewDetails from './ViewDetails';
import DetailMoreInformation from './DetailMoreInformation';
import PrintPdfDocument from './PrintPdfDocument';
import { Notes } from './Notes';
import { ViewAllNotes } from './ViewAllNotes';
import UpdateFaxDetails from './UpdateFaxDetails';
// import Spinner from 'react-bootstrap/Spinner';
import {
    getListaction,
    setSelectedRow,
    getRulesJson,
    showLoader,
    hideBigView,
    getSelectedRowDetails,
    editDetailPage,
    updateList,
    updateInboxState,
    getCountData, //This is used for RefreshCounts while passing props
} from '../../_redux/actions/inboxActions';
import { getDDLlist } from '../../_redux/actions/configActions';
// import { NavLink } from 'react-router-dom';
import RelatedDocument from './RelatedDocument';
import { getActions } from '../../../../_metronic/_helpers';
//import { updateSearchState } from '../_redux/actions/searchActions';

import Office365AuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
import GmailAuthenticationPage from '../../../components/composeDraftMail/Office365AuthenticationPage';
import { DocumentIdToPrint } from '../../../../utils/printPdf/printPdf';
import ExportMenu from './ExportMenu';

const _ = require('lodash');
toast.configure();
const toastElement = (
    closeFn,
    canShow = false,
    headerTxt = '',
    messageText = ''
) => {
    return (
        <Toast show={canShow} onClose={closeFn} delay={3000} autohide>
            <Toast.Header>{headerTxt}</Toast.Header>
            <Toast.Body>{messageText}</Toast.Body>
        </Toast>
    );
};
class DetailBigView extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            editDetailByOrderValidationError: new Map(),
            byPatientDynamicFormData: new Map(),
            open_transition_section: true,
            edit_transition: false,
            is_add_notes: '',
            notes_details: 1,
            notes_list: [],
            showToastMsg: false,
            printElement: undefined,
            openHistroy: false,
            toggleRecentDoc: false,
            detailBigView: false,
            create_patient: false,
            patient_list: [],
            order_list: [],
            patient_filter: '',
            order_filter: '',
            open_patient_dropdown: false,
            view_patient: false,
            edit_patient: false,
            create_order: false,
            by_patient: true,
            by_order: false,
            edit_details: '',
            printSelectedDocId: [],
            printLoader: false,
            viewAllNotes: true,
            editId: '',
            detailPage:
                this.props.detailView !== undefined
                    ? this.props.detailView
                    : false,
            rulesJson: {},
            attachmentTypeDDL: [],
            attachmentLocationDDL: [],
            newreferralDDL: [],
            edit_order: false,
            orderModifiableRecordsetId: '',
            patientModifiableRecordsetId: '',
            detailDDL: '',
            submitDetail: false,
            validateDetail: false,
            noteTypes: [],
            user_permission: {},
            submitCreatePatient: false,
            toggleDetail: false,
            permissionCustom: false,
            serviceType: false,
            patientCreated: false,
        };
        this.myRef = React.createRef();
    }

    set_opendeletepopup = (event) => {
        this.props.openDeletePopUp(event);
    };

    set_handlenotestext = (event) => {
        this.props.handleNotesText(event);
    };

    set_handlenotessubmit = async (notes, notesValue, notesDDL, docId) => {
        this.setState({ is_add_notes: 0 });
        await this.props.handleNotesSubmit(notes, notesValue, notesDDL, docId);
        window.scrollTo(0, document.body.scrollHeight);
    };

    open_transition_section = () => {
        this.setState({
            open_transition_section: true,
            open_details_section: false,
            open_notes_section: false,
        });
    };

    open_details_section = () => {
        this.setState({
            open_transition_section: false,
            open_details_section: true,
            open_notes_section: false,
        });
    };

    open_notes_section = () => {
        this.setState({
            open_transition_section: false,
            open_details_section: false,
            open_notes_section: true,
            is_add_notes: 1,
        });
    };

    doCollapse = () => {
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        const elements = document.querySelectorAll('.rdg-row');
        for (let i = 0; i < elements.length; i++) {
            // elements[i].classList.remove('row-selected-once');
        }

        this.setState({ row_transition: false });
        this.props.rowTransition();
    };

    doExpand = () => {
        document.body.classList.remove('doc-small-view');
        document.body.classList.add('doc-detail-view');
        this.props.openBigView();
        this.setState({ detailBigView: true });
        this.setState({ listingView: false });
        this.props.inboxPage({
            expandInbox: true,
        });
        // this.props.rowTransition();
    };

    doCollapseFromDetail = () => {
        document.body.classList.add('doc-small-view');
        document.body.classList.remove('doc-detail-view');
        this.setState({ detailBigView: false });
        this.setState({ listingView: true });
        this.setState({ row_transition: true });
    };

    viewAllNotes = async () => {
        await this.setState({ viewAllNotes: !this.state.viewAllNotes });
    };

    async componentDidMount() {
        const serviceType =
            this.props.configData?.emailIntegration?.serviceType ===
                'Office 365' ||
            this.props.configData?.emailIntegration?.serviceType === 'Google'
                ? true
                : false;
        this.setState({ serviceType }); // if service type is "Exchange" will implement later
        this._isMounted = true;
        let permission;

        if (
            this.props.selectedDetailsOfRow !== undefined &&
            this.props.selectedDetailsOfRow.statuses !== undefined &&
            this.props.selectedDetailsOfRow.statuses.length !== 0 &&
            this.props.statusCheck === 'inbox' &&
            String(this.props.location.pathname).includes('detail') !== true
        ) {
            permission = userPermission({
                processId:
                    this.props.selectedDetailsOfRow.statuses[0].processId,
                statusId: this.props.selectedDetailsOfRow.statuses[0].statusId,
                menu_name: this.props.currentMenuName,
                configData: this.props.configData,
            });
        } else if (
            this.props.selectedRowDetails !== undefined &&
            this.props.selectedRowDetails.statuses !== undefined &&
            this.props.selectedRowDetails.statuses.length !== 0
        ) {
            permission = userPermission({
                processId: this.props.selectedRowDetails.statuses[0].processId,
                statusId: this.props.selectedRowDetails.statuses[0].statusId,
                menu_name: this.props.currentMenuName,
                configData: this.props.configData,
            });
        } else if (this.props.calingFrom !== 'deleted_document') {
            permission = userPermission({
                processId: this.props.inbox.processId,
                statusId: this.props.inbox.statusId,
                menu_name: this.props.currentMenuName,
                configData: this.props.configData,
            });
        }

        if (this._isMounted) {
            await this.setState({ user_permission: permission });
        }

        if (
            //this.state.permissionCustom === false &&
            this.props.calingFrom === 'deleted_document' &&
            this.props.userPermission !== undefined
        ) {
            if (this._isMounted) {
                await this.setState({
                    permissionCustom: true,
                    user_permission: this.props.userPermission,
                    edit_details: false,
                    detailPage: false,
                });
            }
        }

        let configuredRecordsets = [];
        let patientFieldIdArray;
        let modifyPatientFieldIdArray;
        let orderFieldIdArray;
        let modifyOrderFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let orderRecordsetId;
        let modifyOrderRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;
        let orderModifiableRecordsetId;
        let modifyOrderModifiableRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (formatHeaderNameWithOutSpace(data.name) === 'patientdata') {
                    patientFieldIdArray = data.fields;
                    patientRecordsetId = data.recordsetId;
                    patientModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    modifyPatientFieldIdArray = data.fields;
                    modifyPatientRecordsetId = data.recordsetId;
                    modifyPatientModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (formatHeaderNameWithOutSpace(data.name) === 'orderdata') {
                    orderFieldIdArray = data.fields;
                    orderRecordsetId = data.recordsetId;
                    orderModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'orderdatamodify'
                ) {
                    modifyOrderFieldIdArray = data.fields;
                    modifyOrderRecordsetId = data.recordsetId;
                    modifyOrderModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                return data;
            });
        }
        if (this._isMounted) {
            await this.setState(
                {
                    patientModifiableRecordsetId: patientModifiableRecordsetId,
                    patientFieldIdArray: patientFieldIdArray,
                    patientRecordsetId: patientRecordsetId,
                    modifyPatientFieldIdArray: modifyPatientFieldIdArray,
                    modifyPatientRecordsetId: modifyPatientRecordsetId,
                    modifyPatientModifiableRecordsetId:
                        modifyPatientModifiableRecordsetId,
                    orderFieldIdArray: orderFieldIdArray,
                    orderRecordsetId: orderRecordsetId,
                    orderModifiableRecordsetId: orderModifiableRecordsetId,
                    modifyOrderFieldIdArray: modifyOrderFieldIdArray,
                    modifyOrderRecordsetId: modifyOrderRecordsetId,
                    modifyOrderModifiableRecordsetId:
                        modifyOrderModifiableRecordsetId,
                    edit_details: false,
                },
                () => {}
            );
        }

        // patient
        if (this.props.ddl.hasOwnProperty('attachmenttype')) {
            this.setState({ attachmentTypeDDL: this.props.ddl.attachmenttype });
        }
        if (this.props.ddl.hasOwnProperty('newReferralDDL')) {
            this.setState({ newReferralDDL: this.props.ddl.newreferral });
        }
        if (this.props.editDetails === true) {
            this.setState({
                edit_details: true,
            });
        }
        const pushEditId = [];
        pushEditId.push(window.btoa(String(this.props.selectedDocId)));
        const editURL = `/documents/edit/${pushEditId}`;
        if (this.props.pageCount > 0) {
            this.setState({ editId: editURL });
        } else {
            this.setState({ editId: '' });
        }

        // this.setState({editId:'/documents/edit/865'})

        const rulesJson = this.props.rulesJson;
        for (let rules in rulesJson) {
            if (rulesJson) {
                let rulesTemp = rules;
                rulesTemp = rulesTemp.replace('(', '');
                rulesTemp = rulesTemp.replace(')', '');
                const upperCaseWords = rulesTemp.match(
                    /(\b[A-Z][A-Z]+|\b[A-Z]\b)/g
                );
                const firstWord = rulesTemp.split(' ')[0];
                const array = rulesJson[rules];
                rules = `${firstWord}-${upperCaseWords[0]}`;
                // var finalRules = {};
                // finalRules[rules]=array;
                this.state.rulesJson[rules] = array;
            }
        }

        const noteTypes = getNotes(
            this.props.configData,
            this.props.processId,
            this.props.statusId
        );
        this.setState({ noteTypes: noteTypes });
        if (this.props.calingFrom === 'deleted_document') {
            if (this._isMounted) {
                await this.setState({
                    edit_details: this.props.edit_details,
                });
            }
        }
        if (
            this.state.user_permission !== undefined &&
            this.state.user_permission.allActions !== undefined &&
            this.state.user_permission.allActions.hasOwnProperty(
                'attach_to_chart'
            ) &&
            this.state.user_permission.allActions.hasOwnProperty(
                'save_partial_attach_to_chart'
            )
        ) {
            this.setState({
                detailDDL: 'Attach to chart',
            });
        } else if (
            this.state.user_permission !== undefined &&
            this.state.user_permission.allActions !== undefined &&
            this.state.user_permission.allActions.hasOwnProperty(
                'save_partial_attach_to_chart'
            ) === true &&
            this.state.user_permission.allActions.hasOwnProperty(
                'attach_to_chart'
            ) === false
        ) {
            this.setState({
                detailDDL: 'Save',
            });
        } else if (
            this.state.user_permission !== undefined &&
            this.state.user_permission.allActions !== undefined &&
            this.state.user_permission.allActions.hasOwnProperty(
                'save_partial_attach_to_chart'
            ) === false &&
            this.state.user_permission.allActions.hasOwnProperty(
                'attach_to_chart'
            ) === true
        ) {
            this.setState({
                detailDDL: 'Attach to chart',
            });
        } else if (
            this.state.user_permission !== undefined &&
            this.state.user_permission.allActions !== undefined &&
            this.state.user_permission.allActions.hasOwnProperty(
                're_attach_to_chart'
            ) === true
        ) {
            this.setState({
                detailDDL: 'Re-Attach to Chart',
            });
        }

        this.setState({
            pathName: this.props.location.pathname,
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.pathName !== this.props.location.pathname) {
            this.setState({
                pathName: this.props.location.pathname,
            });
        }

        if (
            this.state.permissionCustom === false &&
            this.props.calingFrom === 'deleted_document' &&
            this.props.userPermission !== undefined
        ) {
            this.setState({
                permissionCustom: true,
                user_permission: this.props.userPermission,
                edit_details: false,
                detailPage: false,
            });
        }
        if (prevState.printSelectedDocId !== this.state.printSelectedDocId) {
            DocumentIdToPrint(this.state.printSelectedDocId)
                .then((value) => {
                    this.props.showLoader(false);
                    this.props.deletedPage({ blurSpinner: false });
                })
                .catch((err) => {
                    console.log(err);
                    this.props.deletedPage({ blurSpinner: false });
                    this.props.showLoader(false);
                });
        }
    }

    async updatePatientFilter(value) {
        //const values = JSON.parse(JSON.stringify(value.patient_filter))

        await this.setState(
            {
                patient_filter: {
                    ...this.state.patient_filter,
                    ...value.patient_filter,
                },
            },
            () => {}
        );
    }

    hideToast() {
        this.setState({
            showToastMsg: false,
        });
    }

    download = () => {
        this.props.download();
    };

    edit_transition = () => {
        this.setState({
            edit_transition: true,
        });
    };

    close_transition = () => {
        this.setState({
            edit_transition: false,
        });
    };

    byPatientOnChangeHandler(e) {
        // not used function
        this.setState({
            edit_patient: false,
            view_patient: false,
            open_patient_dropdown: false,
            patient_filter: e.target.value,
        });

        let searchText = '';
        searchText = `${'fieldSearch=317%3D'}${e.target.value}%2A`;
        this.setState({
            open_patient_dropdown: true,
            patient_list: [],
        });
        recordSetSearch(this.state.patientRecordsetId, searchText)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data && data?.code === 300) {
                    PostMessageLogout();
                }
                const searchResult = PatientSearch(data);
                this.setState({ patient_list: searchResult });
            });
    }

    open_patient_search = (search) => {
        this.setState({
            patient_filter: search,
            open_patient_dropdown: false,
            view_patient: true,
            selected_patient_id: search.patientid,
            create_patient: false,
        });
        if (search.id > 0 || search.id === 0) {
            this.setState({
                patientIsNegative: false,
            });
        } else {
            this.setState({
                patientIsNegative: true,
            });
        }
    };

    edit_patient = () => {
        this.setState({ edit_patient: true });
    };

    edit_order = () => {
        this.setState({ edit_order: true });
    };

    byPatient = () => {
        this.setState({
            by_patient: true,
            by_order: false,
            view_order: false,
            create_order: false,
        });
    };

    byOrder = () => {
        this.setState({
            by_patient: false,
            by_order: true,
            view_patient: false,
            create_patient: false,
        });
    };

    open_order_search = (search) => {
        if (search.id > 0 || search.id === 0) {
            this.setState({
                orderIsNegative: false,
            });
        } else {
            this.setState({
                orderIsNegative: true,
            });
        }
        this.setState({
            order_filter: search,
            open_order_dropdown: false,
            view_order: true,
            edit_order: false,
            create_patient: false,
            //  selected_order_id: id,
        });
    };

    byOrderOnChangeHandler(e) {
        //Not used function
        this.setState({
            create_order: false,
            view_order: false,
            open_order_dropdown: false,
            order_filter: e.target.value,
        });
        // let orderSearchText = '';
        let patientSearchText = '';
        if (this.state.orderFieldIdArray !== '') {
            this.state.orderFieldIdArray.map((fieldData, index) => {
                if (fieldData.name === 'Order Number') {
                    // orderSearchText += `fieldSearch=${fieldData.fieldId}%3D${e.target.value}%2A`;
                } else if (fieldData.name === 'Patient') {
                    patientSearchText += `&fieldSearch=${fieldData.fieldId}%3D${e.target.value}%2A`;
                }
                return fieldData;
            });
        }
        this.setState({ order_list: [] });
        this.setState({ open_order_dropdown: true });
        this.searchOrder(patientSearchText);
    }

    searchOrder = (searchText) => {
        recordSetSearch(this.state.orderRecordsetId, searchText)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data && data?.code === 300) {
                    PostMessageLogout();
                }
                const searchResult = OrderSearch(data);

                this.setState({ order_list: searchResult });
            });
    };

    createOrder = () => {
        this.setState({
            create_order: true,
            view_order: false,
        });
    };

    editDetails = () => {
        this.setState({
            by_order: false,
            edit_details: true,
        });
    };

    closeEditDetails = () => {
        this.setState({
            edit_details: false,
            toggleDetail: false,
            isDetailSubmitted: false,
        });
    };

    openMore = () => {
        this.setState({ more_information: !this.state.more_information });
    };

    setAttachment = (event, state) => {
        this.setState({ [state]: event.target.value });
    };

    refreshViewAll = () => {
        this.setState({
            viewAllNotes: false,
        });
        this.setState({
            viewAllNotes: true,
        });
    };

    patient_list = () => {
        return this.state.patient_list.map((search, index) => {
            return (
                <>
                    <li
                        key={index}
                        onClick={() => {
                            this.open_patient_search(search);
                        }}
                    >
                        <div className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <div className='patient_no_name'>
                                    <span>
                                        #<b>{search.id}</b>
                                    </span>
                                    <span>{search.patientid}</span>
                                    <span className='first_name'>
                                        {search.patientname},
                                    </span>
                                </div>
                                {/* <div className='patient_bio'>
                                <span>Patient address,</span>
                                <span>Patient City</span>
                                <span>Patient State 12345</span>
                            </div> */}
                                <div className='patient_status'>
                                    <span>Episode Status :</span>
                                    <span className='status'>
                                        {search.episodestatus}
                                    </span>
                                </div>
                            </div>
                            <div className=''>
                                <span>{search.formatteddob}</span>
                            </div>
                        </div>
                    </li>
                </>
            );
        });
    };

    order_list = () => {
        return this.state.order_list.map((search, index) => (
            <li
                key={index}
                onClick={() => {
                    this.open_order_search(search);
                }}
            >
                <div className='d-flex align-items-stretch justify-content-between'>
                    <div className=''>
                        <div className='patient_no_name'>
                            <span>
                                #<b>{search.id}</b>
                            </span>
                            <span>{search.patientid}</span>
                            <span className='first_name'>
                                {search.patient},
                            </span>
                        </div>
                        {/* <div className='patient_bio'>
                                <span>Patient address,</span>
                                <span>Patient City</span>
                                <span>Patient State 12345</span>
                            </div> */}
                        <div className='patient_status'>
                            <span>Episode Status :</span>
                            <span className='status'>
                                {search.episodestatus}
                            </span>
                        </div>
                    </div>
                    <div className=''>
                        <span>{search.formattedorderdate}</span>
                    </div>
                </div>
            </li>
        ));
    };

    handleSelectOnChange = (state, event) => {
        this.setState({ [state]: event.target.value }, () => {});
    };

    openCreateNewPatient = () => {
        this.setState({
            create_patient: true,
            edit_patient: false,
            view_patient: false,
            open_patient_dropdown: false,
            open_order_dropdown: false,
        });
    };

    closeCreatePatient = (formData) => {
        this.setState({ create_patient: false });
        this.open_patient_search(formData);
    };
    getSingleOrder = async (ordernumber) => {
        let orderFiledId = '';
        this.props.configData.fields.map((data, item) => {
            if (data.name === 'Order Number') {
                orderFiledId = data.fieldId;
            }
        });

        let orderSearchText = `fieldSearch=${orderFiledId}%3D${ordernumber}%2A`;

        const response = await recordSetSearch(
            this.state.orderRecordsetId,
            orderSearchText
        );
        if (response.status === 200) {
            const data = await response.json();
            if (data && data?.code === 300) {
                PostMessageLogout();
            }
            if (data.dataTable !== undefined) {
                const { dataRows, headerColumns } = data.dataTable;

                let order_idIndex = '';
                let orderId = '';
                headerColumns.forEach((data, index) => {
                    if (data.name === 'Order ID') {
                        order_idIndex = index;
                    }
                });
                for (const row of dataRows) {
                    orderId = row.values[order_idIndex];
                }

                return orderId;
            }
        }
        return null;
    };

    async getBranchId(value) {
        let branchFiledId = '';
        this.props.configData.fields.forEach((data) => {
            if (data.name === 'Branch') {
                branchFiledId = data.fieldId;
            }
        });

        const searchText = `fieldSearch=${branchFiledId}=${encodeParameter(
            value
        )}`;
        let recordsetId = '';
        this.props.configuredRecordsets.forEach((data) => {
            if (data.name === 'Branch Data') {
                recordsetId = data.recordsetId;
            }
        });

        const result = await recordSetSearch(recordsetId, searchText);
        let data;
        if (result.status === 200) {
            data = await result.json();
        } else {
            throw new Error('Error in recordSetSearch');
        }
        if (data && data?.code === 300) {
            PostMessageLogout();
        }
        let searchResult = await EpisodeSearch(data);

        const firstNonEmptyAgency = await searchResult.find(
            (item) => item.agencyid !== ''
        );

        await this.setState({
            formAgencyId: firstNonEmptyAgency.agencyid,
        });
        return BranchIdSearch(data);
    }
    // Here
    submitDetail = async () => {
        if (
            this.props.configData.hasOwnProperty('indexingSettings') &&
            this.props.configData.indexingSettings.referralsUsed === false
        ) {
            await this.setState({
                formData: {
                    ...this.state.formData,
                    new_referral: 'No',
                },
            });
        }

        this.setState({ validateDetail: false });
        let error = false;

        let form_data = {};
        let detailReqFields = [];

        if (this.props.configData.indexingSettings.locationUsed === true) {
            form_data = {
                ...form_data,
                attachment_location: '',
            };
            detailReqFields.push('attachment_location');
        }
        if (
            this.props.configData.indexingSettings.attachmentTypeUsed === true
        ) {
            form_data = {
                ...form_data,
                attachment_type: '',
            };
            detailReqFields.push('attachment_type');
        }

        if (this.state.patient_filter.hasOwnProperty('attachmentlocation')) {
            this.state.patient_filter.attachment_location =
                this.state.patient_filter.attachmentlocation;
        }

        form_data = {
            ...form_data,
            ...this.state.formData,
            // ...this.state.patient_filter,
        };

        /* if (form_data.new_referral === 'No' && this.state.by_order === true) {
            if (!this.state.patient_filter.hasOwnProperty('order_number')) {
                normalErrorToast('Link a patient/order to complete update=======>');
                return true;
            }
        } */

        if (form_data.new_referral === 'No' && this.state.by_patient === true) {
            if (!this.state.patient_filter.hasOwnProperty('patient_id')) {
                normalErrorToast('Link a patient/order to complete update');
                this.setState({ patientLink: true });
                return true;
            }
        }

        //  adding By patient and By order Dynamic fields
        if (
            form_data.new_referral === 'No' &&
            this.state.by_patient === true &&
            this.state.by_patient === false
        ) {
            form_data = {
                ...form_data,
                ...Object.fromEntries(this.state.byPatientDynamicFormData),
            };
        }

        if (form_data.new_referral === 'No' && this.state.by_order === true) {
            if (!this.state.patient_filter.hasOwnProperty('order_number')) {
                normalErrorToast('Link a patient/order to complete update');
                this.setState({ patientLink: true });
                return true;
            }
        }

        for (const property in form_data) {
            // going to check mandatory for by order also
            if (
                (form_data[property] === '' ||
                    form_data[property] === undefined ||
                    form_data[property] === null) &&
                detailReqFields.includes(property)
                // && this.state.by_order === false
            ) {
                if (error === false) {
                    error = true;
                }
                break;
            }
        }

        /*  if (
            form_data.attachment_location === 'EPISODE' &&
            //this.state.episodeData.length > 0 &&
            (form_data.select_an_episode === null ||
                form_data.select_an_episode === undefined ||
                form_data.select_an_episode === '')
        ) {
            error = true;
        } */
        if (
            this.state.patientCreated === false &&
            (this.state.formData.new_referral === 'Incomplete' ||
                this.state.formData.new_referral === 'Yes')
        ) {
            this.setState({
                submitCreatePatient: true,
                submittingViaBigView: true,
            });
            // if (this.state.validateDetail === false) {
            this.setState({ validateDetail: true });
            //}

            error = true;
        }
        if (error === true) {
            //if (this.state.validateDetail === false) {
            this.setState({ validateDetail: true });
            //}

            normalErrorToast('Please fill mandatory fields');
            return true;
        }

        // if (this.state.edit_patient === true) {
        //     normalErrorToast(
        //         'Please complete updating patient with save button'
        //     );
        //     return true;
        // }

        let old_patient = {};
        if (this.props.editDetailPage.hasOwnProperty('patient_filter')) {
            old_patient = this.props.editDetailPage.patient_filter;
        }

        let editedOldPatient = false;

        if (
            Object.keys(old_patient).length > 0 &&
            Object.keys(this.state.patient_filter).length > 0
        ) {
            for (const key in old_patient) {
                if (
                    this.state.patient_filter[key] !== undefined &&
                    this.state.patient_filter[key].toLowerCase().trim() !==
                        old_patient[key].toLowerCase().trim()
                ) {
                    editedOldPatient = true;
                }
            }
        }

        if (
            Object.keys(old_patient).length > 0 &&
            editedOldPatient === false &&
            Object.keys(this.state.patient_filter).length > 0
        ) {
            form_data = {
                ...this.state.patient_filter,
                ...form_data,
                episodeid:
                    this.state.patient_filter.episodeid !== '' &&
                    this.state.patient_filter.episodeid !== null &&
                    this.state.patient_filter.episodeid !== undefined
                        ? this.state.patient_filter.episodeid
                        : this.state.formData.episodeid,
            };
        } else if (
            Object.keys(old_patient).length > 0 &&
            editedOldPatient === true &&
            Object.keys(this.state.patient_filter).length > 0
        ) {
            form_data = {
                ...form_data,
                ...this.state.patient_filter,
                episodeid:
                    this.state.patient_filter.episodeid !== '' &&
                    this.state.patient_filter.episodeid !== null &&
                    this.state.patient_filter.episodeid !== undefined
                        ? this.state.patient_filter.episodeid
                        : this.state.formData.episodeid,
            };
        } else if (Object.keys(this.state.patient_filter).length > 0) {
            /*  form_data = {
                ...form_data,
                ...this.state.patient_filter,
            }; */
            form_data = {
                ...this.state.patient_filter,
                ...form_data,
                episodeid:
                    this.state.patient_filter.episodeid !== '' &&
                    this.state.patient_filter.episodeid !== null &&
                    this.state.patient_filter.episodeid !== undefined
                        ? this.state.patient_filter.episodeid
                        : this.state.formData.episodeid,
            };
        }

        if (form_data.new_referral === 'No') {
            delete form_data.care_type;
            delete form_data.payor_source;
            delete form_data.team;
        }

        if (
            form_data.new_referral === 'Incomplete' ||
            form_data.new_referral === 'Yes'
        ) {
            delete form_data.patient_id;
        }

        this.setState({ submitDetail: true, loader: true });
        let permission;

        if (
            this.props.calingFrom === 'deleted_document' &&
            this.props.userPermission !== undefined
        ) {
            permission = this.props.userPermission;
        } else {
            permission = userPermission({
                processId: this.props.inbox.processId,
                statusId: this.props.inbox.statusId,
                menu_name: this.props.currentMenuName,
                configData: this.props.configData,
            });
        }
        const actionId =
            permission.allActions.save_partial_attach_to_chart.actionId;

        this.setState({ isDetailSubmitted: true });
        let date;
        if (form_data.date_of_birth_validation !== '') {
            date = formatDate(form_data.date_of_birth_validation, 'MM/DD/YYYY');
            if (moment(date, 'MM/DD/YYYY', true).isValid()) {
                form_data.date_of_birth_validation = date;
            } else {
                form_data.date_of_birth_validation = '';
            }
        }

        if (form_data.signed_date !== '') {
            const signed_date = formatDate(form_data.signed_date, 'MM/DD/YYYY');
            if (moment(signed_date, 'MM/DD/YYYY', true).isValid()) {
                form_data.signed_date = signed_date;
            } else {
                form_data.signed_date = '';
            }
        }

        if ('comments' in form_data) {
            if (form_data.comments !== '') {
                form_data.comments = form_data.comments.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br/>'
                );
            }
        }

        const selectedRowDetailsID =
            this.props.selectedRowDetails.miscPproperties.docId;
        const fieldData = GetFieldData({ configData: this.props.configData });
        let params = [];
        const finalParams = {};
        const docid = [];

        //Remove if error exist in form
        this.setState({
            editDetailByOrderValidationError: new Map(),
        });
        // By order Dynamic Validation
        // ( The initial value False.That means, This code is not needed right now, but we may need it in the future. If we do, we can remove the False value.)
        if (false && form_data.new_referral === 'No') {
            const checkRulesResponse = await checkRules(
                form_data,
                this.props.selectedRowDetails.miscPproperties.rulesJson
            );
            if (checkRulesResponse.size > 0) {
                this.setState({
                    loader: false,
                    editDetailByOrderValidationError: checkRulesResponse,
                    submitDetail: false,
                    isDetailSubmitted: false,
                });

                return;
            }
        }

        // By patient => Attachment Type empty remove unwanted entry
        if (form_data.new_referral === 'No' && this.state.by_order === false) {
            const removedData = await clearUnMatchAttachmentType(
                form_data,
                this.props.selectedRowDetails.miscPproperties.rulesJson
            );
            form_data = removedData;
        }

        for (const value in form_data) {
            if (form_data) {
                if (
                    fieldData[value] !== undefined &&
                    form_data[value] !== undefined &&
                    form_data[value] !== '' &&
                    form_data[value] !== null &&
                    value !== 'patientid' &&
                    value !== 'patient' &&
                    value !== 'dob' &&
                    value !== 'attachmentlocation' &&
                    value !== 'episodeVisit' &&
                    value !== 'unique_id' /*  &&
                    value !== 'episode_start' &&
                    value !== 'select_an_episode' &&
                    value !== 'episode_end' &&
                    value !== 'episode_status' &&
                    value !== 'episodeid' */
                ) {
                    params.push({
                        fieldId: fieldData[value],
                        value: form_data[value],
                    });
                }
                if (
                    value === 'patient' &&
                    fieldData[value] !== undefined &&
                    form_data[value] !== undefined &&
                    form_data[value] !== null &&
                    form_data[value] !== ''
                ) {
                    params.push({
                        fieldId: fieldData.patient_name,
                        value: form_data[value],
                    });
                }

                if (
                    value === 'attachmentlocation' &&
                    fieldData[value] !== undefined &&
                    form_data[value] !== null &&
                    form_data[value] !== undefined &&
                    form_data[value] !== '' &&
                    !form_data.hasOwnProperty('attachment_location')
                ) {
                    params.push({
                        fieldId: fieldData.attachment_location,
                        value: form_data[value],
                    });
                }

                if (
                    value === 'episodeVisit' &&
                    fieldData[value] !== undefined &&
                    form_data[value] !== null &&
                    form_data[value] !== undefined &&
                    form_data[value] !== ''
                ) {
                    params.push({
                        fieldId: fieldData.visit_date,
                        value: form_data[value],
                    });
                }
            }
        }
        /*  const episodeReqArray = [
            'episode_start',
            'select_an_episode',
            'episode_end',
            'episode_status',
            'episodeid',
        ];
        episodeReqArray.map((data, index) => {
            params.push({
                fieldId: fieldData[data],
                value:
                    form_data.attachment_location === 'EPISODE'
                        ? form_data[data]
                        : '',
            });
        }); */

        let branchID;

        if (
            form_data.branch_id === '' ||
            form_data.branch_id === null ||
            form_data.branch_id === undefined ||
            form_data.agencyid === '' ||
            form_data.agencyid === null ||
            form_data.agencyid === undefined
        ) {
            await this.getBranchId(form_data.branch)
                .then((branch_id) => {
                    branchID = branch_id;
                })
                .catch((error) => {
                    console.log('Error:', error);
                });

            await params.push({
                fieldId: fieldData.branch_id,
                value: branchID,
            });
            if (
                form_data.agencyid === '' ||
                form_data.agencyid === null ||
                form_data.agencyid === undefined
            ) {
                form_data = {
                    ...form_data,
                    agencyid: this.state.formAgencyId,
                };
                await params.push({
                    fieldId: fieldData.agencyid,
                    value: this.state.formAgencyId,
                });
            }
        }
        let orderId = '';
        if (form_data.order_number) {
            if (
                form_data.order_id === '' ||
                form_data.order_id === null ||
                form_data.order_id === undefined
            ) {
                orderId = await this.getSingleOrder(form_data.order_number);
                await params.push({
                    fieldId: fieldData.order_id,
                    value: orderId,
                });
            }
        } else if (this.state.by_patient === true) {
            await params.push(
                {
                    fieldId: fieldData.order_id,
                    value: '',
                },
                {
                    fieldId: fieldData.order_number,
                    value: '',
                }
            );
        }

        /*  if (
            form_data.attachment_type &&
            form_data.attachment_location &&
            form_data.agencyid
        ) { */
        let result = await getAttachmentData(form_data, this.props.configData);
        params = [...result, ...params];
        /*  } */
        if (form_data.attachment_type) {
            await params.push({
                fieldId: fieldData.document_type,
                value: form_data.attachment_type,
            });
        }

        params = await this.removeDuplicates(params);

        finalParams.fields = params;
        finalParams.typeId = 0;
        finalParams.pages = [];
        finalParams.extension = '';
        if (this.props.calingFrom === 'deleted_document') {
            docid.push(this.props.selectedDocId);
        } else {
            docid.push(selectedRowDetailsID);
        }
        let processId;
        let statusId;
        if (this.props.calingFrom === 'deleted_document') {
            processId = this.props.processId;
            statusId = this.props.statusId;
        } else {
            processId = this.props.inbox.processId;
            statusId = this.props.inbox.statusId;
        }

        const metaParam = {};
        metaParam.docIds = docid;
        metaParam.fields = params;

        let status = '';

        // The following code is used to save the edit type of the current document in Redux if the value of new_referral is "No".
        if (form_data.new_referral === 'No') {
            this.props.inboxPage({
                editDetailsSwitch: {
                    docId: this.props.selectedRowDetails.miscPproperties.docId,
                    editType:
                        this.state.by_order === false ? 'byPatient' : 'ByOrder',
                },
            });
        }

        submitDetailApi(metaParam, processId, statusId, actionId)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((data) => {
                this.setState({ isDetailSubmitted: false, loader: false });
                if (data.hadFailures === false && status === 200) {
                    let headerIndex = 0;
                    if (this.props.calingFrom !== 'deleted_document') {
                        this.props.inbox.inbox.dataTable.headerColumns.map(
                            (header, index) => {
                                if (header.name.toLowerCase() === 'branch') {
                                    headerIndex = index;
                                }
                            }
                        );
                        let docIndex = 0;
                        this.props.inbox.inbox.dataTable.dataRows.map(
                            (docs, index) => {
                                if (docs.id === selectedRowDetailsID) {
                                    docIndex = index;
                                }
                            }
                        );
                        this.props.inbox.inbox.dataTable.dataRows[
                            docIndex
                        ].values[headerIndex] = form_data.branch;
                    }

                    this.setState({ submitDetail: false });
                    if (
                        this.props.selectedRowDetails.hasOwnProperty('fields')
                    ) {
                        const rowDetails = this.props.selectedRowDetails;
                        rowDetails.fields = [...params];
                        this.props.getSelectedRowDetails(rowDetails);

                        const allFields = this.props.configData.fields;
                        const finalRowDetail = {};
                        if (params.length > 0) {
                            params.map((rowdetail) => {
                                allFields.map((allfields) => {
                                    const temp = { ...rowdetail };
                                    if (
                                        rowdetail.fieldId === allfields.fieldId
                                    ) {
                                        temp.fieldName = allfields.name;
                                        const removeSpace =
                                            formatNameWithSpaceToUnderscore(
                                                allfields.name
                                            );
                                        finalRowDetail[removeSpace] = temp;
                                    }
                                });
                            });
                        }

                        this.props.editDetailPage({
                            selectedRowDetail: finalRowDetail,
                        });
                        this.setState({ edit_details: false });
                    }
                    normalSuccessToast('Updated Successfully ');
                    return true;
                } else {
                    if (data && data?.code === 300) {
                        PostMessageLogout();
                    }
                    this.setState({ submitDetail: false });
                    let message = data?.results[0]?.message
                        ? data?.results[0]?.message
                        : 'Error in processing';

                    toast.error(message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                this.setState({
                    loader: false,
                    edit_details: false,
                });
            });
    };

    removeDuplicates = async (data) => {
        const seenFieldIds = new Set();
        const filteredData = data.filter((item) => {
            if (seenFieldIds.has(item.fieldId)) {
                return false;
            }
            seenFieldIds.add(item.fieldId);
            return true;
        });

        return filteredData;
    };

    // Here
    submitAttachToChart = async () => {
        await this.setState({ validateDetail: false });
        let error = false;
        let form_data = {};
        let detailReqFields = [];
        if (this.props.configData.indexingSettings.locationUsed === true) {
            form_data = {
                ...form_data,

                attachment_location: '',
            };
            detailReqFields.push('attachment_location');
        }
        if (
            this.props.configData.indexingSettings.attachmentTypeUsed === true
        ) {
            form_data = {
                ...form_data,
                attachment_type: '',
            };
            detailReqFields.push('attachment_type');
        }
        if (this.state.patient_filter.hasOwnProperty('attachmentlocation')) {
            this.state.patient_filter.attachment_location =
                this.state.patient_filter.attachmentlocation;
        }

        form_data = {
            ...form_data,
            ...this.state.formData,
            // ...this.state.patient_filter,
        };

        /* if (form_data.new_referral === 'No' && this.state.by_order === true) {
            if (!this.state.patient_filter.hasOwnProperty('order_number')) {
                normalErrorToast('Link a patient/order to complete update=======');
                return true;
            }
        } */

        if (form_data.new_referral === 'No' && this.state.by_patient === true) {
            if (!this.state.patient_filter.hasOwnProperty('patient_id')) {
                normalErrorToast('Link a patient/order to complete update');
                this.setState({ patientLink: true });
                return true;
            }
        }

        //  adding By patient and By order Dynamic fields
        if (
            form_data.new_referral === 'No' &&
            (this.state.by_patient === true || this.state.by_patient === false)
        ) {
            form_data = {
                ...form_data,
                ...Object.fromEntries(this.state.byPatientDynamicFormData),
            };
        }

        if (form_data.new_referral === 'No' && this.state.by_order === true) {
            if (!this.state.patient_filter.hasOwnProperty('order_number')) {
                normalErrorToast('Link a patient/order to complete update');
                this.setState({ patientLink: true });
                return true;
            }
        }

        if (this.state.edit_patient === true) {
            normalErrorToast(
                'Please complete updating patient with save button'
            );
            return true;
        }

        for (const property in form_data) {
            //Going to check mandatory for by order
            if (
                (form_data[property] === '' ||
                    form_data[property] === undefined ||
                    form_data[property] === null) &&
                detailReqFields.includes(property)
                // && this.state.by_order === false
            ) {
                if (error === false) {
                    error = true;
                }
                break;
            }
        }

        /* if (
            form_data.attachment_location === 'EPISODE' &&
            //this.state.episodeData.length > 0 &&
            (form_data.select_an_episode === null ||
                form_data.select_an_episode === undefined ||
                form_data.select_an_episode === '')
        ) {
            error = true;
        } */

        if (
            this.state.patientCreated === false &&
            (this.state.formData.new_referral === 'Incomplete' ||
                this.state.formData.new_referral === 'Yes')
        ) {
            this.setState({
                submitCreatePatient: true,
                submittingViaBigView: true,
            });
            //if (this.state.validateDetail === false) {
            this.setState({ validateDetail: true });
            //}
            return true;
        }
        if (error === true) {
            //if (this.state.validateDetail === false) {
            this.setState({ validateDetail: true });
            //}

            normalErrorToast('Please fill mandatory fields');
            return true;
        }
        let old_patient = {};
        if (this.props.editDetailPage.hasOwnProperty('patient_filter')) {
            old_patient = this.props.editDetailPage.patient_filter;
        }

        let editedOldPatient = false;

        if (
            Object.keys(old_patient).length > 0 &&
            Object.keys(this.state.patient_filter).length > 0
        ) {
            for (const key in old_patient) {
                if (
                    this.state.patient_filter[key] !== undefined &&
                    this.state.patient_filter[key].toLowerCase().trim() !==
                        old_patient[key].toLowerCase().trim()
                ) {
                    editedOldPatient = true;
                }
            }
        }

        if (
            Object.keys(old_patient).length > 0 &&
            editedOldPatient === false &&
            Object.keys(this.state.patient_filter).length > 0
        ) {
            form_data = {
                ...this.state.patient_filter,
                ...form_data,
            };
        } else if (
            Object.keys(old_patient).length > 0 &&
            editedOldPatient === true &&
            Object.keys(this.state.patient_filter).length > 0
        ) {
            form_data = {
                ...form_data,
                ...this.state.patient_filter,
            };
        } else if (Object.keys(this.state.patient_filter).length > 0) {
            if (
                form_data.new_referral === 'No' &&
                this.state.by_order === true
            ) {
                form_data = {
                    ...this.state.patient_filter,
                    ...form_data,
                };
            } else {
                // episode details is upate in form data
                form_data = {
                    ...form_data,
                    ...this.state.patient_filter,
                    episode_status: form_data.episode_status,
                    episodeid: form_data.episodeid,
                };
            }
        }

        if (form_data.new_referral === 'No') {
            delete form_data.care_type;
            delete form_data.payor_source;
            delete form_data.team;
        }

        this.setState({ submitDetail: true, loader: true });

        this.setState({ isDetailSubmitted: true });
        let date;
        if (form_data.date_of_birth !== '') {
            const date_of_birth = formatDate(
                form_data.date_of_birth,
                'MM/DD/YYYY'
            );
            if (moment(date_of_birth, 'MM/DD/YYYY', true).isValid()) {
                form_data.date_of_birth = date_of_birth;
            } else {
                form_data.date_of_birth = '';
            }
        }
        if (form_data.date_of_birth_validation !== '') {
            date = formatDate(form_data.date_of_birth_validation, 'MM/DD/YYYY');
            if (moment(date, 'MM/DD/YYYY', true).isValid()) {
                form_data.date_of_birth_validation = date;
            } else {
                form_data.date_of_birth_validation = '';
            }
        }

        if (form_data.signed_date !== '') {
            const signed_date = formatDate(form_data.signed_date, 'MM/DD/YYYY');
            if (moment(signed_date, 'MM/DD/YYYY', true).isValid()) {
                form_data.signed_date = signed_date;
            } else {
                form_data.signed_date = '';
            }
        }

        if (form_data.order_date) {
            const order_date = formatDate(form_data.order_date, 'MM/DD/YYYY');
            if (moment(order_date, 'MM/DD/YYYY', true).isValid()) {
                form_data.order_date = order_date;
            } else {
                form_data.order_date = '';
            }
        }

        if ('comments' in form_data) {
            if (form_data.comments !== '') {
                form_data.comments = form_data.comments.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br/>'
                );
            }
        }

        const selectedRowDetailsID =
            this.props.selectedRowDetails.miscPproperties.docId;
        const fieldData = GetFieldData({ configData: this.props.configData });
        let params = [];
        const finalParams = {};
        const docid = [];
        //Remove if error exist in form
        this.setState({
            editDetailByOrderValidationError: new Map(),
        });
        // Dynamic Validation

        if (form_data.new_referral === 'No') {
            if (this.state.by_patient == false && !form_data.signed_date) {
                const checkRulesForSignDate = new Map();
                checkRulesForSignDate.set(
                    'signed_date',
                    'Signed Date is required'
                );
                showErrorToast('Validation Error');
                this.setState({
                    loader: false,
                    editDetailByOrderValidationError: checkRulesForSignDate,
                    submitDetail: false,
                    isDetailSubmitted: false,
                });

                return;
            }

            const checkRulesResponse = await checkRules(
                form_data,
                this.props.selectedRowDetails.miscPproperties.rulesJson
            );

            if (checkRulesResponse.size > 0) {
                this.setState({
                    loader: false,
                    editDetailByOrderValidationError: checkRulesResponse,
                    submitDetail: false,
                    isDetailSubmitted: false,
                });

                return;
            }
        }
        //return false;
        for (const value in form_data) {
            if (form_data) {
                if (
                    fieldData[value] !== undefined &&
                    form_data[value] !== undefined &&
                    form_data[value] !== '' &&
                    form_data[value] !== null &&
                    value !== 'patientid' &&
                    value !== 'patient' &&
                    value !== 'dob' &&
                    value !== 'attachmentlocation' &&
                    value !== 'episode_visit' &&
                    value !== 'unique_id' /*  &&
                    value !== 'episode_start' &&
                    value !== 'select_an_episode' &&
                    value !== 'episode_end' &&
                    value !== 'episode_status' &&
                    value !== 'episodeid' */
                ) {
                    params.push({
                        fieldId: fieldData[value],
                        value: form_data[value],
                    });
                }

                if (
                    value === 'patient' &&
                    fieldData[value] !== undefined &&
                    form_data[value] !== undefined &&
                    form_data[value] !== null &&
                    form_data[value] !== ''
                ) {
                    params.push({
                        fieldId: fieldData.patient_name,
                        value: form_data[value],
                    });
                }

                if (
                    value === 'attachmentlocation' &&
                    !form_data.hasOwnProperty('attachment_location') &&
                    fieldData[value] !== undefined &&
                    form_data[value] !== null &&
                    form_data[value] !== undefined &&
                    form_data[value] !== ''
                ) {
                    params.push({
                        fieldId: fieldData.attachment_location,
                        value: form_data[value],
                    });
                }

                if (
                    value === 'episode_visit' &&
                    //fieldData[value] !== undefined &&
                    form_data[value] !== null &&
                    form_data[value] !== undefined &&
                    form_data[value] !== ''
                ) {
                    params.push({
                        fieldId: fieldData.visit_date,
                        value: form_data[value],
                    });
                }
            }
        }

        //No need send empty episode id values
        /*  const episodeReqArray = [
            'episode_start',
            'select_an_episode',
            'episode_end',
            'episode_status',
            'episodeid',
        ];
        if (
            form_data.select_an_episode === undefined ||
            form_data.select_an_episode === null ||
            form_data.select_an_episode === ''
        ) {
            episodeReqArray.map((data, index) => {
                params.push({
                    fieldId: fieldData[data],
                    value: form_data[data] ? form_data[data] : '',
                });
            });
        } */

        /* if(this.props.configData.indexingSettings.referralsUsed === false ){            
            if(fieldData.new_referral !== undefined){                
                params.push({
                    fieldId: fieldData.new_referral,
                    value: 'No',
                });
            } 
        } */

        // By patient => Attachment Type empty unwanted entry
        if (form_data.new_referral === 'No' && this.state.by_order === false) {
            const removedData = await clearUnMatchAttachmentType(
                form_data,
                this.props.selectedRowDetails.miscPproperties.rulesJson
            );
            form_data = removedData;
        }

        let branchID;

        if (
            form_data.branch_id === '' ||
            form_data.branch_id === null ||
            form_data.branch_id === undefined ||
            form_data.agencyid === '' ||
            form_data.agencyid === null ||
            form_data.agencyid === undefined
        ) {
            await this.getBranchId(form_data.branch)
                .then((branch_id) => {
                    branchID = branch_id;
                })
                .catch((error) => {
                    console.log('Error:', error);
                });

            await params.push({
                fieldId: fieldData.branch_id,
                value: branchID,
            });
            if (
                form_data.agencyid === '' ||
                form_data.agencyid === null ||
                form_data.agencyid === undefined
            ) {
                form_data = {
                    ...form_data,
                    agencyid: this.state.formAgencyId,
                };
                await params.push({
                    fieldId: fieldData.agencyid,
                    value: this.state.formAgencyId,
                });
            }
        }

        let orderId = '';
        if (form_data.order_number) {
            if (
                form_data.order_id === '' ||
                form_data.order_id === null ||
                form_data.order_id === undefined
            ) {
                orderId = await this.getSingleOrder(form_data.order_number);
                await params.push({
                    fieldId: fieldData.order_id,
                    value: orderId,
                });
            }
        } else if (this.state.by_patient === true) {
            await params.push(
                {
                    fieldId: fieldData.order_id,
                    value: '',
                },
                {
                    fieldId: fieldData.order_number,
                    value: '',
                }
            );
        }

        /*  if (
            form_data.attachment_type &&
            form_data.attachment_location &&
            form_data.agencyid
        ) { */
        let result = await getAttachmentData(form_data, this.props.configData);
        params = [...result, ...params];
        /* } */
        if (form_data.attachment_type) {
            await params.push({
                fieldId: fieldData.document_type,
                value: form_data.attachment_type,
            });
        }

        params = await this.removeDuplicates(params);

        finalParams.fields = params;
        finalParams.typeId = 0;
        finalParams.pages = [];
        finalParams.extension = '';
        docid.push(selectedRowDetailsID);
        const metaParam = {};
        metaParam.docIds = docid;
        metaParam.fieldUpdates = params;

        const actions = getActions(
            '',
            this.props.inbox.processId,
            this.props.inbox.statusId
        );

        let actionId;

        if (this.props.calingFrom === 'deleted_document') {
            actionId =
                this.state.detailDDL === 'Re-Attach to Chart'
                    ? this.props.userPermission.allActions.re_attach_to_chart
                          .actionId
                    : this.props.userPermission.allActions.attach_to_chart
                          .actionId;
        } else {
            actionId =
                this.state.detailDDL === 'Re-Attach to Chart'
                    ? this.state.user_permission.allActions.re_attach_to_chart
                          .actionId
                    : this.state.user_permission.allActions.attach_to_chart
                          .actionId;
        }

        let apiParams = {};
        if (this.props.calingFrom === 'deleted_document') {
            apiParams.processId = this.props.processId;
            apiParams.statusId = this.props.statusId;
        } else {
            apiParams.processId = this.props.inbox.processId;
            apiParams.statusId = this.props.inbox.statusId;
        }
        apiParams.actionId = actionId;
        const fields = {};
        const docidTemp = [];
        if (this.props.calingFrom === 'deleted_document') {
            docidTemp.push(this.props.selectedDocId);
        } else {
            docidTemp.push(selectedRowDetailsID);
        }
        fields.actionId = actionId;
        fields.docIds = docidTemp;
        fields.fields = params;
        fields.newStatus = {
            processId: String(this.props.inbox.processId),
            statusId: String(this.props.inbox.statusId),
        };

        let sortField;
        if (this.props.calingFrom !== 'deleted_document') {
            sortField = sortFields(
                this.props.inbox.stateData.sortColumn,
                this.props.inbox.stateData.sortDirection,
                this.props.inbox.stateData.dateFormatFields
            );
        }

        let filteredData = this.props.inbox.stateData.rowData;
        if (
            this.props.calingFrom !== 'deleted_document' &&
            this.props.inbox.stateData.filterTxt.trim() !== ''
        ) {
            filteredData = this.filterDataGrid(
                this.props.inbox.stateData.rowData,
                this.props.inbox.stateData.filterTxt
            ); // Performing Search
            filteredData = _.orderBy(
                filteredData,
                sortField.sortfn,
                sortField.orderBy
            ); // Performing Sorting
        }

        let status = '';
        let nextIndex = '';
        let currentIndex;
        let nextDocId = '';
        let rowsToDelete = [];
        if (this.props.calingFrom !== 'deleted_document') {
            const rowLen = filteredData.length;
            await filteredData.map((tableData, i) => {
                if (tableData.doc_id === selectedRowDetailsID) {
                    if (rowLen === i + 1) {
                        nextIndex = '';
                    } else {
                        nextIndex = filteredData[i + 1].id;
                        currentIndex = tableData.id;
                    }
                }
            });

            if (nextIndex !== '') {
                let index = filteredData.findIndex(
                    (item) => item.id === nextIndex
                );

                //rowsToDelete.push(filteredData[index]);

                nextDocId = filteredData[index].doc_id;
                /*  nextDocId = filteredData.find(
                    (item) => item.id === nextIndex
                ).docid; */
            }
        }

        attachToChart(apiParams, fields)
            .then(async (response) => {
                this.setState({ submitDetail: false });
                status = response.status;
                return response.json();
            })
            .then((data) => {
                if (data.hadFailures === false && status === 200) {
                    this.setState({ submitDetail: false });
                    if (nextDocId) {
                        if (this.props.calingFrom !== 'search') {
                            this.props.getListaction(
                                this.props.inbox.processId,
                                this.props.inbox.statusId
                            );
                        }

                        const response = getDocumentMetaWithDocId({
                            docid: nextDocId,
                        });

                        response.then(async (data) => {
                            if (data) {
                                if (data && data?.code === 300) {
                                    PostMessageLogout();
                                }
                                if (data.statuses.length > 1) {
                                    showErrorToast(
                                        `Error in processing Document ${data.miscPproperties.docId} . Please contact WorldView Customer Support for further assistance.`
                                    );
                                    this.props.refreshTable();
                                    return false;
                                } else {
                                    normalSuccessToast(
                                        'Details Updated Successfully'
                                    );
                                    await this.props.getSelectedRowDetails(
                                        data
                                    );

                                    this.props.refreshForNextDocument(
                                        nextDocId
                                    );
                                    lockDocument(nextDocId)
                                        .then((data) => {})
                                        .catch((err) => {});

                                    let formatted_rowDetail = '';
                                    if (data.hasOwnProperty('fields')) {
                                        formatted_rowDetail = data.fields;
                                    }

                                    const allFields =
                                        this.props.configData.fields;
                                    const finalRowDetail = {};
                                    if (formatted_rowDetail.length > 0) {
                                        formatted_rowDetail.map((rowdetail) => {
                                            allFields.map((allfields) => {
                                                const temp = { ...rowdetail };
                                                if (
                                                    rowdetail.fieldId ===
                                                    allfields.fieldId
                                                ) {
                                                    temp.fieldName =
                                                        allfields.name;
                                                    const removeSpace =
                                                        formatNameWithSpaceToUnderscore(
                                                            allfields.name
                                                        );
                                                    finalRowDetail[
                                                        removeSpace
                                                    ] = temp;
                                                }
                                            });
                                        });
                                    }

                                    this.props.editDetailPage({
                                        selectedRowDetail: finalRowDetail,
                                    });

                                    var rowStateData =
                                        this.props.inbox.stateData.rowData.slice();
                                    //rowStateData.splice(currentIndex, 1);
                                    rowStateData = rowStateData.filter(
                                        (obj) => obj.id !== currentIndex
                                    );
                                    let rowClassId;
                                    rowStateData.map((item) => {
                                        if (
                                            String(item.doc_id) ===
                                            String(nextDocId)
                                        ) {
                                            rowClassId = item.id;
                                        }
                                    });
                                    RefreshCounts(
                                        this.props.inbox.processId,
                                        this.props.inbox.statusId,
                                        this.props //Here count from parent and getCountData is passing
                                    );

                                    this.props.updateState({
                                        is_locked:
                                            data.miscPproperties.isLocked,
                                        rowsToDelete: rowsToDelete,
                                        //onRowClickId: nextDocId,
                                        //rowClickClass: [rowClassId],
                                        nextDocFromAttach: true,
                                        edit_details: data.miscPproperties
                                            .isLocked
                                            ? false
                                            : true, // if they select from small view and give attach to chart it moves to next doc there attach to chart will vieible - Aiswarya
                                    });
                                    this.props.updateInboxState({
                                        rowData: rowStateData,
                                        //onRowClickId: nextDocId,
                                        //rowClickClass: [rowClassId],
                                    });
                                }
                            }
                        });
                    } else {
                        if (this.props.calingFrom !== 'deleted_document') {
                            this.props.refreshTable();
                        } else if (
                            this.props.calingFrom === 'deleted_document'
                        ) {
                            this.props.refreshForNextDocument(nextDocId);
                        }
                    }
                    // this.props.refreshTable();
                    this.setState({
                        edit_details: false,
                        isDetailSubmitted: false,
                    });
                } else {
                    if (data && data?.code === 300) {
                        PostMessageLogout();
                    } else {
                        let message = data?.results[0]?.message
                            ? data?.results[0]?.message
                            : 'Error in processing';
                        this.setState({ submitDetail: false });
                        toast.error(message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            loader: false,
                            edit_details: false,
                            isDetailSubmitted: false,
                        });
                    }
                }
            });
    };
    async nextIdSelected() {
        const selectedRowDetailsID =
            this.props.selectedRowDetails.miscPproperties.docId;
        const rowsToDelete = [];
        let docidTemp = [];
        if (this.props.calingFrom === 'deleted_document') {
            docidTemp.push(this.props.selectedDocId);
        } else {
            docidTemp.push(selectedRowDetailsID);
        }

        const sortField = sortFields(
            this.props.inbox.stateData.sortColumn,
            this.props.inbox.stateData.sortDirection,
            this.props.inbox.stateData.dateFormatFields
        );
        let filteredData = this.props.inbox.stateData.rowData;
        if (this.props.inbox.stateData.filterTxt.trim() !== '') {
            filteredData = this.filterDataGrid(
                this.props.inbox.stateData.rowData,
                this.props.inbox.stateData.filterTxt
            ); // Performing Search
            filteredData = _.orderBy(
                filteredData,
                sortField.sortfn,
                sortField.orderBy
            ); // Performing Sorting
        } else {
            filteredData = _.orderBy(
                filteredData,
                sortField.sortfn,
                sortField.orderBy
            ); // Performing Sorting
        }

        let status = '';
        let nextIndex = '';
        let currentIndex;
        let nextDocId = '';
        if (this.props.calingFrom !== 'deleted_document') {
            const rowLen = filteredData.length;

            await filteredData.map((tableData, i) => {
                if (tableData.doc_id === selectedRowDetailsID) {
                    if (rowLen === i + 1) {
                        nextIndex = '';
                    } else {
                        nextIndex = i + 1;
                        currentIndex = tableData.id;
                    }
                }
            });

            if (nextIndex !== '') {
                rowsToDelete.push(filteredData[nextIndex]);
                nextDocId = filteredData[nextIndex].doc_id;
            }
        }

        if (nextDocId !== '') {
            if (this.props.calingFrom !== 'search') {
                await this.props.getListaction(
                    this.props.inbox.processId,
                    this.props.inbox.statusId
                );
            }

            const response = getDocumentMetaWithDocId({
                docid: nextDocId,
            });

            response.then(async (data) => {
                if (data && data?.code === 300) {
                    PostMessageLogout();
                }
                if (data.statuses.length > 1) {
                    showErrorToast(
                        `Error in processing Document ${data.miscPproperties.docId} . Please contact WorldView Customer Support for further assistance.`
                    );
                    this.props.refreshTable();
                    return false;
                } else {
                    await this.props.getSelectedRowDetails(data);
                    //if (data.miscPproperties.isLocked) {

                    //}
                    this.props.refreshForNextDocument(nextDocId);

                    let formatted_rowDetail = '';
                    if (data.hasOwnProperty('fields')) {
                        formatted_rowDetail = data.fields;
                    }

                    const allFields = this.props.configData.fields;
                    const finalRowDetail = {};
                    if (formatted_rowDetail.length > 0) {
                        formatted_rowDetail.map((rowdetail) => {
                            allFields.map((allfields) => {
                                const temp = { ...rowdetail };
                                if (rowdetail.fieldId === allfields.fieldId) {
                                    temp.fieldName = allfields.name;
                                    const removeSpace =
                                        formatNameWithSpaceToUnderscore(
                                            allfields.name
                                        );
                                    finalRowDetail[removeSpace] = temp;
                                }
                            });
                        });
                    }

                    this.props.editDetailPage({
                        selectedRowDetail: finalRowDetail,
                    });

                    var rowStateData =
                        this.props.inbox.stateData.rowData.slice();

                    rowStateData.splice(currentIndex, 1);

                    let rowClassId;

                    rowStateData.map((item) => {
                        if (String(item.doc_id) === String(nextDocId)) {
                            rowClassId = nextIndex;
                        }
                    });
                    RefreshCounts(
                        this.props.inbox.processId,
                        this.props.inbox.statusId,
                        this.props //Here count from parent and getCountData is passing
                    );
                    this.props.updateState({
                        is_locked: data.miscPproperties.isLocked,
                        rowsToDelete: rowsToDelete,
                        // onRowClickId: nextDocId,
                        // rowClickClass: [rowClassId],
                    });
                    this.props.updateInboxState({
                        rowData: rowStateData,
                        // onRowClickId: nextDocId,
                        // rowClickClass: [rowClassId],
                    });

                    /*  const tableData = this.props.inbox.inbox;
                        const tableRows = this.props.inbox.inbox.dataTable.dataRows.slice();

                        tableRows.splice(currentIndex, 1)

                        tableData.dataTable.dataRows = tableRows
                        this.props.updateList(tableData);  */
                }
            });
        } else {
            if (this.props.calingFrom !== 'deleted_document') {
                this.props.refreshTable();
            } else if (this.props.calingFrom === 'deleted_document') {
                this.props.refreshForNextDocument(nextDocId);
            }
        }
    }

    filterDataGrid(rowData, searchText = '', preventState = false) {
        const filteredData = _.filter(rowData, function (data) {
            // filter Operation
            let passed = false;
            for (const property in data) {
                if (
                    property === 'doc_id' ||
                    property === 'tag_id' ||
                    property === 'id'
                ) {
                    // eslint-disable-next-line no-continue
                    continue;
                } // Excluding few Property from filter
                passed =
                    String(data[property]).toLowerCase().match(searchText) !=
                        null &&
                    String(data[property]).toLowerCase().match(searchText)
                        .length > 0;
                if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
            }
            return passed;
        });
        return filteredData;
    }

    handleFormOnChange = (state, event) => {
        const formData = {
            ...this.state.formData,
            [state]: event.target.value,
        };
        this.setState({ formData: formData });
    };

    setFormOnChange = (state, value, type) => {
        const formData = { ...this.state.formData, [state]: value };
        this.setState({ formData: formData });
    };

    refreshEditDetails = () => {
        this.setState(
            {
                // formData: {},
                // patient_filter: {},
                edit_details: false,
            },
            () => {
                this.setState({ edit_details: true });
            }
        );
    };

    clearAll = () => {
        this.setState(
            {
                formData: {},
                patient_filter: {},
                edit_details: false,
            },
            () => {
                this.setState({ edit_details: true });
            }
        );
    };

    Update_fax_details = () => {
        const selectedRowDetails = this.props.inbox.selectedRowDetails.fields;
        const Fields = this.props.configData.fields;
        const allFields = [];

        Fields.map((data) => {
            allFields.push({
                fieldId: data.fieldId,
                name: formatNameWithSpaceToUnderscore(data.name),
            });
        });

        let fieldId;
        allFields.map((dataValue) => {
            if (dataValue.name === 'fax_detail') {
                fieldId = dataValue.fieldId;
            }
        });
        selectedRowDetails.map((data) => {
            if (data.fieldId === fieldId) {
                this.setState({
                    updateValue: data.value,
                });
            }
        });

        this.setState({
            openUpdateNotes: true,
            selectedDocId: this.props.selectedDocId,
        });
    };

    sendDraft = async () => {
        let serviceType = this.props.configData?.emailIntegration?.serviceType;
        if (serviceType === 'Office 365') {
            // check to authenticate draft mail
            Office365AuthenticationPage({
                selectedDocID: this.props.selectedDocId,
                configData: this.props.configData,
            });
        } else {
            GmailAuthenticationPage({
                selectedDocID: this.props.selectedDocId,
                configData: this.props.configData,
            });
        }
    };
    printIt = (data) => {
        if (this.props.pdfPrintRef) {
            this.props.pdfPrintRef.current.printPdf();
        } else {
            this.props.showLoader(true);
            this.setState({
                printSelectedDocId: [data],
            });
        }
    };
    render() {
        const handleHistroy = () => {
            this.setState({
                openHistroy: true,
            });
        };
        const handleHistroyClose = () => {
            this.setState({
                openHistroy: false,
            });
        };
        // const printIt = (data) => {

        //     this.props.showLoader(true);
        //     this.setState({
        //         printSelectedDocId: [data],
        //         printLoader: true,
        //     });
        // };
        const setPrinterloader = () => {
            this.setState({
                // printSelectedDocId:[],
                printLoader: false,
            });
        };
        const selectedDocId = this.props.selectedDocId;
        const backBtn = () => {
            // var pathname = window.location.pathname.split('/');

            if (
                this.state.detailPage === true &&
                String(this.props.location.pathname).includes('detail') === true
            ) {
                return (
                    <>
                        {' '}
                        <div className='back_btn'>
                            <button
                                type='button'
                                onClick={() => this.props.goToPage()}
                            >
                                Back
                            </button>
                        </div>{' '}
                    </>
                );
            }
            return null;
        };

        return (
            <>
                <div
                    className={
                        this.props.isLocked === true
                            ? 'pdf-detail mb-5 d-flex justify-content-end action-locked'
                            : 'pdf-detail mb-5 d-flex justify-content-end'
                    }
                >
                    <SpinnerBlur show={this.state.loader} />
                    <div className='left-expand d-flex align-items-stretch justify-content-between'>
                        {/* <div className="left-pdf-button" onClick={this.doExpand} style={{ display: "inline-block" }}><span><i className="fas fa-file-medical-alt"></i></span></div> */}
                        <div>
                            {this.state.editId !== '' &&
                            this.props.isLocked === false &&
                            this.props.calingFrom !== 'search' &&
                            this.state?.user_permission?.processName !==
                                'Export' ? (
                                <div
                                    className='left-pdf-button'
                                    onClick={() => {
                                        let currentPath;
                                        this.props.updateState({
                                            single_edit: true,
                                        });
                                        currentPath =
                                            window.location.pathname.split('/');
                                        this.props.history.push({
                                            pathname: `/${currentPath[1]}/edit`,
                                            search: `?edit=${window.btoa(
                                                String(this.props.selectedDocId)
                                            )}`,
                                        });
                                    }}
                                >
                                    <span title='Edit Document'>
                                        <i className='fas fa-file-edit rest' />
                                    </span>
                                </div>
                            ) : this.props.calingFrom === 'search' &&
                              this.state?.user_permission?.processName !==
                                  'Export' ? (
                                <div
                                    className='left-pdf-button'
                                    onClick={() => {
                                        this.props.updateState({
                                            single_edit: true,
                                        });
                                        this.props.history.push({
                                            pathname:
                                                this.props.location.pathname +
                                                '/edit',
                                            search:
                                                '?edit=' +
                                                window.btoa(
                                                    String(
                                                        this.props.selectedDocId
                                                    )
                                                ),
                                        });
                                    }}
                                >
                                    <span title='Edit Document'>
                                        <i className='fas fa-file-edit rest' />
                                    </span>
                                </div>
                            ) : this.props.calingFrom === 'deleted_document' &&
                              this.state?.user_permission?.processName !==
                                  'Export' ? (
                                <div
                                    className='left-pdf-button'
                                    onClick={() => {
                                        let str = this.props.location.pathname;

                                        const lastChar = str.at(-1);
                                        if (lastChar === '/') {
                                            str = str.substring(
                                                1,
                                                str.length - 1
                                            );
                                        }

                                        this.props.deletedPage({
                                            single_edit: true,
                                        });
                                        this.props.history.push({
                                            pathname: str + '/edit',
                                            search:
                                                '?edit=' +
                                                window.btoa(
                                                    String(
                                                        this.props.deleted_page
                                                            .selectedDocId
                                                    )
                                                ),
                                        });
                                    }}
                                >
                                    <span title='Edit Document'>
                                        <i className='fas fa-file-edit rest' />
                                    </span>
                                </div>
                            ) : (
                                this.state?.user_permission?.processName !==
                                    'Export' && (
                                    <div
                                        className='left-pdf-button'
                                        style={{
                                            cursor: 'not-allowed',
                                        }}
                                    >
                                        <span
                                            title='Edit Document'
                                            style={{
                                                // backgroundColor: "grey",
                                                cursor: 'not-allowed',
                                            }}
                                        >
                                            <i className='fas fa-file-edit rest' />
                                        </span>
                                    </div>
                                )
                            )}
                            {this.state?.user_permission?.processName !==
                                'Export' && (
                                <div
                                    className='left-pdf-button'
                                    onClick={
                                        this.props.isLocked
                                            ? null
                                            : handleHistroy
                                    }
                                    style={
                                        this.props.isLocked
                                            ? {
                                                  display: 'inline-block',
                                                  cursor: 'not-allowed',
                                              }
                                            : { display: 'inline-block' }
                                    }
                                >
                                    <span
                                        title='View History'
                                        style={
                                            this.props.isLocked
                                                ? { cursor: 'not-allowed' }
                                                : { cursor: 'pointer' }
                                        }
                                    >
                                        <i className='fa fa-history' />
                                        View History
                                    </span>
                                </div>
                            )}
                            <div className='icon_fun remove_icon_hover'>
                                {this.props.isLocked === true ? (
                                    // this.props.callingFrom ===
                                    // 'related_document'
                                    <span className='lock_drop'>
                                        <i
                                            className='fa fa-ellipsis-v'
                                            aria-hidden='true'
                                        />
                                    </span>
                                ) : (
                                    <span>
                                        {this.state.detailBigView !== true && (
                                            <Dropdown className='bulk-print-opt dropDown-svg'>
                                                <Dropdown.Toggle
                                                    variant='success'
                                                    id='dropdown-basic'
                                                    title='Settings'
                                                >
                                                    <OverlayTrigger
                                                        placement='top'
                                                        onEntering={
                                                            this.entering
                                                        }
                                                        overlay={
                                                            <Tooltip
                                                                id='tooltip'
                                                                style={{
                                                                    backgroundColor:
                                                                        'rgb(0, 35, 58)',
                                                                    color: 'white',
                                                                    marginBottom:
                                                                        '20px',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        backgroundColor:
                                                                            'rgb(0, 35, 58)',
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Settings
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i
                                                            className='fa fa-ellipsis-v'
                                                            aria-hidden='true'
                                                        />
                                                    </OverlayTrigger>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <ExportMenu
                                                        permission={
                                                            this.state
                                                                .user_permission
                                                        }
                                                        callingFrom='detailView'
                                                        nextIdSelected={() => {
                                                            this.nextIdSelected();
                                                        }}
                                                        updateState={(
                                                            state
                                                        ) => {
                                                            this.setState({
                                                                ...state,
                                                            });
                                                            this.props.inboxPage(
                                                                { ...state }
                                                            );
                                                        }}
                                                        calingFrom={
                                                            this.props
                                                                .calingFrom
                                                        }
                                                    />
                                                    {this.props.isLocked ===
                                                        false &&
                                                    this.state
                                                        .user_permission !==
                                                        undefined &&
                                                    this.state.user_permission
                                                        .allActions !==
                                                        undefined &&
                                                    this.state.user_permission
                                                        .allActions
                                                        .update_fax_details !==
                                                        undefined ? (
                                                        <Dropdown.Item
                                                            title='Update Fax Details'
                                                            onClick={() =>
                                                                this.Update_fax_details()
                                                            }
                                                        >
                                                            <span className='delivery-details'>
                                                                <svg
                                                                    className='icon-fill'
                                                                    width='39'
                                                                    height='34'
                                                                    viewBox='0 0 39 34'
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                >
                                                                    <path d='M33.7879 11.6753C34.4225 10.9737 34.4225 9.82878 33.7879 9.16402L31.7838 6.94815C31.1826 6.24645 30.1471 6.24645 29.5125 6.94815L27.9761 8.64698C27.8425 8.79471 27.8425 9.09016 27.9761 9.27481L31.6836 13.3742C31.8506 13.5219 32.1178 13.5219 32.2514 13.3742L33.7879 11.6753ZM26.6734 10.0873L17.8889 19.8002L17.1874 24.2689C17.0872 24.8967 17.5548 25.4138 18.1227 25.303L22.1642 24.5274L30.9488 14.8145C31.1158 14.6668 31.1158 14.3713 30.9488 14.1867L27.2412 10.0873C27.0742 9.93958 26.807 9.93958 26.6734 10.0873ZM20.1267 22.053H21.73V23.4195L19.5589 23.8257L18.5235 22.6809L18.8909 20.2803H20.1267V22.053Z' />
                                                                    <path d='M5.87503 6.18655H7.16802C7.85493 6.18655 8.46102 6.82318 8.46102 7.54471V21.1263C8.46102 21.8903 7.85493 22.4845 7.16802 22.4845H5.87503C5.14772 22.4845 4.58203 21.8903 4.58203 21.1263V7.54471C4.58203 6.82318 5.14772 6.18655 5.87503 6.18655ZM23.977 4.06442V7.54471C24.6639 7.54471 25.27 8.18134 25.27 8.90287V10.2715L17.0473 19.3631L16.5574 22.4845H11.047C10.3197 22.4845 9.75402 21.8903 9.75402 21.1263V2.11207C9.75402 1.39054 10.3197 0.753906 11.047 0.753906H20.8253C21.1889 0.753906 21.5122 0.923676 21.7546 1.17833L23.5729 3.08824C23.8153 3.3429 23.977 3.68244 23.977 4.06442ZM25.27 21.0977L24.0163 22.4838C24.0032 22.4843 23.9901 22.4845 23.977 22.4845H21.731V22.0561H20.1277V20.2834H18.8919L18.555 22.4845H17.469L17.8899 19.8033L18.805 18.7915V19.0891C18.805 19.4711 19.0878 19.7681 19.4515 19.7681H20.7445C21.0677 19.7681 21.391 19.4711 21.391 19.0891V17.7309C21.391 17.3914 21.0677 17.0518 20.7445 17.0518H20.3784L25.27 11.6433V21.0977ZM15.5725 19.7681C15.8957 19.7681 16.219 19.4711 16.219 19.0891V17.7309C16.219 17.3914 15.8957 17.0518 15.5725 17.0518H14.2795C13.9158 17.0518 13.633 17.3914 13.633 17.7309V19.0891C13.633 19.4711 13.9158 19.7681 14.2795 19.7681H15.5725ZM15.5725 14.3355C15.8957 14.3355 16.219 14.0384 16.219 13.6564V12.2983C16.219 11.9587 15.8957 11.6192 15.5725 11.6192H14.2795C13.9158 11.6192 13.633 11.9587 13.633 12.2983V13.6564C13.633 14.0384 13.9158 14.3355 14.2795 14.3355H15.5725ZM20.7445 14.3355C21.0677 14.3355 21.391 14.0384 21.391 13.6564V12.2983C21.391 11.9587 21.0677 11.6192 20.7445 11.6192H19.4515C19.0878 11.6192 18.805 11.9587 18.805 12.2983V13.6564C18.805 14.0384 19.0878 14.3355 19.4515 14.3355H20.7445ZM11.6935 8.90287H22.0375V4.82839H20.7445C20.3808 4.82839 20.098 4.53129 20.098 4.14931V2.79115H11.6935V8.90287Z' />
                                                                </svg>
                                                                Update Fax
                                                                Details
                                                            </span>
                                                        </Dropdown.Item>
                                                    ) : null}
                                                    <Dropdown.Item
                                                        title='Print'
                                                        className='print-dropdown'
                                                        onClick={() => {
                                                            this.props.deletedPage(
                                                                {
                                                                    blurSpinner: true,
                                                                }
                                                            );
                                                            this.printIt(
                                                                selectedDocId
                                                            );
                                                        }}
                                                    >
                                                        <span
                                                            onClick={() => {
                                                                // this.printIt(
                                                                //     selectedDocId
                                                                // );
                                                            }}
                                                        >
                                                            <i className='fas fa-print' />
                                                            Print
                                                        </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            this.download()
                                                        }
                                                        title='Download'
                                                    >
                                                        <span>
                                                            <i className='fas fa-download' />
                                                            Download
                                                        </span>
                                                    </Dropdown.Item>
                                                    {this.state.serviceType &&
                                                    this.state?.user_permission
                                                        ?.processName !==
                                                        'Export' ? (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.sendDraft();
                                                            }}
                                                            title='Send via email'
                                                        >
                                                            <span>
                                                                <i className='fa-solid fa-envelope' />
                                                                Send via email
                                                            </span>
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {/* {this.props.isLocked != true ? */}
                                                    {/* {this.state.detailPage ===
                                                false ? ( */}
                                                    {this.state?.user_permission
                                                        ?.show_delete &&
                                                    this.props.callingFrom !==
                                                        'related_document' &&
                                                    this.props.callingFrom !==
                                                        'deleted_document' ? (
                                                        <Dropdown.Item
                                                            onClick={
                                                                this
                                                                    .set_opendeletepopup
                                                            }
                                                            title='Delete'
                                                        >
                                                            <span>
                                                                <i className='fas fa-trash-alt' />
                                                                Delete
                                                            </span>
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {/* : null } */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                        {isMobile === false ||
                        this.props.reportPagecalingFrom === 'report'
                            ? backBtn()
                            : null}
                    </div>
                </div>
                <div
                    className={`toast-container ${this.state.toast_type}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '25%',
                    }}
                >
                    {toastElement(
                        this.hideToast.bind(this),
                        this.state.showToastMsg,
                        this.state.toastHeaderText,
                        this.state.toastMessageText
                    )}
                </div>
                {/* Details Top menu Sections */}

                <div>
                    {this.state?.user_permission?.processName !== 'Export' && (
                        <RelatedDocument
                            selectedDocId={this.props.selectedDocId}
                            isLocked={this.props.isLocked}
                            toggleSpinner={() => {}}
                            callingFrom={this.props.callingFrom}
                            myParent='bigview'
                            //allActions={this.state.user_permission.allActions}
                        />
                    )}
                    {/* {this.state.printSelectedDocId.length > 0 ? (
                        <PrintPdfDocument
                            docId={this.state.printSelectedDocId}
                            loader={setPrinterloader}
                        />
                    ) : null} */}
                    {/* Transition Section */}
                    {this.state.open_transition_section &&
                    (this.props.reportPagecalingFrom === 'report' ||
                        String(this.props.location.pathname).includes(
                            'detail'
                        ) === true ||
                        (this.state.user_permission !== undefined &&
                            (this.state?.user_permission?.show_branch ===
                                true ||
                                this.state?.user_permission?.show_group ===
                                    true ||
                                this.state?.user_permission?.show_inbox ===
                                    true))) ? (
                        <div className='wrap_transition p-4'>
                            {/* <div className="d-flex transition_part align-items-stretch justify-content-between mb-5 pb-2">
									<div className="transition_fun"><span><i title="Transition" className="fas fa-exchange-alt"> </i>Transition</span></div>
								</div> */}

                            <TransitionComponent
                                content='normal'
                                statusArray={this.props.statusArray}
                                branches={this.props.branches}
                                editTransition={this.state.edit_transition}
                                groups={this.props.groups}
                                isLocked={this.props.isLocked}
                                showBranch={this.props.showBranch}
                                showGroup={this.props.showGroup}
                                showInbox={this.props.showInbox}
                                menuGroup={this.props.menuGroup}
                                menuBranch={this.props.menuBranch}
                                menuInbox={this.props.menuInbox}
                                menu_name={this.props.menu_name}
                                processId={this.props.processId}
                                statusId={this.props.statusId}
                                selectedDocId={this.props.selectedDocId}
                                branchActionId={
                                    this.state?.user_permission?.branchActionId
                                }
                                groupActionId={
                                    this.state?.user_permission?.groupActionId
                                }
                                closeTransition={() => {
                                    this.props.showLoader(false);
                                    this.props.hideBigView(true);
                                    if (
                                        this.props.calingFrom !==
                                        'deleted_document'
                                    ) {
                                        this.props.refreshTable();
                                    } else if (
                                        this.props.calingFrom ===
                                        'deleted_document'
                                    ) {
                                        this.props.refreshForNextDocument();
                                    }

                                    // this.props.history.push({
                                    //     pathname: '/'
                                    // })
                                    // this.props.history.push({
                                    //     pathname: '/inbox'
                                    // })
                                }}
                                detailPage={this.state.detailPage}
                                calingFrom={this.props.calingFrom}
                                userPermission={this.state.user_permission}
                            />
                        </div>
                    ) : null}
                    {/* {this.state.printLoader ? (
                        <div className='grouping-loader'>
                            {' '}
                            <Loader show={this.state.printLoader} />{' '}
                        </div>
                    ) : null} */}

                    {/* Details Section */}

                    {this.state.open_details_section ? (
                        <div className='wrap_details p-4'>
                            <div className='d-flex details_part mb-3 align-items-stretch justify-content-between'>
                                <div className='details_fun'>
                                    <span>
                                        <i
                                            title='Details'
                                            className='fa-solid fa-note-medical'
                                        >
                                            {' '}
                                        </i>
                                        Details
                                    </span>
                                </div>

                                {(this.state.user_permission.allActions.hasOwnProperty(
                                    'save_partial_attach_to_chart'
                                ) &&
                                    this.state.user_permission.allActions
                                        .save_partial_attach_to_chart
                                        .actionId !== '') ||
                                (this.state.user_permission.allActions.hasOwnProperty(
                                    'attach_to_chart'
                                ) &&
                                    this.state.user_permission.allActions
                                        .attach_to_chart.actionId !== '') ? (
                                    <div className='edit_details'>
                                        <Button
                                            variant='primary'
                                            title='Edit Details'
                                            disabled={
                                                this.props.isLocked === true
                                            }
                                        >
                                            Edit Details
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                            <div className='text_detail mb-2'>
                                {' '}
                                <span>
                                    No details added yet. Click on the "Edit
                                    Details" button above to get started.
                                </span>
                            </div>
                            <div className='more_info' onClick={this.openMore}>
                                <div className='mt-4 mb-2 more_infor'>
                                    {this.state.more_information ? (
                                        <div title='Less Information'>
                                            <i className='fa-regular fa-chevron-up' />
                                            <button type='button'>
                                                {' '}
                                                Less Information
                                            </button>
                                        </div>
                                    ) : (
                                        <div title='More Information'>
                                            <i className='fa-regular fa-chevron-down' />
                                            <button type='button'>
                                                {' '}
                                                More Information
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {this.state.more_information ||
                            this.props.more_information === true ? (
                                <DetailMoreInformation />
                            ) : null}
                        </div>
                    ) : null}

                    <div className='wrap_details p-md-4 p-0'>
                        <div className='details_part'>
                            <div className='details_fun detail_title_mobile'>
                                <span>
                                    <i
                                        title='Details'
                                        className='fa-solid fa-notes-medical'
                                    >
                                        {' '}
                                    </i>
                                    Details
                                </span>
                            </div>
                        </div>
                        <div className='d-flex details_part step2 mb-3 align-items-stretch justify-content-between'>
                            <div className='details_fun'>
                                <span>
                                    <i
                                        title='Details'
                                        className='fa-solid fa-notes-medical'
                                    >
                                        {' '}
                                    </i>
                                    Details
                                </span>
                            </div>

                            {this.state.edit_details === false &&
                            this.state.detailPage === false &&
                            this.props.isLocked === false &&
                            this.state.user_permission.allActions !==
                                undefined &&
                            (this.state.user_permission.allActions.hasOwnProperty(
                                'attach_to_chart'
                            ) ||
                                this.state.user_permission.allActions.hasOwnProperty(
                                    'save_partial_attach_to_chart'
                                ) ||
                                this.state.user_permission.allActions.hasOwnProperty(
                                    're_attach_to_chart'
                                )) ? (
                                <div className='edit_details'>
                                    <Button
                                        variant='primary'
                                        title='Edit Details'
                                        onClick={this.editDetails}
                                    >
                                        Edit Details
                                    </Button>
                                </div>
                            ) : this.props.isLocked === true ? (
                                <div className='edit_details'>
                                    <Button
                                        variant='primary'
                                        title='Edit Details'
                                        disabled={this.props.isLocked === true}
                                    >
                                        Edit Details
                                    </Button>
                                </div>
                            ) : null}

                            {this.state.edit_details ? (
                                <div className='edit_details notes d-flex justify-content-between'>
                                    <button
                                        type='button'
                                        title='Clear'
                                        className='clear cancel-bg-btn'
                                        disabled={this.props.isLocked === true}
                                        onClick={() => {
                                            this.clearAll();
                                        }}
                                    >
                                        <i className='fas fa-broom' />
                                        Clear
                                    </button>
                                    <button
                                        type='button'
                                        title='Cancel'
                                        className='cancel_det ml-3'
                                        disabled={this.props.isLocked === true}
                                        onClick={this.closeEditDetails}
                                    >
                                        Cancel
                                    </button>
                                    {this.state.submitDetail === false ? (
                                        <div className='notes_btn ml-3'>
                                            <div
                                                className='btn-group'
                                                role='group'
                                            >
                                                <button
                                                    id='btnGroupDrop1'
                                                    type='button'
                                                    className='btn success font-weight-bold dropdown-toggle'
                                                    data-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                    onClick={() => {
                                                        this.setState({
                                                            toggleDetail:
                                                                !this.state
                                                                    .toggleDetail,
                                                        });
                                                    }}
                                                >
                                                    {this.state.detailDDL}
                                                    {this.state.user_permission.allActions.hasOwnProperty(
                                                        'attach_to_chart'
                                                    ) &&
                                                    this.state.user_permission.allActions.hasOwnProperty(
                                                        'save_partial_attach_to_chart'
                                                    ) ? (
                                                        <i className='fa-light fa-angle-down' />
                                                    ) : null}
                                                </button>

                                                {this.state.toggleDetail &&
                                                this.state.user_permission.allActions.hasOwnProperty(
                                                    'attach_to_chart'
                                                ) &&
                                                this.state.user_permission.allActions.hasOwnProperty(
                                                    'save_partial_attach_to_chart'
                                                ) ? (
                                                    <div
                                                        className='dropdown-menu'
                                                        //  aria-labelledby='btnGroupDrop1'
                                                    >
                                                        {this.state.user_permission.allActions.hasOwnProperty(
                                                            'attach_to_chart'
                                                        ) &&
                                                        this.state
                                                            .user_permission
                                                            .allActions
                                                            .attach_to_chart
                                                            .actionId !== '' ? (
                                                            <a
                                                                // type="button"
                                                                title='Attach to chart'
                                                                className='dropdown-item'
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            detailDDL:
                                                                                'Attach to chart',
                                                                            toggleDetail:
                                                                                !this
                                                                                    .state
                                                                                    .toggleDetail,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Attach to chart
                                                            </a>
                                                        ) : null}

                                                        {this.state.user_permission.allActions.hasOwnProperty(
                                                            'save_partial_attach_to_chart'
                                                        ) &&
                                                        this.state
                                                            .user_permission
                                                            .allActions
                                                            .save_partial_attach_to_chart
                                                            .actionId !== '' ? (
                                                            <a
                                                                // type="button"
                                                                className='dropdown-item'
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            detailDDL:
                                                                                'Save',
                                                                            toggleDetail:
                                                                                !this
                                                                                    .state
                                                                                    .toggleDetail,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Save
                                                            </a>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                                <div
                                                    className='checkbox-note'
                                                    title='Save'
                                                >
                                                    <label className='checkbox checkbox-success p-0'>
                                                        <input
                                                            type='checkbox'
                                                            name='Checkboxes5'
                                                            checked='checked'
                                                            readOnly
                                                            //defaultChecked
                                                        />
                                                        {this.state
                                                            .isDetailSubmitted ? (
                                                            <span
                                                                className={
                                                                    this.props
                                                                        .notesVal ===
                                                                    ''
                                                                        ? 'disable'
                                                                        : ''
                                                                }
                                                                /* onClick={() =>
                                                               
                                                            } */
                                                            >
                                                                <i className='fa-light fa-check' />
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className={
                                                                    this.props
                                                                        .notesVal ===
                                                                    ''
                                                                        ? 'disable'
                                                                        : ''
                                                                }
                                                                onClick={
                                                                    this.state
                                                                        .detailDDL ===
                                                                    'Save'
                                                                        ? this
                                                                              .submitDetail
                                                                        : this
                                                                              .submitAttachToChart
                                                                }
                                                            >
                                                                <i className='fa-light fa-check' />
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className='btn-group posted_btn load_img notes_btn ml-3'
                                            role='group'
                                        >
                                            <button
                                                type='button'
                                                className='post_loading'
                                                style={{
                                                    backgroundColor: '#8DC63F',
                                                    width: '80px',
                                                }}
                                            >
                                                <span
                                                    className='spinner-border spinner-border-sm'
                                                    role='status'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                        {this.state.edit_details === false ? (
                            <ViewDetails
                                patientRecordsetId={
                                    this.state.patientRecordsetId
                                }
                                docId={this.props.selectedDocId}
                                orderRecordsetId={this.state.orderRecordsetId}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                }}
                            />
                        ) : null}
                        {this.state.edit_details ? (
                            <div>
                                <div className='mb-2'>
                                    <EditDetails
                                        byPatientDynamicUpdate={{
                                            data: this.state
                                                .byPatientDynamicFormData,
                                            setData: (key, value) => {
                                                if (key) {
                                                    const tempState =
                                                        this.state
                                                            .byPatientDynamicFormData;
                                                    tempState.set(key, value);
                                                    this.setState({
                                                        byPatientDynamicFormData:
                                                            tempState,
                                                    });
                                                } else {
                                                    this.setState({
                                                        byPatientDynamicFormData:
                                                            new Map(),
                                                    });
                                                }
                                            },
                                        }}
                                        orderByValidation={{
                                            validationMap:
                                                this.state
                                                    .editDetailByOrderValidationError,
                                            removeValidation: (key) => {
                                                this.state.editDetailByOrderValidationError.delete(
                                                    key
                                                );
                                            },
                                        }}
                                        state={{ ...this.state }}
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                        }}
                                        refreshEditDetails={() => {
                                            this.refreshEditDetails();
                                        }}
                                        isDetailBigView
                                        clearAll={this.state.clear_all}
                                        submitCreatePatient={
                                            this.state.submitCreatePatient
                                        }
                                        submitDetailForm={() => {
                                            if (
                                                this.state.detailDDL === 'Save'
                                            ) {
                                                this.submitDetail();
                                            } else {
                                                this.submitAttachToChart();
                                            }
                                        }}
                                        updatePatientFilter={(e) => {
                                            this.updatePatientFilter(e);
                                        }}
                                        patientLink={this.state.patientLink}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {/* Episode starts */}

                        {/* {this.state.edit_details ? (
                            <div className='form-group row'>
                                <div className='col-lg-12'>
                                    <label className='pl-5'>COMMENTS</label>
                                    <textarea
                                        className='form-control'
                                        value={this.state.comments}
                                        onChange={(event) => {
                                            this.handleFormOnChange(
                                                'comments',
                                                event
                                            );
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                        ) : null} */}

                        <div className='more_info pl-2' onClick={this.openMore}>
                            <div className='mt-4 mb-6 mb-xl-4 more_infor'>
                                {this.state.more_information ? (
                                    <div title='Less Information'>
                                        <i className='fa-regular fa-chevron-up' />
                                        <button type='button'>
                                            {' '}
                                            Less Information
                                        </button>
                                    </div>
                                ) : (
                                    <div title='More Information'>
                                        <i className='fa-regular fa-chevron-down' />
                                        <button type='button'>
                                            {' '}
                                            More Information
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {this.state.more_information ? (
                            <DetailMoreInformation />
                        ) : null}
                        {/* Episode ends */}
                    </div>
                    <div className='wrap_details wrap_note notes-expand p-4'>
                        {this.state.noteTypes.length > 0 ? (
                            <div className='d-flex mb-5 details_part align-items-stretch justify-content-between'>
                                <div className='details_fun'>
                                    <span>
                                        <i
                                            title='Notes'
                                            className='fa-solid fa-sticky-note'
                                        >
                                            {' '}
                                        </i>
                                        Notes
                                    </span>
                                </div>

                                <hr />
                            </div>
                        ) : null}

                        {this.state.viewAllNotes === true &&
                        this.props.selectedDocId !== undefined ? (
                            <ViewAllNotes
                                selectedDocId={this.props.selectedDocId}
                            />
                        ) : null}

                        {/* {this.props.callingFrom === "related_document" ? null : */}
                        <Notes
                            callingFrom={this.props.callingFrom}
                            openNote={this.state.open_notes_section}
                            docId={this.props.selectedDocId}
                            type='normal'
                            // handleReset={handleReset}
                            handleNotesSubmit={(
                                notes,
                                notesValue,
                                notesDDL,
                                docId
                            ) => {
                                this.set_handlenotessubmit(
                                    notes,
                                    notesValue,
                                    notesDDL,
                                    docId
                                );
                            }}
                            openBigView={() => {
                                this.openBigView();
                            }}
                            // handleNotesSubmit={this.set_handlenotessubmit}
                            handleNotesText={this.props.handleNotesText}
                            handleNotesDDL={this.props.handleNotesDDL}
                            notesVal={this.props.notesVal}
                            notesSubmitted={this.props.notesSubmitted}
                            refreshViewAll={() => {
                                this.refreshViewAll();
                            }}
                            isLocked={this.props.isLocked}
                        />
                        {/* } */}
                    </div>
                </div>
                {/* PDF preview Section */}

                <Modal
                    show={this.state.openHistroy}
                    onHide={() => {
                        handleHistroyClose();
                    }}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start history_header'
                    >
                        <Modal.Title className='d-flex d-flex wrap_history align-items-stretch justify-content-between'>
                            <div className='history_popup'>
                                <i className='fa fa-history' />
                                View History
                            </div>
                            <div className='d-flex align-items-center'>
                                <i
                                    title='Close'
                                    className='fa-light fa-xmark d-flex justify-content-end '
                                    aria-hidden='true'
                                    onClick={() => {
                                        handleHistroyClose();
                                    }}
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0 history-pop'>
                        <DocumentHistroy
                            openHistroy={this.state.openHistroy}
                            docId={this.props.selectedDocId}
                        />
                    </Modal.Body>
                </Modal>

                <UpdateFaxDetails
                    openUpdateNotes={this.state.openUpdateNotes}
                    selectedDocId={[this.state.selectedDocId]}
                    updateState={(state) => {
                        this.setState({ ...state });
                    }}
                    updateValue={this.state.updateValue}
                    more_information={this.state.more_information}
                    user_permission={this.state.user_permission}
                    calingFrom='detailBigview'
                />
            </>
        );
    }
}

// export default DetailBigView;

const mapDispatchToProps = {
    getListaction: getListaction,
    setSelectedRow: setSelectedRow,
    deletedPage,
    getCountData: getCountData,
    getRulesJson: getRulesJson,
    showLoader: (data) => showLoader(data),
    hideBigView: (data) => hideBigView(data),
    getDDLlist: getDDLlist,
    getSelectedRowDetails,
    editDetailPage,
    inboxPage,
    updateList,
    updateInboxState,
};
const mapStateToProps = (state, ownProps) => {
    return {
        inbox: state.inbox,
        rowData: state.inbox.rowData,
        rulesJson: state.persist.rulesJson,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        ddl: state.persistDDL.DDL,
        currentMenuName: state.persist.currentMenuName,
        editDetailPage: state.inbox.editDetailPage,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DetailBigView));
