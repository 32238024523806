import { put, takeLatest } from 'redux-saga/effects';
import {
    GET_OWNERS_AUTH,
    // GET_OWNERS_SUCCESS_AUTH,
    GET_OWNERS_FAILURE_AUTH,
    // DEFAULT_AUTH_STATE,
    LOGOUT,
} from './actionTypes/checkAuthTypes';

export const API_URL = process.env.REACT_APP_EXTERNAL_API_URL;

export function* getOwnersToCheckAuth({ processId, statusId }) {
    if (processId === undefined || statusId === undefined) {
        return true;
    }
    try {
        // const result = [ "kalpana Raja","Bargavi Prasanna","Sathish Kumar","Abubakkar","Santhosh" ];
        const color_code = [
            '#ECB445',
            '#8B36BF',
            '#00B1BC',
            '#ffbe0b',
            '#fb5607',
            '#ff006e',
            '#8338ec',
            '#3a86ff',
            '#003049',
            '#d62828',
            '#f77f00',
            '#283618',
            '#bc6c25',
            '#1b4332',
            '#1a759f',
        ];
        //const history = useHistory();
        const ownerData = yield fetch(
            `${API_URL}/documentOwners/${processId}/${statusId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('WV-Token')}`,
                },
            }
        )
            //.then((response) => response.json());
            .then((response) => {
                var statuscode = response.status;

                if (statuscode === 400 || statuscode === 401) {
                    fetch(`${API_URL}/logout`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${localStorage.getItem(
                                'WV-Token'
                            )}`,
                        },
                    });

                    var remember_me = localStorage.getItem('remember_me');
                    var username = localStorage.getItem('username');
                    var password = localStorage.getItem('password');

                    const values = {};
                    const keys = Object.keys(localStorage);
                    let i = keys.length;
                    while (i--) {
                        values[keys[i]] = localStorage.getItem(keys[i]);
                    }

                    const localStorageData = values;
                    const headerStorage = {};
                    for (const name in localStorageData) {
                        if (
                            String(name).includes('header-reorder') ||
                            String(name).includes('header-width') ||
                            String(name).includes('filter-options') ||
                            String(name).includes('gmail_') ||
                            String(name).includes('sub_domain_name') ||
                            String(name).includes('tagsCurrentWidth') ||
                            String(name).includes('searchFilter_') ||
                            String(name).includes('grouping_')
                        ) {
                            headerStorage[name] = localStorageData[name];
                        }
                    }

                    localStorage.clear();
                    localStorage.setItem('remember_me', remember_me);
                    localStorage.setItem('username', username);
                    localStorage.setItem('password', password);

                    for (const name in headerStorage) {
                        localStorage.setItem(name, headerStorage[name]);
                    }

                    localStorage.setItem('forcelogout', 1);
                    window.location.reload();
                } else {
                    //localStorage.setItem('forcelogout', 1);
                    return response.json();
                }
            });
        const finalOwner = [];
        ownerData.forEach((owner) => {
            const color = {};
            const bg_color = color_code[Math.floor(Math.random() * 15)];
            color.ownerId = owner.ownerId;
            color.ownerName = owner.ownerName;
            color.bg_color = bg_color;
            finalOwner.push(color);
        });
        yield put({ type: GET_OWNERS_AUTH, data: finalOwner });
    } catch (error) {
        yield put({ type: GET_OWNERS_FAILURE_AUTH, error });
    }
}

// eslint-disable-next-line require-yield
export function* logOut() {
    fetch(`${API_URL}/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem('WV-Token'),
        },
    }).then((response) => {
        var remember_me = localStorage.getItem('remember_me');
        var username = localStorage.getItem('username');
        var password = localStorage.getItem('password');

        const values = {};
        const keys = Object.keys(localStorage);
        let i = keys.length;
        while (i--) {
            values[keys[i]] = localStorage.getItem(keys[i]);
        }

        const localStorageData = values;
        const headerStorage = {};
        for (const name in localStorageData) {
            if (
                String(name).includes('header-reorder') ||
                String(name).includes('header-width') ||
                String(name).includes('filter-options') ||
                String(name).includes('gmail_') ||
                String(name).includes('sub_domain_name') ||
                String(name).includes('tagsCurrentWidth') ||
                String(name).includes('searchFilter_')
            ) {
                headerStorage[name] = localStorageData[name];
            }
        }

        localStorage.clear();
        localStorage.setItem('remember_me', remember_me);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        for (const name in headerStorage) {
            localStorage.setItem(name, headerStorage[name]);
        }

        window.location.reload();
    });
}

// export function* setDefaultValuesForAuth() {
//     yield put({ type: DEFAULT_AUTH_STATE });
// }

// export function* defaultAuth() {
//     yield takeLatest(DEFAULT_AUTH_STATE, setDefaultValuesForAuth);
// }

export function* checkAuth(processId, statusId) {
    yield takeLatest(GET_OWNERS_AUTH, getOwnersToCheckAuth);
    yield takeLatest(LOGOUT, logOut);
}
