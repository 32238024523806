import {
    CONFIG_DATA,
    ACTION_ARRAY,
    GET_DDL_LIST,
    LOG_OUT,
} from '../actionTypes/configTypes';

export const getConfigData = (configData) => ({
    type: CONFIG_DATA,
    configData,
});

export const getActionArray = (actionArray) => ({
    type: ACTION_ARRAY,
    actionArray,
});

export const getDDLlist = (DDL) => ({
    type: GET_DDL_LIST,
    DDL,
});

export const logOut = (IslogouTrue) => ({
    type: LOG_OUT,
    IslogouTrue,
});
