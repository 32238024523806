export const DefaultState = Object.seal({
    groupBy: [],
    groupByData: {},
    filterTxt: '',
    // perPage: 10,
    currentPage: 0,
    totalCount: 0,
    sortDirection: 'NONE',
    sortColumn: '',
    selectedRows: new Set(),
    rowData: [],
    filteredRowData: [],
    header: [],
    expandedRow: new Set(),
    expandedGroupIds: new Set(),
    tempExpandedGroupIds: new Set(),
    uniqueData: {},
    openedRowId: {},
    allActions: {},
    related_doc_detail: false,
    multiple_edit_page: false,
});
export const GridState = {
    rowData: [],
    filteredRowData: [],
    header: [],
    selectedIndexes: [],
    filterTxt: '',
    perPage: 10,
    currentPage: 0,
    totalCount: 0,
    sortDirection: 'NONE',
    sortColumn: '',
    groupBy: [],
    groupByData: {},
    unExpandedRows: [],
    groupByIndex: [],
    expandedRow: new Set(),
    expandedGroupIds: new Set(),
    tempExpandedGroupIds: new Set(),
    selectedRows: new Set(),
    sortColumns: [],
    uniqueData: {},
    defaultOpen: false,
    uniqueTagNameValues: [],
    isFilterRowEmpty: false,
    defaultPopOpenState: {},
    // expandedGroupIds: new Set(),
    modifiedColumnsNames: new Set(),
    menu_name: null,
    dateFormatFields: [],
    rowClickClass: [],
    currentStateMenuName: null,
    newTagColorArray: [],
    editId: [],
    tableDesign: 'simple', // Default value,
    selected_tag: [],
    selectedTagDocId: '',
    tagLoader: false,
    selectedColumn: '',
    tagIndex: 0,
    show_owner: false,
    show_tag: false,
    show_branch: false,
    show_group: false,
    show_inbox: false,
    show_notes: false,
    show_pdf: false,
    show_delete: false,
    show_print: false,
    show_download: false,
    row_transition: false,
    processId: null,
    statusId: null,
    statusArray: [],
    branchActionId: null,
    groupActionId: null,
    updateFaxActionId: null,
    menu_tags: [],
    // newTagColorArray: [],
    branches: [],
    groups: [],
    ownerValue: '',
    reInitiateGrid: false,
    openedRowId: {},
};

export const InboxState = {
    rowsToDelete: [],
    tableDesign: 'complex', // 'complex','simple',
    selectedIndexes: [],
    rowTransition: false,
    groupByData: {},
    filterTxt: '',
    row_transition: false,
    refresh_table: true,
    refresh_delete: true,
    menu_selected_tagonly: [],
    menu_branch: '',
    menu_group: '',
    menu_inbox: '',
    toast_type: 'success',
    detailBigView: false,
    owners: [],
    main_spinner: false,
    rowClickClass: [],
    is_locked: false,
    is_locked_by: '',
    blur_spinner: false,
    rowDetails: undefined,
    show_owner: false,
    show_tag: false,
    show_branch: false,
    show_group: false,
    show_inbox: false,
    show_notes: true,
    show_pdf: true,
    show_delete: true,
    show_print: true,
    show_download: true,
    selected_tag: [],
    showData: false,
    selectedTagDocId: '',
    selectedColumn: '',
    ownerTitle: '',
    noteCount: 0,
    pageCount: 0,
    displayAnimation: false,
    editId: [],
    menu_name: '',
    tagIndex: 0,
    openedRowId: {},
    selectedRows: new Set(),
};
